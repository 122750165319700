/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import OrderExperience from '../../../components/OrderExperience/OrderExperience';
import { orderTypeIds } from '../../../shared/constants/order';

import { routeCountryPath } from '../../../shared/utilities/common';
import styles from '../ConfirmOrder.module.css';

class HeroLeft extends Component {

  handleShare = () => {
    const appUrl = window.location.href;
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${appUrl}`;
    window.open(shareUrl, '_blank');
  }

  eta = () => {
    const { order } = this.props;

    if (!order) {
      return null;
    }

    if (order.orderTypeId === orderTypeIds.shipping) {
      return order.shipment && order.shipment.estimatedDeliveryTime ?
        moment(order.shipment.estimatedDeliveryTime).format('MMMM D') :
        moment().format('MMMM D');
    }

    return moment(order.deliveryTime).isValid() ?
      moment(order.deliveryTime).format('dddd MMM Do [at] h:mma') :
      'ASAP';
  }

  shippingAddress = () => {
    const { order } = this.props;

    if (!order) {
      return null;
    };

    let shippingAddress = order.shippingAddress.address1;

    if (order.shippingAddress2) {
      shippingAddress += ` ${order.shippingAddress2}`;
    }

    return shippingAddress;
  }

  render() {
    const { order, history } = this.props;

    const isShipping = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.shipping : false;
    const isDelivery = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.delivery : false;
    const isPickUp = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.pickup : false;
    const isDoughProOrder = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.doughPro : false;
    const isEmailGift = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.eGiftCard : false;
    const shippingAddress = this.shippingAddress();
    const eta = this.eta();

    const renderOrderId = () => {
      if (!order) {
        return null;
      }

      return (
        <p id={styles.orderConfirmation}>
          Order #
          {order.id}
        </p>
      );
    };

    return (
      <div data-ddog-id="order_confirmation" className={`${styles.topLeftConfirmation} md:pt-24`}>
        {renderOrderId()}

        <h1> Your order has been confirmed </h1>

        {isShipping ? (
          <p>
            Shipping
            {' '}
            to
            {' '}
            {shippingAddress}
            .
          </p>
        ) : null}

        {isDelivery ? (
          <p>
            Delivery to
            {' '}
            {order.shippingAddress.address1}
            {' '}
            has an eta of
            {' '}
            {eta}
            .
          </p>
        ) : null}

        {isPickUp ? (
          <p>
            Order from
            {' '}
            {order.store.address}
            {' '}
            will be ready by
            {' '}
            {eta}
            .
            {' '}
          </p>
        ) : null}

        {!isDoughProOrder && !isEmailGift ? (
          <div>
            <Button
              handleSubmit={() => history.push(routeCountryPath(`/checkout/order-tracker-step/${order.trackingID}`))}
              customContainerStyles={`${styles.heroButtonContainer} 
                bg-button font-filsonProMedium text-light rounded-[54px]`}
              label="Track order"
              disabled={!order}
            />

            {isDelivery || isPickUp ? <p onClick={() => this.handleShare()}>Share</p> : null}
          </div>

        ) : null}
        <OrderExperience />
      </div>
    );

  };
}

HeroLeft.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  order: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    shipment: PropTypes.shape({
      estimatedDeliveryTime: PropTypes.string
    }),
    trackingID: PropTypes.string.isRequired,
    shippingAddress2: PropTypes.string,
    orderTypeId: PropTypes.number.isRequired,
    deliveryTime: PropTypes.string.isRequired,
    store: PropTypes.shape({
      address: PropTypes.string.isRequired
    }).isRequired,
    shippingAddress: PropTypes.shape({
      address1: PropTypes.string.isRequired
    })
  }).isRequired
};

export const mapStateToProps = state => {
  const { order } = state;

  return {
    order
  };
};

export default withRouter(connect(mapStateToProps)(HeroLeft));
