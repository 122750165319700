import React from 'react';
import PropTypes from 'prop-types';
import {
  propTypeStorePaymentMethod,
  propTypeOrder,
  propTypesCmsContent,
  propTypeCustomerSavedCards
} from './shared.propTypes';

import CookieDoughWidget from '../CookieDoughWidget/CookieDoughWidget';
import { isDoughProOrder } from '../../shared/utilities/orders';
import styles from './shared.module.css';
import { getPaymentMethodGroups } from './shared';
import PaymentMethodGroup from './PaymentMethodGroup/PaymentMethodGroup';

const PaymentWidget = props => {
  const {
    storePaymentMethods,
    order,
    orderTypeId,
    paymentMethodsCustomerSaved,
    updatePaymentCredentials,
    isDoughProMember,
    isLoggedIn,
    cmsContent,
    updateEmbeddedPaymentMethod
  } = props;

  const isSavePaymentMethodCheckedAndDisabled = orderTypeId && isDoughProOrder(orderTypeId);

  const paymentMethodGroups = getPaymentMethodGroups(
    storePaymentMethods,
    isLoggedIn ? paymentMethodsCustomerSaved : null
  );

  const customerHasCookieDough = order.customer?.loyalty && !isDoughProOrder(order.orderTypeId);

  return (
    <div className={`${styles.paymentWidget} bg-white dark:bg-dark shadow-md `}>
      {paymentMethodGroups.map(pmcg => (
        <div
          key={pmcg.groupTitle}
          className={`
          ${styles.paymentMethodGroupContainer}
          border-b-2 border-lightDry dark:border-darkDry text-dark dark:text-white`}
        >
          <PaymentMethodGroup
            paymentMethodComponentGroup={pmcg}
            isSavePaymentMethodCheckedAndDisabled={isSavePaymentMethodCheckedAndDisabled}
            isSavePaymentMethodAllowed={isLoggedIn}
            paymentMethodsCustomerSaved={paymentMethodsCustomerSaved}
            updatePaymentCredentials={updatePaymentCredentials}
            isDoughProMember={isDoughProMember}
            isLoggedIn={isLoggedIn}
            cmsContent={cmsContent}
            updateEmbeddedPaymentMethod={updateEmbeddedPaymentMethod}
          />
        </div>
      ))}
      {customerHasCookieDough && <CookieDoughWidget order={order} />}
    </div>
  );
};

PaymentWidget.propTypes = {
  storePaymentMethods: PropTypes.arrayOf(propTypeStorePaymentMethod).isRequired,
  order: propTypeOrder.isRequired,
  orderTypeId: PropTypes.number.isRequired,
  paymentMethodsCustomerSaved: propTypeCustomerSavedCards.isRequired,
  updatePaymentCredentials: PropTypes.func.isRequired,
  isDoughProMember: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  cmsContent: propTypesCmsContent,
  updateEmbeddedPaymentMethod: PropTypes.func.isRequired
};

PaymentWidget.defaultProps = {
  isDoughProMember: false,
  isLoggedIn: false,
  cmsContent: []
};

export default PaymentWidget;
