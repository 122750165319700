import React from "react";
import PropTypes from "prop-types";

import Link from "../../components/LinkWithCountry/LinkWithCountry";

function Faqs(props) {
  const { contents } = props;
  return (
    <div className="w-full flex flex-col h-[158px] md:items-center justify-center gap-3 px-4">
      <h1 className="text-dark font-congenialBlack text-4xl leading-9 tracking-[-1px]">
        {contents?.heading}
      </h1>

      <p className="font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] text-darkElevationPrimary">
        {contents?.description}
      </p>

      <div className="flex flex-col md:flex-row gap-3">
        {contents?.link &&
          contents?.link.map(l => (
            <Link to={l.href}>
              <button
                type="button"
                className="
            bg-button text-lg leading-[22px] tracking-[-0.1px] 
            font-filsonProBold text-white h-[52px] rounded-[40px] px-5 py-[15px]"
              >
                {l.title}
              </button>
            </Link>
          ))}
      </div>
    </div>
  );
}

Faqs.propTypes = {
  contents: PropTypes.shape({}).isRequired
};

export default Faqs;
