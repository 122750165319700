export const nightModePages = [
    '/user/loyalty-rewards'
]


export const passwordlessNightModeScreen = [
    '/login',
    '/create-account',
    '/welcome'
]

export const dayModePages = [
    '/insomniarewards'
]