/**
 * Formats the provided string argument into capitalized form
 * @param {String} string string to return captalized
 * @return {String} returns capitalized string
 */
import MOBILE_SIZE from "../constants/size";
import bigLittleDippers from '../../static/imgs/header/big-little-dippers-chocolate-chunk@2x.png';
import chillaxPacks from '../../static/imgs/header/chillax-packs@2x.png';
import cookiewich from '../../static/imgs/header/cookiewich@2x.png';
import monsterStyleSundae from '../../static/imgs/header/monster-style-sundae@2x.png';
import tripleThreatCookieCake from '../../static/imgs/header/triple-threat-cookie-cake@2x.png';
import { store } from "../../redux/store";
import { isHasCountryPathInLocation } from "./pathes";

export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
};

/**
 * Returns boolean depending on whether or not the provided component is on the provided path
 * @param {React.Component} component - React Component to check path against
 * @param {String} desiredPath - desired path in /path format
 * @returns {Bool} returns boolean depending on if current Component is found on desired path
 */
export const checkPath = (component, desiredPath) => {
  const { pathname } = component.props.location;
  return pathname.includes(desiredPath);
};

/**
 * Returns a boolean which indicates if the current resolution is a mobile resolution
 * @returns {Boolean}
 */
export const isMobile = () => {
    const width = window.innerWidth;

    return width <= MOBILE_SIZE.width;
}

export const apiBase = () => {
    const endpoint = global.window.environment.REACT_APP_GRAPHQL_URI || 'http://insomniacookies.com';
    const url = new URL(endpoint);

    return url.host;
};

/**
 * Shorten string argument by n argument to format to ellipses
 * @param {String} string
 * @param {Number} n
 * @returns {String}
 */
export const shorten = (string, n) => {
  // if string is shorter than truncation limit, return unformatted string
  if (string.length < n) return string;
  // return formatted string substring
  return `${string.substring(0,n)}...`;
}

export const slugify = (_str) => {
  let str = _str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to   = "aaaaeeeeiiiioooouuuunc------";
  // eslint-disable-next-line no-plusplus
  for (let i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export const getErrorReasons = (error, index = -1) => {
  if (!error || !error.graphQLErrors || !error.graphQLErrors.length === 0) {
    return null;
  }

  const reasons = [];

  for (let i = 0; i < error.graphQLErrors.length; i += 1) {
    const graphQLError = error.graphQLErrors[i];

    if (!graphQLError) {
      reasons.push(null);
    } else {
      reasons.push(graphQLError.extensions ? graphQLError.extensions.reason : null);
    }
  }

  if (index === -1) {
    return reasons;
  }

  return index < reasons.length ? reasons[index] : null;
}

export const getErrorMessages = (error, index = -1) => {
  if (!error || !error.graphQLErrors || !error.graphQLErrors.length === 0) {
    return null;
  }

  const messages = [];

  for (let i = 0; i < error.graphQLErrors.length; i += 1) {
    const graphQLError = error.graphQLErrors[i];

    if (!graphQLError) {
      messages.push(null);
    } else {
      messages.push(graphQLError.message ? graphQLError.message : null)
    }
  }

  if (index === -1) {
    return messages;
  }

  return index < messages.length ? messages[index] : null;
}

export const getValidationErrorMessages = (error) => {
  if (!error || !error.graphQLErrors || !error.graphQLErrors.length === 0) {
    return null;
  }

  const messages = [];

  for (let i = 0; i < error.graphQLErrors.length; i += 1) {
    const graphQLError = error.graphQLErrors[i];

    if (!graphQLError) {
      messages.push(null);
    } else if ( graphQLError.extensions?.validation ) {
        messages.push(graphQLError.extensions?.validation ? graphQLError.extensions?.validation : null)
      } else {
        messages.push({'message': [graphQLError.message ? graphQLError.message : null]})
      }
  }
  
  return messages.length > 0 ? messages[0] : [];
}

export const formatValidationMessage = (errors, field) => {
  let message =  errors[field] ? errors[field][0] : null;

  if ( message ) {
    const fieldArray = field.split(".");
    message = message.replace(`${fieldArray[0]}.`, '' );
  }
  
  return message;
}


export const isMobileScreen = () => {
  return window.innerWidth <= 700;
}

export const getBackgroundImage = (src) => {
  return  src.replace(/ /g, '%20');
};

export const getHeaderProductImage = () => {
  const imgs = [
    bigLittleDippers,
    chillaxPacks,
    cookiewich,
    monsterStyleSundae,
    tripleThreatCookieCake,
  ];
  const index = Math.floor(Math.random() * imgs.length);
  return imgs[index];
}

export const phoneNumberFormatValidation = (values) => {
  const {floatValue} = values;

  if(values.value.length === 0) {
    return true;
  }
  return /^\d+$/.test(floatValue);
};

export const cardExpirationFormatValidation = (values) => {
  const {floatValue} = values;
  let max = '1';
  if(values.value.length > 1) {
    if(values.value.length === 2){
      max = 12;
    }else {
      max += 10 ** (values.value.length - 1) - 1;
    }
  }

  if(values.value.length === 0) {
    return true;
  }
  return floatValue <= max;
};


export const clearStorage = () => {
  window.localStorage.clear();
  window.sessionStorage.clear();
}

/**
 *
 * @param {boolean} force Use force as true when you call this method on some event not on page load
 *  else it will cause infinite redirect.
 * @returns
 */
export const redirectToOnError = (force = false) => {
  const { selectedCountry } = store.getState().country;

  if (!selectedCountry.id) {
    // Extract country code e.g uk, ca from pathname.
    const pathParts = window.location.pathname.split('/').filter((cp) => cp);
    let countryPath = null;
    if (pathParts.length > 0 && pathParts[0].length === 2) {
      [ countryPath ] = pathParts;
    }

    // If there is country code then redirect to country home page.
    if (countryPath && window.location.pathname.indexOf(`/${countryPath}`) === 0) {
      const isAlreadyOnPath = window.location.pathname === `/${countryPath ?? ''}`;
      if (isAlreadyOnPath && !force) {
        return;
      }

      window.location.href = `/${countryPath ?? ''}`;

      return;
    }

    // If no country matched then redirect to US.
    if (window.location.pathname !== '/' || force) {
      window.location.href = '/';
    }

    return;
  }

  const isAlreadyOnPath = window.location.pathname === `/${selectedCountry.path_url ?? ''}`;
  if (isAlreadyOnPath && !force) {
    return;
  }

  window.location.href = `/${selectedCountry.path_url ?? ''}`;
}


export const routeCountryPath = (path) => {
  const { selectedCountry } = store.getState().country;
  if (!selectedCountry.id) {
    return path;
  }

  if (isHasCountryPathInLocation(path, selectedCountry.path_url)) {
    return path;
  }

  return selectedCountry.path_url ? `/${selectedCountry.path_url}${path}` : path;
}
