import React from "react";
import PropTypes from "prop-types";

function DeliciousRewards(props) {
  const { contents } = props;

  return (
    <div className="px-4">
      <div className="flex flex-col items-center justify-center gap-3">
        <h1 className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-dark">
          {contents?.heading}
        </h1>
        <p className="text-darkElevationPrimary">{contents?.description}</p>
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-6">
        {contents?.products &&
          contents?.products.map(p => (
            <div className="md:w-[288px] max-h-[344px] flex flex-col md:items-center md:justify-center gap-3">
              <img
                src={p?.image?.url}
                alt="Classic Cookies"
                className="w-[288px] h-[238px]"
              />
              <h1 className="font-filsonProRegular font-bold text-xl leading-[22px] tracking-[-0.3px] text-dark md:text-center">
                {p.title}
              </h1>
              <p className="font-filsonProRegular text-base leading-5 text-darkElevationPrimary md:text-center">
                {p.description}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
}


DeliciousRewards.propTypes = {
  contents: PropTypes.shape({}).isRequired
};

export default DeliciousRewards;