import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../Loader/Loader';
import * as orderActions from '../../redux/actions/order';
import CheckoutContainer from '../CheckOutContainer/CheckoutContainer';
import styles from '../../pages/ReviewOrder/ReviewOrder.module.css';
import Button from '../Button/Button';
import SecondaryInput from '../SecondaryInput/SecondaryInput';
import CheckBox from '../CheckBox/CheckBox';
import * as userRepo from '../../shared/repos/graphql/user';
import * as orderRepo from '../../shared/repos/graphql/order';
import { isDoughProOrder } from '../../shared/utilities/orders';
import componentStyles from './ContactDetailsWidget.module.css';
import {setAlertSenderPhone} from "../../redux/actions/user";
import {orderMethods} from "../../shared/constants/order";
import classes from '../../shared/constants/classes';
import * as checkoutActions from "../../redux/actions/checkout";
import {setErrorState, setValidationErrorMessagesFromApiError} from "../../shared/utilities/validations";
import PhoneInputWidget from '../PhoneInputWidget/PhoneInputWidget';
import messages from '../../shared/constants/messages';

class ContactDetailsWidget extends Component {
    constructor(props) {
        super(props);

        let firstName = '';
        let lastName = '';
        let email = '';
        let phone = '';
        let phonePhone = '';
        let prefixPhone = '';
        let editContact = false;
        let phoneVerified = false;
        let telephoneVerificationRequired = true;

        const customer = props.order.customer || null;
        const {alertSenderPhone} = this.props;

        if (customer) {
            firstName = customer.firstname;
            lastName = customer.lastname;
            email = customer.email;
            if (customer.phoneTelephone && customer.prefixTelephone) {
              phone = `${customer.prefixTelephone} ${customer.phoneTelephone}`;
              phonePhone = customer.phoneTelephone;
              prefixPhone = customer.prefixTelephone;
            } else {
              phone = customer.telephone;
            }
            editContact = true;
            phoneVerified = customer.telephoneVerified;
            telephoneVerificationRequired = customer.telephoneVerificationRequired;
        }

        const sender = props.checkout.sender || {};

        if (sender && (sender.firstName || sender.lastName || sender.email || sender.phone)) {
            firstName = sender.firstName;
            lastName = sender.lastName;
            email = sender.email;
            phone = sender.phone;
            phonePhone = sender.phonePhone ? sender.phonePhone : '';
            prefixPhone = sender.prefixPhone ? sender.prefixPhone : '';
            editContact = true;
        }

        this.state = {
            firstName,
            lastName,
            email,
            phone,
            phonePhone,
            prefixPhone,
            editContact,
            currentPhone: customer ? customer.telephone : '',
            phoneVerified,
            agreePhoneChange: false,
            phoneOTP: null,
            phoneOTPErrMessage: '',
            phoneOTPResend: false,
            phoneOTPResending: false,
            verifiyingPhoneOTP: false,
            openForm: true,
            loading: false,
            alertSenderPhone,
            sender,
            telephoneVerificationRequired
        };
    }

    componentDidMount() {
      this.handleContactBlur();
    }

    componentDidUpdate() {
      const { alertSenderPhone } = this.state;
      // eslint-disable-next-line react/destructuring-assignment
      if (this.props.alertSenderPhone !== alertSenderPhone) {
        // eslint-disable-next-line react/destructuring-assignment, react/no-did-update-set-state
        this.setState({alertSenderPhone: this.props.alertSenderPhone });
      }
    }

    verifyPhone = () => {
      const { order } = this.props;
      const { telephoneVerificationRequired } = this.state;

      if (!order) {
        return false;
      }

      return isDoughProOrder(order.orderTypeId) && telephoneVerificationRequired;
    }

    hasVerifiedPhone = () => {
      const { phoneVerified } = this.state;

      return phoneVerified;
    }

    isNewPhoneNumber = () => {
      const { phonePhone, currentPhone } = this.state;

      return currentPhone !== phonePhone;
    }

    updatePhoneFromOTP = async () => {
      const { phone, phoneOTP } = this.state;
      const { orderUpdated, order } = this.props;

      if (!order.customer || !phoneOTP) {
        return;
      }

      this.setState({
        loading: true,
      });

      let data;

      try {
        const result = await userRepo.confirmCustomerTelephoneOtp(phoneOTP);

        data = result.data;
      } catch(e) {
        this.setState({loading: false, phoneOTPResend: true});

        if (!e.message) {
          this.setState({
            phoneOTPErrMessage: 'Something went wrong! Please try again.',
          });

          return;
        }

        this.setState({
          phoneOTPErrMessage: e.message,
        });

        return;
      }

      this.setState({
        verifiyingPhoneOTP: false,
        openForm: false,
        phoneOTP: null,
        loading: false,
        currentPhone: phone,
        phoneVerified: data.confirmCustomerTelephoneOtp.telephoneVerified,
      });

      order.customer.telephone = phone;
      order.customer.telephoneVerified = data.confirmCustomerTelephoneOtp.telephoneVerified;

      orderUpdated(order);
    }

    resendOTP = async () => {
      this.setState({
        phoneOTPResending: true,
        loading: true
      });

      await userRepo.resendCustomerTelephoneOtp();

      this.setState({
        phoneOTPResending: false,
        phoneOTPResend: false,
        phoneOTPErrMessage: '',
        loading: false
      });
    }

    renderOTPConfirmationForm = () => {
      const { loading, phoneOTPErrMessage, phoneOTPResend, phoneOTPResending } = this.state;

      return (
        <div className={styles.secondaryAddressContainer}>
          {loading ? (<div className={styles.createContactLoaderWrapper}><Loader /></div>) : null}
          <p>Confirm Text Verification</p>

          <SecondaryInput
            component={this}
            label="Text Verification"
            state="phoneOTP"
            isEdit
          />

          <Button
            customContainerStyles={styles.secondaryButtonContainer}
            handleSubmit={this.updatePhoneFromOTP}
            label="Confirm"
          />

          <div className={componentStyles.resendWrapper}>
            { phoneOTPErrMessage && phoneOTPErrMessage.length ? <p className={`${styles.errorMessage} ${componentStyles.errorMessage}`}>{phoneOTPErrMessage}</p> : null }
            {phoneOTPResend && <button disabled={phoneOTPResending} className={componentStyles.resendOTPBtn} type="button" onClick={() => this.resendOTP()}>{phoneOTPResending ? 'Resending...' : 'Resend' }</button>}
          </div>
        </div>
      );
    }

    handleContactBlur = (field) => {
      const { checkoutUpdated, checkoutErrorsUpdated, checkout, setAlertSenderPhoneUpdated } = this.props;
      const { firstName, lastName, email, phone, phonePhone, prefixPhone, alertSenderPhone } = this.state;

      if (!checkout.errors) {
        checkout.errors = {};
      }

      if (checkout.sender && (
              firstName === checkout.sender.firstName &&
              lastName === checkout.sender.lastName &&
              email === checkout.sender.email &&
              phone === checkout.sender.phone
      )) {
        return;
      }

      const data = {
        firstName,
        lastName,
        email,
        phone,
        phonePhone,
        prefixPhone,
      };

      checkout.sender = data;
      delete checkout.errors[`contact_${field}`];
      if (field === 'phone') {
        delete checkout.errors[`contact_${field}Phone`];
      }
      checkoutUpdated(checkout);
      checkoutErrorsUpdated(checkout.errors);
      setAlertSenderPhoneUpdated(alertSenderPhone);
      this.setState({sender: data, [`${field}ErrorMessage`]: null})
    }

    onPhoneInput = (value) => {
      this.setState({
        phone: value.telephone,
        phonePhone: value.phone,
        prefixPhone: value.code,
      }, () => this.handleContactBlur('phone'));
    }

  handleOnPhoneBlur = (phoneData) => {
    const { checkout, checkoutUpdated } = this.props;
      if (phoneData.maskValidation && phoneData.additionalValidations===true) {
        this.setState({
          phoneErrorMessage: null,
        });
        delete checkout.errors.contact_phone;
        delete checkout.errors.contact_phonePhone;
        checkoutUpdated(checkout);
      } else {
        if (phoneData.maskValidation === false) {
          const errMessage = messages.phoneInput.maskValidation.replace('%s', phoneData.mask);
          this.setState({
            phoneErrorMessage: errMessage,
          });
          checkout.errors.contact_phone = [{message: errMessage}];
          checkoutUpdated(checkout);
        }

        if (phoneData.additionalValidations !== true && phoneData.additionalValidations.length > 0) {
          const errMessage = phoneData.additionalValidations;
          this.setState({
            phoneErrorMessage: errMessage,
          });
          checkout.errors.contact_phone = [{message: errMessage}];
          checkoutUpdated(checkout);
        }
      }
    }

    setContactInformation = async () => {
      const { orderUpdated, order, setAlertSenderPhoneUpdated } = this.props;
      const { firstName, lastName, email, phone, agreePhoneChange, alertSenderPhone } = this.state;

      const detailsFilled = firstName && lastName && email && phone;
      if (!detailsFilled) {
        return;
      }

      if (this.verifyPhone() && this.isNewPhoneNumber() && !agreePhoneChange) {
        return;
      }

      this.setState({
        loading: true
      });

      try {
        const res = await orderRepo.addSender(order.code, {
          firstName,
          lastName,
          email,
          phone,
        });

        setAlertSenderPhoneUpdated(alertSenderPhone);
        const updateOrder = {
          ...order,
          sender: res.data.createCustomerContactOnOrder.sender,
          customer: res.data.createCustomerContactOnOrder.customer
        };

        if (this.verifyPhone() && (!this.hasVerifiedPhone() || this.isNewPhoneNumber())) {
          this.setState({
            verifiyingPhoneOTP: true,
          });
        }

        this.setState({
          loading: false,
          editContact: true,
        });

        if (order.customer.telephoneVerified && !this.isNewPhoneNumber()) {
          this.setState({
            openForm: false
          });
        }


        orderUpdated(updateOrder);
      } catch(e) {
        setValidationErrorMessagesFromApiError(e, this);

        this.setState({
          loading: false
        });
      }
    }

    getSubscriptionCheckboxParams = () => {
      const {
        orderStatusSubscription,
        subscriptionSelections,
        toggleSubscription,
      } = this.props;
      const { alertSenderPhone, } = this.state;
      return {
        onClick: async () => {
          if (orderStatusSubscription) {
            await toggleSubscription(orderStatusSubscription.subscriptionId);
          }
          this.toggleCheckboxOrderUpdate();
        },
        isChecked: orderStatusSubscription
          ? subscriptionSelections.includes(orderStatusSubscription.subscriptionId)
          : alertSenderPhone,
        label: orderStatusSubscription
          ? orderStatusSubscription.description
          : "Text order status updates",
      };
    };

    renderContactOptions = () => {
        const { loading, editContact, agreePhoneChange, verifiyingPhoneOTP,
          phoneErrorMessage, phonePhone, prefixPhone } = this.state;
        const { userOrderMethod } = this.props;

        const isDelivery = (userOrderMethod === orderMethods.delivery);

        if (verifiyingPhoneOTP) {
          return this.renderOTPConfirmationForm()
        }
        const subCheckboxParams = this.getSubscriptionCheckboxParams();
        return (
          <div className={styles.secondaryAddressContainer}>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe name="" style={{display: 'none'}} src="about:blank" />
            <form target="" action="about:blank" className=''>
              {loading ? (<div className={styles.createContactLoaderWrapper}><Loader /></div>) : null}

              <p className='text-dark dark:text-white'>{`${editContact ? 'Edit' : 'Add'} Contact Information`}</p>
              {!editContact ? (
                <span className={styles.selectionMinor}> *Indicates required fields </span>) : null}

              <SecondaryInput
                component={this}
                label="First Name"
                state="firstName"
                handleOnBlur={() => this.handleContactBlur('firstName')}
                isEdit={editContact}
              />

              <SecondaryInput
                component={this}
                label="Last Name"
                state="lastName"
                handleOnBlur={() => this.handleContactBlur('lastName')}
                isEdit={editContact}
              />

              <SecondaryInput
                component={this}
                label="Email"
                state="email"
                handleOnBlur={() => this.handleContactBlur('email')}
                isEdit={editContact}
              />

              <PhoneInputWidget
                className={componentStyles.phoneInputContainer}
                customInputStyles={componentStyles.phoneInputStyle}
                customSelectStyles={componentStyles.phoneSelectStyle}
                onInput={(e) => this.onPhoneInput(e)}
                handleOnBlur={(e) => this.handleOnPhoneBlur(e)}
                label='Phone Number'
                placeholder=" "
                errorMessage={phoneErrorMessage}
                phone={phonePhone}
                code={prefixPhone}
                isEdit={false}
                isNumberFormat
              />

              {
                (isDelivery) ?
                  (
                    <CheckBox
                      onClick={subCheckboxParams.onClick}
                      isChecked={subCheckboxParams.isChecked}
                      label={subCheckboxParams.label}
                    />
                  ) : null
              }

              {this.isNewPhoneNumber() && this.verifyPhone() &&
                    (
                    <CheckBox
                      onClick={() => this.setState({agreePhoneChange: !agreePhoneChange})}
                      isChecked={agreePhoneChange}
                      label="Confirm phone number change. You'll receive text verification number."
                    />
                    )}

              { !this.isNewPhoneNumber() && !this.hasVerifiedPhone() && this.verifyPhone() &&
                (
                  <p><small>You&apos;ll receive text verification number.</small></p>
                ) }
              {
                this.verifyPhone() &&
                (
                <Button
                  customContainerStyles={styles.secondaryButtonContainer}
                  handleSubmit={this.setContactInformation}
                  label={editContact ? 'Save Changes' : 'Add contact'}
                />
                )
              }
            </form>
          </div>
        );
    };

    toggleCheckboxOrderUpdate = () => {
      let { alertSenderPhone } = this.state;
      const { setAlertSenderPhoneUpdated, orderStatusSubscription, subscriptionSelections } = this.props;

      if (orderStatusSubscription) {
        alertSenderPhone = subscriptionSelections.includes(orderStatusSubscription.subscriptionId);
      } else {
        alertSenderPhone = !alertSenderPhone;
      }
      setAlertSenderPhoneUpdated(alertSenderPhone);
      this.handleContactBlur();
    }

    onExpand = (expand) => {
      this.setState({ openForm: expand });
    }

  checkRequiredContactFields = (sender) => {
    if (!sender) {
      return false;
    }

    const requiredFields = ['firstName', 'lastName', 'phone', 'email'];
    let isError = false;

    requiredFields.forEach((field) => {
      if (sender[field] === '') {
        isError = true;
      }
    });

    return !isError;
  }

  setErrorsFromCheckout = (errors) => {
      const { state } = this;

      if (!errors) {
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(errors)) {
        const keyParts = key.split('_');
        if (keyParts[0] === 'contact' && state[`${keyParts[1]}ErrorMessage`] !== errors[key][0].message) {
          setErrorState(this, keyParts[1], false, value[0].message);
        }
      }
  }

    render () {
        const { userOrderMethod, checkout, setContactFormError } = this.props;
        const { openForm, sender } = this.state;

        const isPickup = userOrderMethod === 'PICKUP';
        const senderDefined = this.checkRequiredContactFields(checkout.sender);

        let incomplete = !senderDefined;

        if (this.verifyPhone()) {
          incomplete = incomplete || !this.hasVerifiedPhone() || this.isNewPhoneNumber();
        }

        this.setErrorsFromCheckout(checkout.errors);
        setContactFormError(checkout.errors);

        return (
          <div className={`
            ${styles.detailsSection}
            ${isPickup ? styles.pickupContactsContainer : null}
          `}
          >
            <h3 className={`yourContactDetailsLabel border-b-[3px] border-borderLight h-9
                    font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] text-dark dark:text-white`}
            >
                  Your Contact Details
            </h3>

            <CheckoutContainer
              type="contact"
              isAlert={incomplete}
              header="Your Contact Information"
              component={this}
              onChange={() => this.handleContactBlur()}
              onExpand={(val) => this.onExpand(val)}
              subHeader={!senderDefined ? 'Need contact information' : `${sender.firstName} ${sender.phone}`}
              subHeaderClass={!senderDefined ?
                  classes.checkoutPage.contactInfo.alertMsg.error :
                  classes.checkoutPage.contactInfo.alertMsg.success}
              expanded={openForm}
            >

              {this.renderContactOptions()}
            </CheckoutContainer>
          </div>
        );
    };
}

ContactDetailsWidget.defaultProps = {
  checkout: {errors: {}},
  orderStatusSubscription: null,
  toggleSubscription: () => {},
  subscriptionSelections: []
};

ContactDetailsWidget.propTypes = {
    order: PropTypes.shape({
      customer: PropTypes.shape(),
      sender: PropTypes.shape(),
      code: PropTypes.string,
      orderTypeId: PropTypes.number
    }).isRequired,
    checkout: PropTypes.shape({
      sender: PropTypes.shape(),
      errors: PropTypes.shape(),
    }),
    orderUpdated: PropTypes.func.isRequired,
    checkoutUpdated: PropTypes.func.isRequired,
    checkoutErrorsUpdated: PropTypes.func.isRequired,
    userOrderMethod: PropTypes.string.isRequired,
    setAlertSenderPhoneUpdated: PropTypes.func.isRequired,
    alertSenderPhone: PropTypes.bool.isRequired,
    orderStatusSubscription: PropTypes.shape({
      defaultValue: PropTypes.bool,
      description: PropTypes.string,
      subscriptionId: PropTypes.string,
    }),
    toggleSubscription: PropTypes.func,
    subscriptionSelections: PropTypes.arrayOf(PropTypes.string),
    setContactFormError: PropTypes.func.isRequired
}

export const mapStateToProps = state => {
  const { userOrderMethod, alertSenderPhone } = state.user;
    return {
        order: state.order,
        checkout: state.checkout,
        userOrderMethod,
        alertSenderPhone,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    orderUpdated: (value) => dispatch(orderActions.orderUpdated(value)),
    setAlertSenderPhoneUpdated: (value) => dispatch(setAlertSenderPhone(value)),
    checkoutUpdated: (value) => dispatch(checkoutActions.checkoutUpdated(value)),
    checkoutErrorsUpdated: (value) => dispatch(checkoutActions.checkoutErrorsUpdated(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsWidget);
