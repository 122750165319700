/* eslint-disable import/prefer-default-export */
export const orderTypeIds = {
    delivery: 2,
    pickup: 3,
    shipping: 6,
    doughPro: 11,
    eGiftCard: 8
};

export const orderMethods = {
    delivery: 'DELIVERY',
    pickup: 'PICKUP',
    shipping: 'SHIPPING',
    doughPro: 'DOUGH_PRO',
    eGiftCard: 'EMAIL_GIFTCARD'
};

export const CANCELLATION_POLICY_TEXT = "If order is cancelled there is no refund, order may be updated by calling local store."

export const paymentMethodIds = {
    creditcard: 1,
    giftcard: 2,
    schoolcash: 3,
    cash: 4
};

export const totalTitles = {
    total: 'Total'
};

export const recipientMessageLength = {
    shippingOrder: 200,
    anotherOrder: 150
};

export const instructionsLength = {
    defaultLength: 100
};

export const shippingMethodRequirements = {
    shippingMethodMiss: 'Need shipping method',
    shippingAddressMiss: 'Please add an address to see shipping rates'
};

export const orderCompleteRequirementMessages = {
    pickupTime: 'Please review Pickup Time',
    deliveryTime: 'Please review Delivery Time',
    shippingDetails: 'Please review Shipping Details',
    contactDetails: 'Please review Your Contact Details',
    PaymentMethod: 'Please review Payment Method',
    shippingAddress: 'Address missed.'
};
export const orderCompleteRequirementClassNames = {
    pickupTime: 'selectPickupTime',
    deliveryTime: 'selectDeliveryTime',
    shippingDetails: 'shippingDetailsLabel',
    contactDetails: 'yourContactDetailsLabel',
    PaymentMethod: 'paymentMethodArea',
    giftArea: 'giftArea',
};

export const orderTrackerMessages = {
    pickOrderTrack: "We've got </br> your order!",
    pickupOrderTrackSubhead: "Enjoy!",
    defaultOrderTrack: "Your order is comin&apos; at ya"
};

export const dateTimeFormat = {
    yearMonthDayHourMinuteAMPM: 'YYYY-MM-DD hh:mm a',
    yearMonthDayHourMinuteSecond: 'YYYY-MM-DD HH:mm:ss',
    monthDayTime: 'MMMM Do [-] h:mm A',
    yearMonthDay: 'YYYY-MM-DD'
};