/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';

import _ from 'underscore';

import PropTypes from 'prop-types';

// REDUX
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import * as elementsActions from '../../redux/actions/elements';
import * as userActions from '../../redux/actions/user';
import Loader from '../../components/Loader/Loader';
import {
  REMOVE_ITEM_FROM_ORDER_MUTATION,
} from '../../graphql/order';

import * as orderRepo from '../../shared/repos/graphql/order';
import * as checkoutRepo from "../../shared/repos/graphql/checkout";
import * as shippingRepo from "../../shared/repos/graphql/shipping";

// COMPONENTS
import OrderReviewWidget from '../../components/OrderReviewWidget/OrderReviewWidget';
import Button from '../../components/Button/Button';
import UpsellProduct from '../UpsellProduct/UpsellProduct';
import '@brainhubeu/react-carousel/lib/style.css';

import styles from './ViewOrder.module.css';
import {getTotalPrice} from "../../shared/utilities/renderings";
import { openFreeCookiePDPModal } from '../../shared/utilities/modals';
import endpoints from '../../shared/constants/endpoints';
import orderTotals from '../../shared/constants/orderTotals';
import messages from '../../shared/constants/messages';
import { store } from '../../redux/store';
import { orderMethods , orderTypeIds } from '../../shared/constants/order';
import { checkoutOrderMinAmount } from "../../shared/utilities/orders";
import { routeCountryPath } from '../../shared/utilities/common';

import { defaultCurrency } from '../../shared/constants/currency';

const arrowIcon = require('./imgs/back-icon.svg');
const LightArrowIcon = require('./imgs/light-back-icon.svg');

class ViewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giftBoxId: 283,
      data: {
          order: {
            items: [],
            totals: [],
            upsellProducts: []
          }
      },
      orderDetailsLoading: true,
      orderTotalLoading: true,
      freeTraditionalProductRedirect: true,
      unavailableProducts: []
    };
  }

  componentDidMount() {
    this.viewOrderDetails();
  }

  componentDidUpdate(prevProps) {
    const { order } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (prevProps.order !== order) {
      this.viewOrderDetails();
    }
  }

  /**
   * Render line item UI
   * @param {String} label
   * @param {Number } price
   * @param {Boolean} isTotal
   */
  renderLineItem = (label, price, isTotal, isDiscount = false) => (
    <div
      key={label}
      className={[
        styles.lineItemContainer,
        isTotal ? `${styles.isTotal} text-dark dark:text-white` : "text-darkElevationPrimary dark:text-light"
      ].join(' ')}
    >
      <p>{label}</p>
      <p>
        {
          !isNaN(price) && price !== undefined && price !== null && price !== (0).toFixed(2)
              ? `${isDiscount && price > 0? '-' : ''}$${parseFloat(price).toFixed(2)}`
              : `$${(0).toFixed(2)}`
        }
      </p>
    </div>
  );

  cartHasFreeTraditionalCookie = (product) => {
    if (!product) {
      return false;
    }

    const {userCart} = this.props;

    if (!userCart || userCart.length === 0) {
      return false;
    }

    return userCart.filter((item) => {
      return item &&
        item.product &&
        parseInt(item.product.id, 10) === parseInt(product.id, 10);
    }).length > 0;
  }

  renderFreeTraditionalCookieRedirectModal = () => {

      const { setModalObject, doughProMember, modalObject, userCart } = this.props;
      const { freeTraditionalProductRedirect } = this.state;
      const freeTraditionalProduct = doughProMember ? doughProMember.freeTraditionalProduct : null;

      const noUserCart = !userCart || !userCart.length;
      const notEligible = !freeTraditionalProduct || modalObject || !freeTraditionalProductRedirect || this.cartHasFreeTraditionalCookie(freeTraditionalProduct);
      const notDoughProMember = !doughProMember || !doughProMember.active;

      if (noUserCart || notEligible || notDoughProMember) {
          return;
      }

      setModalObject(openFreeCookiePDPModal(this.cancelFreeCookie, this.openFreeCookiePDP));
  }

  cancelFreeCookie = () => {

      const { setModalObject } = this.props;

      this.setState({
          freeTraditionalProductRedirect: false
      });

      setModalObject(null);
  }

  openFreeCookiePDP = () => {

      const { setModalObject, history, doughProMember } = this.props;
      const { freeTraditionalProduct } = doughProMember;

      setModalObject(null);

      history.push(routeCountryPath(endpoints.productCustomize.replace(':id', freeTraditionalProduct.id)));
  }

  viewOrderDetails = async () => {
    const { userCartId } = this.props;

    if (!userCartId) {
      return;
    }

    this.setState({
      orderDetailsLoading: true
    });

    const { order } = store.getState();
    if(order === null) {
      const response = await orderRepo.getOrder(userCartId);
      const { data } = response
      const hasItem = data.order && data.order.items;

      if(hasItem) {
        this.setState({
          data
        });
      }

    } else {
      const data = {
        order
      };

      const hasItems = data.order && data.order.items;
      
      if (hasItems) {
        this.setState({
          data
        });
      }
    }

    this.setState({
      orderDetailsLoading: false,
    });

    this.getUnavailableProducts()
  };

  getUnavailableProducts = () => {
    const { order } = store.getState();
    const isShippingOrder = order.orderTypeId === orderTypeIds.shipping;
    const hasShippingAddress = order.shippingAddress && order.shippingAddress.postcode;
    let unavailableProducts = [];
    if (isShippingOrder && hasShippingAddress) {
        shippingRepo.shippingUnavailableProducts(order.shippingAddress.postcode, order.code)
        .then(response => {
          unavailableProducts = response.data.shippingUnavailableProducts.unavailableProducts;
          this.setState({unavailableProducts})
        }).catch(() => {});
    }
  }

  updateCart = () => {
      this.viewOrderDetails();
  };

  totals = data => {
      const { userCart } = this.props;
      const { orderTotalLoading } = this.state;
      const orderHasItems = userCart.length > 0 && data && data.order;
      let serviceFeeTotal = null;

      if (!orderHasItems) {
          return null;
      }

      return (
        <div className={`${styles.orderCostContainer}  bg-white dark:bg-dark px-8 py-10 border-[1px] border-lightDry dark:border-darkBorder
        rounded-[20px]`}
        >
          {!orderTotalLoading ? (
            <div style={{
                'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center',
                'position': 'absolute', 'width': '70%', 'height': '25%'
              }}
            >
              <Loader height={40} />
            </div>
          ) : null}
          {data.order.totals.filter(item => {
              const isCoupon = item.title.toLowerCase() === 'coupons';
              const hasValue = item.value > 0;
              const isTotal = item.title.toLowerCase() === 'total';

              if (orderTotals.serviceFee.title.toLowerCase() === item.title.toLowerCase()) {
                serviceFeeTotal = item;

                return false;
              }

              return !isCoupon && !isTotal && hasValue;
          }).map(item => {
            let itemTitle = item.title;
            const isloyalty = itemTitle.toLowerCase() === orderTotals.loyalty.title.toLowerCase();

            if (
              orderTotals.tax.title.toLowerCase() === itemTitle.toLowerCase() &&
              serviceFeeTotal
            ) {
              itemTitle = 'Taxes & Fees';
            }
            return this.renderLineItem(itemTitle, item.value.toFixed(2), false, isloyalty);
          })}

          {data.order.discounts.map(item => {
              return this.renderLineItem(item.title, item.value.toFixed(2), false, true);
          })}

          {data.order.totals.filter(item => {
              const isTotal = item.title.toLowerCase() === orderTotals.total.title.toLowerCase();
              return isTotal;
          }).map(item => {
              return this.renderLineItem(item.title, item.value.toFixed(2), true);
          })}
        </div>
      );
  };

  onGiftClick = item => {
    const { userCartId } = this.props;
    const { giftBoxId } = this.state;

    const productOptions = _.pluck(item.productOptions, 'optionIdProductId');

    if (productOptions.includes(giftBoxId)) {
      productOptions.splice(productOptions.indexOf(giftBoxId), 1);
    } else {
      productOptions.push(giftBoxId);
    }
    
    const data = {
      orderProductId: parseInt(item.id, 10),
      product: item.product.id,
      quantity: 1,
      productOptions
    };

    orderRepo.addUpdateOrderProduct(userCartId, data)
      .then(response => {
        const order = response.data.updateProductOrder;
        (async() => {
          await checkoutRepo.getOrder(order.code, []);
          this.updateCart();
        })();
      });
  };


  getOptionTotals = (item) => {
    const { data } = this.state;
    const productItem = item;
    const isRedeemable = data?.order?.orderRedeemables && data?.order.orderRedeemables.length > 0;
    if (isRedeemable) {
      const orderRedeemable = data?.order?.orderRedeemables.find(p => {
        return p.order_product_id === item.id
      })
      productItem.product.price = orderRedeemable ? productItem.price : productItem.product.price;
    }

    const totals = getTotalPrice(productItem)
    return parseFloat(totals).toFixed(2);
  }

  goToCheckout = () => {
    const {history, userCart, userCartId, userOrderMethod, orderMinAmount, currency} = this.props;
    if (userCart.length > 0) {
      if (userOrderMethod === orderMethods.delivery) {
        const cartIconLink = endpoints.checkoutOrderPage.replace(':id', userCartId);
        const totalPrice = userCart.map(item => item.quantity * item.price).reduce((a, b) => a + b, 0);
        if (totalPrice < orderMinAmount) {
          const currencySymbol = currency ? currency.symbol : defaultCurrency.symbol;
          checkoutOrderMinAmount(orderMinAmount, currencySymbol);
        } else {
          history.push(routeCountryPath(cartIconLink));
        }
      } else {
        history.push(routeCountryPath(endpoints.checkoutOrderPage.replace(':id', userCartId)))
      }
    } else {
      return null;
    }
  }

  viewOrderContainer = () => {
    const {
      history,
      userOrderMethod,
      selectedStore,
      userAddress,
      userCart
    } = this.props;

    const { data, orderDetailsLoading, unavailableProducts, giftBoxId } = this.state;

    const [removeItemMutation, { loading: removeItemLoading }] = useMutation(
      REMOVE_ITEM_FROM_ORDER_MUTATION,
      {
        onCompleted: async(res) => {
          const order = res.removeOrderItemsFromOrder;
          this.setState({
            orderTotalLoading: false
          })
          await checkoutRepo.getOrder(order.code, []);
          this.setState({
            orderTotalLoading: true
          })
          this.updateCart();
        }
      }
    );

    if (orderDetailsLoading && userCart.length !== 0) {
      return (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      );
    }
    const { submitButtonLabel } = messages.viewOrder;
    const { loyaltyMultiplier } = data.order;
    const isShipping = userOrderMethod === 'SHIPPING';
    const isPickup = userOrderMethod === 'PICKUP';
    const isDelivery = userOrderMethod === 'DELIVERY';
    const specialPackagingId = 1951;
    const showPickupDeliveryText = (isPickup || (isDelivery && userAddress))
        && userCart.length > 0;

    if (isDelivery) {
      this.renderFreeTraditionalCookieRedirectModal()
    }

    return (
      <div className={`${styles.pageWrapper} lg:max-w-screen-desktop mx-auto py-12 px-4 lg:px-0`}>
        <div className={`${styles.pageContainer} flex flex-col gap-2 font-filsonProRegular`}>
          {removeItemLoading ? (
            <div className={styles.loaderWidget}>
              <Loader />
            </div>
          ) : null}
          {/* PAGE TITLE */}
          <div className={`${styles.headerBlock} flex items-center justify-between`}>
            <h3
              className=' text-dark dark:text-white flex items-center gap-4' 
              onClick={() => history.push(routeCountryPath(endpoints.getMenuUrl(null)))}
            >
              <span>
                <img alt="back" src={arrowIcon} className='block dark:hidden' />
                <img alt="back" src={LightArrowIcon} className='hidden dark:block' />
                
              </span>
              Back to Menu
            </h3>

            {userCart.length > 0 && data && data.order ? (
              <div className={styles.actionsContainer}>
                <Button
                  handleSubmit={() => this.goToCheckout()}
                  disabled={removeItemLoading}
                  customContainerStyles={styles.submitButton}
                  label={submitButtonLabel}
                />
              </div>
            ) : null}
          </div>

          {isShipping && userAddress ? (
            <p className='font-filsonProRegular leading-5 text-dark dark:text-white' id={styles.shippingAddress}>
              {`Shipping to ${userAddress.address1}`}
            </p>
          ) : null}

          {showPickupDeliveryText ? (
            <div id={styles.pickUpAddress}>
              <p className='font-filsonProRegular leading-5 text-dark dark:text-white'>
                {isPickup
                  ? `Picking up from ${selectedStore.name}`
                  : `Delivering to ${userAddress.address1}`}
              </p>
            </div>
          ) : null}

          {/* SUGGESTED DELIVERY CAROUSEL */}
          {(isPickup || isDelivery) && userCart.length > 0 ? (
            <UpsellProduct order={data.order} />
          ) : null}

          {userCart.length > 0 && data && data.order ? (
            <p className="orderItemCount text-dark dark:text-white">
              {userCart.length}
              {' '}
Item Order
            </p>
          ) : (
            <p className='text-dark dark:text-white mt-2'>Your cart is empty.</p>
          )}

          {data.order.items.map((item) => {
            let giftBoxPrice = 0;
            const hasPackaging = item.product && item.product.options ? item.product.options.find(productOption => {
                if (parseInt(productOption.id, 10) === specialPackagingId) {
                    const boxOption = productOption.options.find(option => {
                        return parseInt(option.id, 10) === giftBoxId;
                    });

                    if (boxOption) {
                        giftBoxPrice = boxOption.price;

                        return true;
                    }

                    return false;
                }

                return false;
            }) : null;

            const giftBoxSelected = item.productOptions ? item.productOptions.find(productOption => parseInt(productOption.optionIdProductId, 10) === giftBoxId) : false;

            const isRedeemable = data?.order?.orderRedeemables && data?.order.orderRedeemables.length > 0;
            let redeemablePoints = loyaltyMultiplier
            if (isRedeemable) {
              const orderRedeemable = data?.order?.orderRedeemables.find(p => {
                return p.order_product_id === item.id
              })
              redeemablePoints = orderRedeemable ? orderRedeemable.points : loyaltyMultiplier;
            }

            const widget = item.product ? (
              <OrderReviewWidget
                order={data.order}
                giftBoxPrice={giftBoxPrice.toFixed(2)}
                addGiftBox={hasPackaging}
                isGift={giftBoxSelected}
                onGiftClick={() => this.onGiftClick(item)}
                item={item}
                key={item.id}
                removeItemMutation={removeItemMutation}
                amount={item.quantity}
                itemTitle={item.product.title}
                type={item.product.categories.length > 0 ? item.product.categories[0].title : ''} // eslint-disable-line
                loyaltyMultiplier={redeemablePoints}
                itemPrice={this.getOptionTotals(item)}
                unavailableProducts={unavailableProducts}
                isRedeemable={isRedeemable}
              />
            ) : null;

            return widget;
          })}

          {this.totals(data)}

        </div>
      </div>
    );
  };

  render() {
    return <this.viewOrderContainer />;
  }
}

ViewOrder.propTypes = {
  userOrderMethod: PropTypes.string.isRequired,
  setModalObject: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  order: PropTypes.shape().isRequired,
  doughProMember: PropTypes.shape({
    freeTraditionalProduct: PropTypes.shape({
      id: PropTypes.number
    })
  }),
  orderMinAmount: PropTypes.number.isRequired,
  currency: PropTypes.objectOf(PropTypes.string),
};

export const mapStateToProps = (state) => {
  const {
    userOrderMethod,
    selectedStore,
    userAddress,
    userCart,
    userCartId,
    doughProMember
  } = state.user;

  const {
    modalObject,
    orderMinAmount,
  } = state.elements;

  const { order } = state;
  const { currency } = state.currency;

  return {
    userOrderMethod,
    selectedStore,
    userAddress,
    userCart,
    userCartId,
    order,
    doughProMember,
    modalObject,
    orderMinAmount,
    currency,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  setModalObject: (value) => dispatch(elementsActions.setModalObject(value)),
  setUserOrderMethod: (value) => dispatch(userActions.setUserOrderMethod(value)),
  setUserCart: (value) => dispatch(userActions.setUserCart(value)),
  setUserAddress: (value) => dispatch(userActions.setUserAddress(value)),
  setSelectedStore: (value) => dispatch(userActions.setSelectedStore(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrder);
