/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

export const PRODUCT_QUERY = gql`
  query product($id: ID!, $redeemableId: String) {
    product(where: { id: $id }, redeemableId: $redeemableId) {
      id
      title
      description
      nutritionInfoLink
      calories
      allergens
      price
      strike_price
      categories {
        id
        title
        slug
        parentId
      }
      options {
        id
        price
        header
        groupId
        maxOptions
        minOptions
        groupTitle
        label
        options {
          id
          title
          image
          price
          calories
        }
        optionGroupType
      }
      productImage
      maxQuantity,
      stores {
        id,
        status
        currency {
            name,
            code,
            symbol
        }
      }
      amountOptions {
        duration
        amount
      }
      availability
    }
  }
`;

export const FIRST_PRODUCT_QUERY = gql`
  query products {
    products(first: 1) {
      id
      title
      description
      calories
      allergens
      price
      productImage
    }
  }
`;

export const STORE_MENU_QUERY_V2 = gql`
  fragment Products on Product {
    id
    title
    productImage
    productImageSmall
    description
    accentImage
    featured
    productCount
    hasAssortments
    price
    strike_price
    upsellFeatured
    options {
      id
    }
    metaTags{
      title
    }
    calories
  }
  query STORE_MENU($id: ID!) {
    store(where: { id: $id }) {
      id
      menuV2 {
        category
        slug
        description
        products {
          ...Products
        }
        children {
          category
          slug
          description
          products {
            ...Products
          }
        }
      }
      categoriesList {
         slug
      }
      currency {
        id,
        name,
        code,
        symbol
      }
    }
  }
`;

export const STORE_PRODUCT_STOCK_QUERY = gql`
  query STORE_PRODUCT_STOCK_QUERY($orderCode: String!, $storeId: ID!) {
    storeProductStock(orderCode: $orderCode, storeId: $storeId) {
      orderProductId
      id
      title
      oos
    }
  }
`;

export const  PRODUCT_ASSORTMENT_QUERY = gql`
    query PRODUCT_ASSORTMENT($productId:ID!, $groupIds: [ID!]!, $storeId: ID!) {
        productAssortment(productId: $productId, groupIds: $groupIds, storeId: $storeId) {
            id
            groupId
            options {
                id
                productId
                quantity
            }
        }
    }
`;
