import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DropDown from "../Dropdown/SimpleDropdown";
import styles from "./PhoneWidget.module.css";
import Input from "../Input/SimpleInput";
import { handleFormInput } from "../../shared/utilities/validations";
import { phoneNumberFormatValidation } from "../../shared/utilities/common";
import * as countryRepo from "../../shared/repos/graphql/country";
import * as phoneService from "../../shared/utilities/phone";
// REDUX
import * as countryActions from "../../redux/actions/country";
import * as checkoutActions from "../../redux/actions/checkout";

const defaultMask = "(###) ###-####";

class PhoneWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneCodes: [],
      selectOptions: [],
      phoneCode: "",
      phonePhone: "",
      telephone: "",
      currentPhoneMask: defaultMask
    };
  }

  componentDidMount = () => {
    this.getPhoneCodes().then(() => {
      const { code, phone, telephone } = this.props;
      const { phoneCodes } = this.state;
      if (code || phone) {
        this.setUpPhoneData(code, phone);
      } else if (telephone) {
        this.setupTelephone(telephone);
      } else if (phoneCodes.length > 0) {
        this.setupDefaultPhoneCode();
      }
    });
  };

  setUpPhoneData = (code, phone) => {
    const { phoneCodes } = this.state;
    const maskRegex = phoneService.castMaskFormat(phoneService.detectMask(code, phoneCodes));
    this.setState({
      phoneCode: code,
      phonePhone: phone,
      currentPhoneMask: maskRegex
    });
  };

  setupDefaultPhoneCode = () => {
    const { phoneCodes } = this.state;
    const { selectedCountry, isCountrySelectActive } = this.props;
    if (phoneCodes.length > 0) {
      let isPhoneCodeFound = false;
      const countryChanged = isCountrySelectActive && selectedCountry && selectedCountry.phone_prefix_id;
      if (countryChanged) {
        const phoneCode = _.find(phoneCodes, {
          id: selectedCountry.phone_prefix_id.toString()
        });
        if (phoneCode) {
          const mask = phoneService.castMaskFormat(phoneCode.mask);
          this.setState({
            currentPhoneMask: mask,
            phoneCode: phoneCode.code
          });
          isPhoneCodeFound = true;
        }
      }
      if (isPhoneCodeFound === false) {
        const mask = phoneService.castMaskFormat(phoneCodes[0].mask);
        this.setState({
          currentPhoneMask: mask,
          phoneCode: phoneCodes[0].code
        });
      }
    }
  };

  setupTelephone = phone => {
    const rawPhone = phoneService.detectPhone(phone);
    const code = phoneService.detectCountryCode(phone);
    const { phoneCodes } = this.state;
    let mask = defaultMask;
    if (code) {
      mask = phoneService.castMaskFormat(phoneService.detectMask(code, phoneCodes));
    }
    this.setState({
      telephone: phone,
      phoneCode: code,
      phonePhone: rawPhone,
      currentPhoneMask: mask
    });
  };

  getPhoneCodes = () => {
    const { setPhonePrefixes } = this.props;
    const promise = new Promise(resolve => {
      countryRepo.getPhonePrefixes().then(res => {
        setPhonePrefixes(res.data.phonePrefixes);
        this.updatePhonePrefixes(res.data.phonePrefixes, resolve);
      });
    });

    return promise;
  };

  updatePhonePrefixes = (prefixes, resolve) => {
    const options = _.map(prefixes, optionCode => {
      return {
        value: optionCode.code,
        label: `${this.getFlagEmoji(optionCode.country.flag)}  ${optionCode.code}`
      };
    });
    this.setState(
      {
        selectOptions: options,
        phoneCodes: prefixes
      },
      () => {
        const { phoneCodes } = this.state;
        resolve(phoneCodes);
      }
    );
  };

  getFlagEmoji = (countryCode) => {
    return countryCode.toUpperCase().replace(/./g, char => 
        String.fromCodePoint(127397 + char.charCodeAt())
    );
  }

  validateMask = (mask, phone) => {
    const maskLength = mask.split("#").length - 1;
    return phone.length === maskLength;
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { phonePhone, phoneCode, telephone, currentPhoneMask } = this.state;
    const { onInput, fieldName } = this.props;

    let phoneNumberChanged = prevState.phonePhone !== phonePhone ||
      prevState.phoneCode !== phoneCode

    if (phoneNumberChanged) {
      this.updatePhoneNumber(phoneCode, phonePhone);
    }

    phoneNumberChanged = prevState.telephone !== telephone
    if (phoneNumberChanged) {
      const phone = phoneService.formatPhoneNumber(phonePhone);
      onInput(fieldName, {
        telephone: phone && phone.trim() !== "" ? telephone : "",
        phone,
        code: phoneCode,
        mask: currentPhoneMask,
        phoneFormat: phonePhone,
        maskValidation: this.validateMask(
          currentPhoneMask,
          phonePhone.replace(/\D/g, "")
        )
      });
    }
    this.onPropsTelephoneUpdate(prevProps.telephone);
  };

  onPropsTelephoneUpdate = prevPhone => {
    const { telephone } = this.props;
    if (prevPhone && prevPhone !== telephone) {
      const prefix = phoneService.detectCountryCode(telephone);
      const postfix = phoneService.detectPhone(telephone);
      if (prefix) {
        this.setState({
          phonePhone: postfix,
          phoneCode: prefix
        });
      } else {
        this.setState({
          phonePhone: postfix
        });
      }
    }
  };

  updatePhoneNumber = (prefix, phone) => {
    this.setState({
      telephone: prefix
        ? `${prefix} ${phone.replace(/\D/g, "")}`
        : `${phone.replace(/\D/g, "")}`
    });
  };

  dropDownChange = e => {
    const {
      phoneCodes,
      telephone,
      phoneCode,
      phonePhone,
      currentPhoneMask
    } = this.state;
    const { handleOnBlur, checkoutUpdated, checkout } = this.props;
    const mask = phoneService.castMaskFormat(
      phoneService.detectMask(e.value, phoneCodes)
    );

    checkout.phoneCode = e.value;
    checkoutUpdated(checkout);

    this.setState(
      {
        phoneCode: e.value,
        currentPhoneMask: mask
      },
      () => {
        const phone = phonePhone.replace(/\D/g, "");
        handleOnBlur({
          telephone: phone && phone.trim() !== "" ? telephone : "",
          phone,
          code: phoneCode,
          mask: currentPhoneMask,
          phoneFormat: phonePhone,
          maskValidation: this.validateMask(
            currentPhoneMask,
            phonePhone.replace(/\D/g, "")
          )
        });
      }
    );
  };

  handleOnBlur = () => {
    const { handleOnBlur } = this.props;
    const { telephone, phoneCode, phonePhone, currentPhoneMask } = this.state;
    const phone = phonePhone.replace(/\D/g, "");
    handleOnBlur({
      telephone: phone && phone.trim() !== "" ? telephone : "",
      phone,
      code: phoneCode,
      mask: currentPhoneMask,
      phoneFormat: phonePhone,
      maskValidation: this.validateMask(
        currentPhoneMask,
        phonePhone.replace(/\D/g, "")
      )
    });
  };

  PhoneWidgetContainer = () => {
    const {
      phonePhone,
      currentPhoneMask,
      selectOptions,
      phoneCode
    } = this.state;
    const {
      label,
      errorMessage,
      isEdit,
      isRequired,
      checkout
    } = this.props;

    return (
      <div
        className={`
      flex items-center justify-center gap-2 border-[1px]
      border-darkElevationPrimary bg-transparent w-full rounded-[40px] h-11 px-2
    `}
      >
        <DropDown
          onChange={e => this.dropDownChange(e)}
          options={selectOptions}
          value={checkout?.phoneCode || phoneCode}
          isRequired={isRequired}
          theme={!isEdit ? "Dark" : "Light"}
          customDropDownStyles=""
          className={`${styles.dropdown} phoneWidget`}
        />
        <Input
          customContainerStyles="!bg-transparent !outline-none !border-none h-11"
          handleInput={e => handleFormInput(e, this, "phonePhone")}
          label={label}
          handleOnBlur={e => this.handleOnBlur(e)}
          placeholder={defaultMask}
          theme={!isEdit ? "Dark" : "Light"}
          value={phonePhone}
          inputMask={currentPhoneMask}
          isNumberFormat
          isAllowed={phoneNumberFormatValidation}
          errorMessage={errorMessage}
          isRequired={isRequired}
          fieldClass="w-full bg-transparent outline-none h-11 text-white"
        />
      </div>
    );
  };

  render = () => {
    return <this.PhoneWidgetContainer />;
  };
}
PhoneWidget.defaultProps = {
  telephone: "",
  fieldName: '',
  code: "",
  phone: "",
  label: "",
  errorMessage: "",
  isEdit: false,
  onInput: () => {},
  handleOnBlur: () => {},
  setPhonePrefixes: () => [],
  isRequired: false,
  isCountrySelectActive: false
};

PhoneWidget.propTypes = {
  telephone: PropTypes.string,
  fieldName: PropTypes.string,
  code: PropTypes.string,
  phone: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  onInput: PropTypes.func,
  handleOnBlur: PropTypes.func,
  isEdit: PropTypes.bool,
  setPhonePrefixes: PropTypes.func,
  isRequired: PropTypes.bool,
  isCountrySelectActive: PropTypes.bool,
  checkout: PropTypes.shape().isRequired,
  checkoutUpdated: PropTypes.func.isRequired,
  selectedCountry: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    flag: PropTypes.string,
    path_url: PropTypes.string,
    api_url: PropTypes.string,
    short_name: PropTypes.string,
    url: PropTypes.string,
    zone_id: PropTypes.string,
    phone_prefix_id: PropTypes.number,
    currency: {
      code: PropTypes.string,
      name: PropTypes.string,
      symbol: PropTypes.string
    }
  }).isRequired
};

export const mapStateToProps = state => {
  const {
    phonePrefixes,
    selectedCountry,
    isCountrySelectActive
  } = state.country;
  return {
    phonePrefixes,
    selectedCountry,
    isCountrySelectActive,
    checkout: state.checkout
  };
};

export const mapDispatchToProps = dispatch => ({
  setPhonePrefixes: value => dispatch(countryActions.setPhonePrefixes(value)),
  checkoutUpdated: value => dispatch(checkoutActions.checkoutUpdated(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneWidget);
