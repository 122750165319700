import { Region, Stack } from "contentstack";

export const getConfig = () => ({
  // Will be available on both server and client
  CONTENTSTACK_API_KEY: window.environment.REACT_APP_CONTENTSTACK_API_KEY,
  CONTENTSTACK_DELIVERY_TOKEN:
    window.environment.REACT_APP_CONTENTSTACK_DELIVERY_TOKEN,
  CONTENTSTACK_BRANCH:
    window.environment.REACT_APP_CONTENTSTACK_BRANCH || "main",
  CONTENTSTACK_REGION: window.environment.REACT_APP_CONTENTSTACK_REGION || "us",
  CONTENTSTACK_ENVIRONMENT:
    window.environment.REACT_APP_CONTENTSTACK_ENVIRONMENT,
  CONTENTSTACK_MANAGEMENT_TOKEN:
    window.environment.REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
  CONTENTSTACK_API_HOST:
    window.environment.REACT_APP_CONTENTSTACK_API_HOST || "api.contentstack.io",
  CONTENTSTACK_APP_HOST:
    window.environment.REACT_APP_CONTENTSTACK_APP_HOST ||
    "app.contentstack.com",
  CONTENTSTACK_LIVE_PREVIEW:
    window.environment.REACT_APP_CONTENTSTACK_LIVE_PREVIEW || "true",
  CONTENTSTACK_LIVE_EDIT_TAGS:
    window.environment.REACT_APP_CONTENTSTACK_LIVE_EDIT_TAGS || "false"
});

// basic env validation
export const isBasicConfigValid = () => {
  const {
    CONTENTSTACK_API_KEY,
    CONTENTSTACK_DELIVERY_TOKEN,
    CONTENTSTACK_ENVIRONMENT
  } = getConfig();
  return (
    !!CONTENTSTACK_API_KEY &&
    !!CONTENTSTACK_DELIVERY_TOKEN &&
    !!CONTENTSTACK_ENVIRONMENT
  );
};

// Live preview config validation
export const isLpConfigValid = () => {
  const {
    CONTENTSTACK_LIVE_PREVIEW,
    CONTENTSTACK_MANAGEMENT_TOKEN,
    CONTENTSTACK_API_HOST,
    CONTENTSTACK_APP_HOST
  } = getConfig();
  return (
    !!CONTENTSTACK_LIVE_PREVIEW &&
    !!CONTENTSTACK_MANAGEMENT_TOKEN &&
    !!CONTENTSTACK_API_HOST &&
    !!CONTENTSTACK_APP_HOST
  );
};

// set region
const setRegion = () => {
  const { CONTENTSTACK_REGION } = getConfig();
  let region = "US";
  if (!!CONTENTSTACK_REGION && CONTENTSTACK_REGION !== "us") {
    region = CONTENTSTACK_REGION.toLocaleUpperCase().replace("-", "_");
  }
  return Region[region];
};

// set LivePreview config
const setLivePreviewConfig = () => {
  const {
    CONTENTSTACK_MANAGEMENT_TOKEN,
    CONTENTSTACK_LIVE_PREVIEW,
    CONTENTSTACK_API_HOST
  } = getConfig();
  if (!isLpConfigValid())
    throw new Error(
      "Your LP config is set to true. Please make you have set all required LP config in .env"
    );
  return {
    management_token: CONTENTSTACK_MANAGEMENT_TOKEN,
    enable: CONTENTSTACK_LIVE_PREVIEW === "true",
    host: CONTENTSTACK_API_HOST
  };
};

// contentstack sdk initialization
export const initializeContentStackSdk = () => {
  const {
    CONTENTSTACK_API_KEY,
    CONTENTSTACK_LIVE_PREVIEW,
    CONTENTSTACK_DELIVERY_TOKEN,
    CONTENTSTACK_ENVIRONMENT,
    CONTENTSTACK_BRANCH,
  } = getConfig();

  if (!isBasicConfigValid())
    throw new Error("Please set you .env file before running starter app");
  const stackConfig = {
    api_key: CONTENTSTACK_API_KEY,
    delivery_token: CONTENTSTACK_DELIVERY_TOKEN,
    environment: CONTENTSTACK_ENVIRONMENT,
    region: setRegion(),
    branch: CONTENTSTACK_BRANCH || "main"
  };
  if (CONTENTSTACK_LIVE_PREVIEW === "true") {
    stackConfig.live_preview = setLivePreviewConfig();
  }

  return Stack(stackConfig);
};

// api host url
export const customHostUrl = baseUrl => {
  return baseUrl.replace("api", "cdn");
};
// generate prod api urls
export const generateUrlBasedOnRegion = () => {
  return Object.keys(Region).map(region => {
    if (region === "US") {
      return `cdn.contentstack.io`;
    }
    return `${region}-cdn.contentstack.com`;
  });
};
// prod url validation for custom host
export const isValidCustomHostUrl = url => {
  return url ? !generateUrlBasedOnRegion().includes(url) : false;
};
