/* eslint-disable consistent-return */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import MapComponent from '../../components/MapComponent/MapComponent';
import Loader from '../../components/Loader/Loader';

import styles from './OrderTracker.module.css';
import * as orderRepo from '../../shared/repos/graphql/order';

import {orderTrackerMessages, orderTypeIds} from "../../shared/constants/order";
import messages from "../../shared/constants/messages";

const startIcon = require('./imgs/start-icon.png');
const finishIcon = require('./imgs/finish-icon.png');
const etaIcon = require('./imgs/eta-icon.png');
const storeDefault = require('./imgs/storeDefault.jpg');

class OrderTracker extends Component {
    constructor(props) {
        super(props);

        const trackingID = props.match.params.id;

        this.state = {
            loading: false,
            trackingID
        };
    }

  componentDidMount() {

    const {trackingID} = this.state;

    this.state = {
        loading: false
    };

    this.getOrder(trackingID);
  }


    async getOrder(trackingID) {
        this.setState({
            loading:true
        });

        const result = await orderRepo.getTracker(trackingID);

        const { orderTracker } = result.data;

        this.setState({
            loading: false,
            order: orderTracker
        });
    }

    renderSVG = (x1, x2, y1, y2) => {
        return (
          <svg>
            <line
              stroke="#bc945a"
              strokeLinecap='round'
              strokeDasharray="0.1, 5"
              strokeWidth="1.5"
              x1={x1 || '1'}
              y1={y1 || '1'}
              x2={x2 || '1'}
              y2={y2 || '1'}
            />
          </svg>
        );
    }

    deliveryOrPickupTime = () => {
        const { order } = this.state;

        const time = order.deliveryTime === '0000-00-00 00:00:00' ? 'ASAP' : moment(order.deliveryTime).format('dddd MMM Do [at] h:mma');

        return time === 'Invalid date' ? 'unknown' : time;
    }

    shippingDate = () => {
        const { order } = this.state;
        if (order?.shipment?.estimatedDeliveryDate === "-0001-11-30") { return "Delivered" }
        const date = moment(order?.shipment?.estimatedDeliveryDate).format('dddd MMM Do');
        return date === 'Invalid date' ? 'TBD' : date;
    }

    trackingTime = time => {
        const datetime = moment(time).format('dddd MMM Do');

        return datetime === 'Invalid date' ? 'TBD' : datetime;
    }

    startingLocation = () => {
        const { order } = this.state;
        return order?.orderTypeId !== orderTypeIds?.shipping
            ? order.store.address
            : `${order.shipment.shippingLocation?.addressOne }, ${  order.shipment.shippingLocation?.addressTwo
             }, ${  order.shipment.shippingLocation?.city  }, ${  order.shipment.shippingLocation?.state  }, ${  order.shipment.shippingLocation?.zip}`;
    }

    render() {
        const { loading, order } = this.state;

        if (loading || !order) {
            return (
              <div className={styles.loaderWrapper}>
                <Loader />
              </div>
            );
        }

        const etaByOrderType = {
            2: this.deliveryOrPickupTime,
            3: this.deliveryOrPickupTime,
            6: this.shippingDate
        };

        const storeAddress = {
            lat: order.store.lat,
            lng: order.store.lng
        };

        const shippingStoreAddress = {
            lat: order.shipment?.shippingLocation?.lat,
            lng: order.shipment?.shippingLocation?.lng
        }

        const customerAddress = {
            lat: order.shippingAddress.lat,
            lng: order.shippingAddress.lng
        };

        const startingLocation = this.startingLocation();

        const destinationAddress = [
            order.shippingAddress.address1,
            order.shippingAddress.city,
            order.shippingAddress.state,
            order.shippingAddress.postcode
        ];

        const statusMsgs = {
            2: `eta: ${  etaByOrderType[order.orderTypeId]()}`,
            3:  messages.orderTracker.driverAssignedLabel,
            4: 'The driver is en route',
            5: 'The order has been delivered',
            6: 'The order has been delivered'
        };

        const statusMsg = order.status ? statusMsgs[order.status.status] : statusMsgs[2];
        const isPickup = order?.orderTypeId === orderTypeIds.pickup;
        const isShipping = order?.orderTypeId === orderTypeIds.shipping;
        const trackHeader = isPickup ? orderTrackerMessages.pickOrderTrack : orderTrackerMessages.defaultOrderTrack;

        const etaMsg = isPickup ? order.store.curbsideMessage : statusMsg;

        const isImage = order.store.storefrontImage.substr(order.store.storefrontImage.length - 1) !== '/';
        const storefrontImage = isImage ? order.store.storefrontImage : storeDefault;
        const {deliveryStatus} = order
        const {deliveryImageUrl} = deliveryStatus

        return (
          <div className='w-full'>
            <div className={`${styles.pageWrapper} mx-auto max-w-screen-desktop mt-9 mb-16`}>
              <div className={styles.orderDetailsContainer}>

                <h1 dangerouslySetInnerHTML={{ __html: trackHeader }} />

                <div className={styles.orderDetails}>
                  {
                  !isPickup ? (
                    <div className="svgContainer">
                      <div id={styles.leftRoute}>
                        {this.renderSVG('1', '21', null, null)}
                      </div>

                      <div id={styles.downRoute}>
                        {this.renderSVG(null, null, '1', '60')}
                      </div>

                      <div id={styles.rightRoute}>
                        {this.renderSVG('1', '21', null, null)}
                      </div>
                    </div>
                  ) : null
                }

                  <div className={styles.locationContainer}>
                    <img alt="start-icon" src={startIcon} />

                    <p>{startingLocation}</p>
                  </div>

                  {
                  !isPickup ? (
                    <div className={styles.locationContainer}>
                      <img alt="finish-icon" src={finishIcon} />

                      <p>{destinationAddress.join(', ')}</p>
                    </div>

                  ) : null
                }

                  <div className={[ styles.etaContainer, isPickup ? styles.pickupEtaContainer : null ].join(' ')}>
                    <img alt="eta-icon" src={etaIcon} />
                    <p>
                      {etaMsg}
                      {deliveryImageUrl ? (
                        <span>
                        .
                          {' '}
                          {' '}
                        Please click
                          {' '}
                          <a href={deliveryImageUrl} target="_blank" className={styles.deliveryImageLink} rel="noopener noreferrer">here</a>
                          {' '}
                        to view your delivery image!
                        </span>
                    ) : null}
                    </p>
                  </div>

                  {order.shipment ? (
                    <div>
                      <h2>
                      Shipment tracking for order #
                        {order.id}
                      </h2>
                      <div className={styles.trackingContainer}>
                        <div>
                          <div className={styles.trackingRow}>
                            <p>Tracking Number</p>
                            <p>Delivery Method</p>
                            <p>Customer Name</p>
                          </div>
                        </div>

                        <div>
                          <div className={styles.trackingRow}>
                            <p>{order.shipment.trackingNumber}</p>
                            <p>{order.shipment.shippingMethod}</p>
                            <p>
                              {order.shippingFirstName}
                              {' '}
                              {order.shippingLastName}
                            </p>
                          </div>
                        </div>
                      </div>

                      <h2>Tracking Status</h2>
                      <div className={styles.trackingContainer}>
                        <div>
                          <div className={styles.trackingRow}>
                            <p>Location</p>
                            {order.shipment.tracking.map(item => {
                              return (<p>{item.location}</p>)
                          })}
                          </div>
                        </div>

                        <div>
                          <div className={styles.trackingRow}>
                            <p>Status</p>
                            {order.shipment.tracking.map(item => {
                              return (<p>{item.description}</p>)
                          })}
                          </div>
                        </div>

                        <div>
                          <div className={styles.trackingRow}>
                            <p>Date/Time</p>
                            {order.shipment.tracking.map(item => {
                              return (<p>{this.trackingTime(item.time)}</p>)
                          })}
                          </div>
                        </div>
                      </div>
                    </div>
                ) : null}
                </div>
              </div>

              <div className={styles.mapContainer}>
                {
                // eslint-disable-next-line no-nested-ternary
                isPickup ? (
                  <img alt="finish-icon" className="storefrontImage" style={{width: '100%'}} src={storefrontImage} />
                ) : ( isShipping ? (
                  <MapComponent
                    storeAddress={shippingStoreAddress}
                    customerAddress={customerAddress}
                    myLocation={customerAddress}
                    order={order}
                  />
                ) : (
                  <MapComponent
                    storeAddress={storeAddress}
                    customerAddress={customerAddress}
                    myLocation={customerAddress}
                    order={order}
                  />
                ))
              }
              </div>
            </div>
          </div>
        );
    }
}

OrderTracker.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};

export default OrderTracker;
