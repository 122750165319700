/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Link from '../../../components/LinkWithCountry/LinkWithCountry';
import * as userActions from '../../../redux/actions/user';
import styles from '../ConfirmOrder.module.css';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import * as userRepo from '../../../shared/repos/graphql/user';
import { loginWithFacebook } from '../../../shared/utilities/facebook';
import * as validations from '../../../shared/utilities/validations';
import { isRecaptcha } from "../../../shared/utilities/config";
import CheckBox from "../../../components/CheckBox/CheckBox";
import { routeCountryPath } from '../../../shared/utilities/common';
import subscription from '../../../shared/constants/subscription';
import { getCustomerSubscriptionByCondition } from '../../../shared/utilities/subscription';
import * as elementsActions from "../../../redux/actions/elements";
import {setCaptchaToken} from "../../../shared/utilities/captcha";
import captchaAction from "../../../shared/constants/captchaAction";

const fbIcon = require('./imgs/fb-icon.svg');

class CustomerRegistration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            confirmPassword: '',
            responseErrorMessage: null,
            passwordErrorMessage: null,
            confirmPasswordErrorMessage: null,
            loading: false,
            isChecked: true,
            isShowSubCheckbox: false,
            subCheckboxText: '',
            subscriptionRequestCompleted: false,
        };
    }

    componentDidMount() {
        getCustomerSubscriptionByCondition({
            keyField: subscription.subscriptionKeyField.EMAIL_OPT_IN,
            subscriptionTypeId: subscription.subscriptionType.optIn,
        }).then(sub => {
            if (sub) {
                this.setState({
                    isShowSubCheckbox: true,
                    subCheckboxText: sub.altDescription,
                    isChecked: sub.defaultValue,
                    subscriptionRequestCompleted: true,
                });
            } else {
                this.setState({
                    subscriptionRequestCompleted: true,
                });
            }
        });
    }

    registerCustomer = async () => {
        const { password, confirmPassword, isChecked } = this.state;
        const { order } = this.props;
        const { email } = order;
        this.setState({
            responseErrorMessage: null,
            passwordErrorMessage: null,
            confirmPasswordErrorMessage: null
        });

        if (password !== confirmPassword) {
            return this.setState({
                confirmPasswordErrorMessage: "Passwords don't match"
            });
        }

        if (password.length < 8) {
            return this.setState({
                passwordErrorMessage: 'Password must be greater than 8 characters',
                confirmPasswordErrorMessage: 'Password must be greater than 8 characters'
            });
        }

        this.setState({
            loading: true
        });

        try {

            if (isRecaptcha()) {
              const { setVerificationToken, setVerificationAction } = this.props;
              await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.signup);
            }

            const response = await userRepo.createAccountAndLogin(email, password, null, isChecked);

            this.setState({
                loading: false
            });

            const { signupCustomer } = response.data;
            const { setUserToken, history } = this.props;
            const { token } = signupCustomer;

            setUserToken(token);
            return history.push(routeCountryPath('/user/profile'));
        } catch (exception) {
            const hasValidationErrors = validations.setValidationErrorMessagesFromApiError(exception, this);

            const newState = {
                loading: false,
            };

            if (!hasValidationErrors) {
                newState.responseErrorMessage = exception.message;
            }

            return this.setState(newState);
        }
    };

    loginWithFacebook =() => {
        const { setUserToken, history } = this.props;

        loginWithFacebook(async response => {
            if (response.status === 'connected') {
                return history.push(routeCountryPath('/user/profile'));
            }

            if (isRecaptcha()) {
              const { setVerificationToken, setVerificationAction } = this.props;
              await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.loginWithFacebook);
            }

            const loginResponse = await userRepo.login(null, null, response.authResponse.userID, null);
            if (loginResponse && loginResponse.data) {
                const { login } = loginResponse.data;
                const { token } = login;
                setUserToken(token);
                return history.push(routeCountryPath('/user/profile'));
            }

            this.setState({
                loading: false
            });
            return null;
        });
    }

    toggleCheckbox = () => {
        const { isChecked } = this.state;
        this.setState({
            isChecked: !isChecked
        });
    }

    render() {
        const {
            loading,
            password,
            confirmPassword,
            passwordErrorMessage,
            confirmPasswordErrorMessage,
            responseErrorMessage,
            isChecked,
            isShowSubCheckbox,
            subCheckboxText,
            subscriptionRequestCompleted,
        } = this.state;

        const isDisabled =!password || !confirmPassword  || loading || subscriptionRequestCompleted === false;

        return (
          <div className={styles.loggedOutBottomContainer}>
            <p>
            Collect points from your order by creating an account.
              <span>
                <Link to="/loyalty">Learn More</Link>
              </span>
            </p>
            <button
              onClick={this.loginWithFacebook}
              type="button"
              className={styles.facebookButton}
            >
              <img alt="fb-signup" src={fbIcon} />
              <p>Sign Up with Facebook</p>
            </button>


            <div className={styles.divider}>
              <div />
              <span>or</span>
              <div />
            </div>

            <p className={styles.sectionHeader}> Add Password </p>
            <Input
              customContainerStyles={styles.inputWrapper}
              handleInput={e => validations.handleFormInput(e, this, 'password')}
              label="New Password"
              type="password"
              placeholder=" "
              value={password}
              errorMessage={passwordErrorMessage}
            />
            <Input
              customContainerStyles={styles.inputWrapper}
              handleInput={e => validations.handleFormInput(e, this, 'confirmPassword')}
              label="Confirm Password"
              type="password"
              placeholder=" "
              value={confirmPassword}
              errorMessage={confirmPasswordErrorMessage}
            />
            { isShowSubCheckbox ? (
              <CheckBox
                onClick={() => this.toggleCheckbox()}
                isChecked={isChecked}
                label={<p>{ subCheckboxText }</p>}
              />
                ) : null }
            {responseErrorMessage && (
            <p className={styles.hasError}>
              {responseErrorMessage}
              {' '}
            </p>
) }
            <Button
              customContainerStyles={styles.buttonContainer}
              disabled={isDisabled}
              handleSubmit={
                  isDisabled
                      ? null
                      : () =>
                          this.registerCustomer()
              }
              isLoading={loading}
              label="Create account"
            />
          </div>
        );
    };
}

CustomerRegistration.propTypes = {
    order: PropTypes.shape().isRequired,
    setUserToken: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    setVerificationToken: PropTypes.func.isRequired,
    setVerificationAction: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
    return {
        order: state.order
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setUserToken: (value) => dispatch(userActions.setUserToken(value)),
    setVerificationToken: value => dispatch(elementsActions.setVerificationToken(value)),
    setVerificationAction: value => dispatch(elementsActions.setVerificationAction(value))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerRegistration));
