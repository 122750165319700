import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

function Offerings({ data: { bottom = null, top = null, bottomHeaderSection= null, bottomDescription = null }, openDeliveryWidget }) {
  const getTopSectionDesktop = () => {
    return (
      top && (
        <div className="w-full m-auto relative mb-8 hidden lg:block h-[456px]">
          <div className="w-full bg-center bg-cover duration-500 animate-slide h-[384px]">
            <img
              /* eslint-disable-next-line */
              src={top?.bg_desktop?.url}
              /* eslint-disable-next-line */
              alt={top?.bg_desktop?.title}
              className="h-[384px] w-full object-cover shadow-md"
              /* eslint-disable-next-line */
              {...top?.bg_desktop?.$?.url}
            />
          </div>
          {/* Left Arrow */}
          <div className="absolute lg:top-[10%] -translate-x-0 traslate-y-[-50%] 2xl:left-[20%] left-[10%] text-2xl rounded-full p-2 cursor-pointer">
            <div className="flex flex-col">
              <h1
                className="font-congenialBlack text-[44px] leading-[46px] lg:text-md text-light tracking-[-1px]"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...top.$?.title}
              >
                {top.title}
              </h1>
              <p
                className="text-lg 2xl:text-lg lg:text-md lg:text-sm tracking-[-0.1px] font-filsonProRegular text-light lg:w-96 my-2"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...top.$?.description}
              >
                {parse(top.description)}
              </p>
              {/* eslint-disable-next-line */}
              <button
                onClick={() => openDeliveryWidget(top?.action?.href)}
                type="button"
                className="bg-button font-filsonProMedium px-8 text-light my-4 h-[44px] lg:h-[54px] rounded-[54px] "
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...top?.action?.$?.title}
              >
                {top?.action?.title}
              </button>
            </div>
          </div>

          {/* Right Arrow  */}
          <div className="absolute top-[-24%] -translate-x-0 traslate-y-[-50%] 2xl:right-[12%] lg:right-[5%] right-[-10%] text-2xl rounded-full p-2 cursor-pointer">
            <img
              /* eslint-disable-next-line */
              src={top?.image_desktop?.url}
              /* eslint-disable-next-line */
              alt={top?.image_desktop?.title}
              className="w-full"
              /* eslint-disable-next-line */
              {...top?.image_desktop?.$?.url}
            />
          </div>
        </div>
      )
    );
  };

  const getTopSectionMobile = () => {
    return (
      top && (
        <div className="w-full m-auto relative mb-8 block lg:hidden h-[440px]">
          <div className="w-full">
            <img
              /* eslint-disable-next-line */
              src={top?.bg_mobile?.url}
              alt="offering-bg"
              className="w-full h-[420px] object-cover shadow-md"
              /* eslint-disable-next-line */
              {...top?.bg_mobile?.$?.url}
            />
          </div>
          {/* Left Arrow  */}
          <div className="absolute top-[-25%] -translate-x-0 traslate-y-[-50%] text-2xl rounded-full p-2 cursor-pointer h-[333px]">
            <img
              /* eslint-disable-next-line */
              src={top?.image_mobile?.url}
              /* eslint-disable-next-line */
              alt={top?.image_mobile?.title}
              className="w-full"
              /* eslint-disable-next-line */
              {...top?.image_mobile?.$?.url}
            />
          </div>

          {/* Right Arrow */}
          <div className="w-full mx-auto">
            <div className="absolute top-[50%] -translate-x-0 traslate-y-[-50%] text-2xl rounded-full p-2 cursor-pointer text-center h-[186px]">
              <div className="flex flex-col items-center">
                <h1
                  className="font-congenialBlack text-[36px] leading-[36px]  text-light tracking-[-1px]"
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...top.$?.title}
                >
                  {top.title}
                </h1>

                <p
                  className=" text-base leading-5 tracking-[-0.1px] font-filsonProRegular text-light my-2"
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...top.$?.description}
                >
                  {parse(top.description)}
                </p>

                <button
                  onClick={() => openDeliveryWidget(top?.action?.href)}
                  type="button"
                  className="bg-button text-lg font-filsonProMedium px-8 text-light my-4 h-[44px] rounded-[54px] "
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...top?.action?.$?.title}
                >
                  {top?.action?.title}
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const getBottomSection = item => {
    return (
      <div
        className="col-span-1 grid grid-cols-3 lg:flex flex-col gap-3 items-center justify-center"
        // eslint-disable-next-line no-underscore-dangle
        key={item._metadata.uid}
      >
        <div
          id='bottomDiv'
          onClick={() => openDeliveryWidget(item.action?.href)}
          onKeyDown={() => openDeliveryWidget(item.action?.href)}
          role="button"
          tabIndex={0}
          className="col-span-3 grid grid-cols-3 lg:flex flex-col gap-3 items-center justify-center"
        >

        
          <img
            src={item?.image?.url}
            alt={item.heading}
            className="col-span-1 lg:w-[380px] lg:h-[324px] lg:object-contain"
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...item.image.$?.url}
          />
          <h3
            className="col-span-2 my-4 font-black text-4xl leading-7 tracking-[-0.2px] text-dark dark:text-white"
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...item.$?.text}
          >
            {item.text}
          </h3>

        </div>

        <div
          className="flex items-center justify-center relative h-[54px]"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...item.action.$?.href}
        >
          {item.action?.href && item.action?.title && (
            <button
              onClick={() => openDeliveryWidget(item.action?.href)}
              type="button"
              className="bg-button text-light hidden lg:block w-[132px] h-[54px] rounded-[54px] text-center font-filsonProMedium"
            >
              {item.action?.title}
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="w-full dark:bg-gradient-to-b dark:from-darkBlack dark:to-dark bg-white border-0">
        {/* Top Section */}
        {getTopSectionDesktop()}

        {getTopSectionMobile()}

        {/* Bottom Section */}
        {bottom && (
          <div className="w-full px-4 lg:p-0 m-0">
            <div className="max-w-screen-desktop py-8 m-auto">
              <h1 className="font-black font-congenialBlack text-4xl leading-7 tracking-[-0.2px] text-dark dark:text-white">
                {bottomHeaderSection}
              </h1>
              <p className="font-black font-congenialBlack text-xl leading-7 tracking-[-0.2px] text-darkElevationPrimary dark:text-darkElevationSecondary mt-2 mb-8">
                {bottomDescription}
              </p>
              <div className="lg:grid grid-cols-3 place-content-center place-items-center 2xl:gap-36 gap-8">
                {bottom?.map(item => {
                  return getBottomSection(item);
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

Offerings.propTypes = {
  data:
    PropTypes.shape({
      top: PropTypes.shape({}),
      bottom: PropTypes.shape({}),
      bottomHeaderSection: null,
      bottomDescription: null
    }),
  openDeliveryWidget: PropTypes.func
};

Offerings.defaultProps = {
  data: null,
  openDeliveryWidget: null
};

export default Offerings;
