/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import CheckoutContainer from '../CheckOutContainer/CheckoutContainer';
import DropDown from '../Dropdown/Dropdown';
import styles from './BirthdayWidget.module.css';
import customer from '../../shared/constants/customer';

const moment = extendMoment(Moment);
class BirthdayWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: {
        label: "",
        value: ""
      },
      selectedMonth: {
        label: "Jan",
        value: "01"
      },
      selectedDay: {
        label: "1",
        value: "01"
      }
    };
  }

  componentDidMount = () => {
    const { birthday } = this.props;
    const monthOptions = this.getMonths();
    const yearsOptions  = this.getYears();
    const year = birthday.year && birthday.year !== '0000' ? birthday.year : '2020';
    const monthYear = birthday.month ? `${year}-${birthday.month}` : `${year}-01`;
    const daysOptions = this.getDays(monthYear);

    this.setState({
      daysOptions,
      monthOptions,
      yearsOptions
    });
  };

  getMonths = () => {
    const months = moment.monthsShort();
    let count = 0;
    let formattedNumber;

    const dropdownMonths = Array.from(months).map(month => {
      count += 1;
      formattedNumber = `0${count}`.slice(-2);
      return {
        label: month,
        value: formattedNumber
      };
    });
    return dropdownMonths;
  };

  getDays = monthYear => {
    const { setBirthday, birthday } = this.props;
    const lastDay = moment(monthYear).daysInMonth();
    const daysInMonth = Array.from(Array(lastDay), (_, i) => i + 1).map(day => {
      return {
        label: day.toString(),
        value: day
      };
    });
    let { day } = birthday;
    const { month, year } = birthday;

    const dayLabel = day ? parseFloat(day).toString() : null;
    const monthLabel = month ? moment(month, "MM").format("MMM") : null;

    if (day && day > lastDay) {
      day = lastDay;
    }
    this.setState({
      selectedYear: {
        label: year,
        value: year
      },
      selectedMonth: {
        label: monthLabel,
        value: month
      },
      selectedDay: {
        label: dayLabel,
        value: day
      }
    });
    setBirthday({
      month,
      day,
      year
    });
    return daysInMonth;
  };

  getYears = () => {
    const back = 100;
    const fullYear = new Date().getFullYear();
    return Array.from({ length: back }, (v, i) => fullYear - (i + customer.restrictedAge)).map(
      year => {
        return {
          label: year,
          value: year
        };
      }
    );
  };

  updateBirthMonth = (month, label) => {
    const { setBirthday } = this.props;
    const { selectedDay, selectedYear } = this.state;

    let day = selectedDay.value;
    const year = selectedYear.value;

    const monthYear = `${year}-${month}`;
    const lastDay = moment(monthYear).daysInMonth();
    const daysOptions = this.getDays(monthYear);

    if (day && day > lastDay) {
      day = lastDay;
      this.setState({
        selectedDay: {
          label: day.toString(),
          value: day
        }
      });
    }
    this.setState({
      selectedMonth: {
        label,
        value: month
      },
      daysOptions
    });
    setBirthday({
      year,
      month,
      day
    });
  };

  updateBirthYear = (year) => {
    const { setBirthday } = this.props;
    const { selectedDay, selectedMonth } = this.state;

    const day = selectedDay.value;
    const month = selectedMonth.value;

    this.setState({
      selectedYear : {
        label: year,
        value: year,
      }
    });
    setBirthday({
      year,
      month,
      day
    });
  };

  updateSelectedDay = day => {
    const { setBirthday } = this.props;
    const { selectedMonth, selectedYear } = this.state;
    this.setState({
      selectedDay: {
        label: day.toString(),
        value: day
      }
    });
    setBirthday({
      year: selectedYear.value,
      month: selectedMonth.value,
      day
    });
  };

  birthdayContainer = () => {
    const { header, subHeader, sectionHeader, expanded, disabled } = this.props;
    const {
      selectedMonth,
      selectedDay,
      daysOptions,
      monthOptions,
      yearsOptions,
      selectedYear,
    } = this.state;

    if (disabled) {
      return (
        <div className={`${styles.dropDownRow} flex gap-2`}>
          <DropDown
            options={monthOptions}
            value={selectedMonth}
            customDropDownStyles={styles.dropDownPlaceholderMonth}
            disabled
          />
          <DropDown
            options={daysOptions}
            value={selectedDay}
            customDropDownStyles={styles.dropDownPlaceholderDay}
            disabled
          />
          <DropDown
            options={yearsOptions}
            value={selectedYear}
            customDropDownStyles={styles.dropDownPlaceholderYear}
            disabled
          />          
        </div>
      );
    }
    return (
      <CheckoutContainer
        header={header}
        subHeader={subHeader}
        expanded={expanded}
        btnAction={false}
      >
        <p className={styles.sectionHeader}>{sectionHeader}</p>
        <div className={`${styles.dropDownRow} flex gap-2`}>
          <DropDown
            onChange={e => this.updateBirthMonth(e.value, e.label)}
            options={monthOptions}
            value={selectedMonth.value ? selectedMonth : null}
            customDropDownStyles={styles.dropDownPlaceholderMonth}
          />
          <DropDown
            onChange={e => this.updateSelectedDay(e.value)}
            options={daysOptions}
            value={selectedDay.value ? selectedDay : null}
            customDropDownStyles={styles.dropDownPlaceholderDay}
          />
          <DropDown
            onChange={e => this.updateBirthYear(e.value)}
            options={yearsOptions}
            value={selectedYear.value ? selectedYear : null}
            customDropDownStyles={styles.dropDownPlaceholderYear}
          />
        </div>
      </CheckoutContainer>
    );
  };

  render() {
    return <this.birthdayContainer />;
  }
}
BirthdayWidget.propTypes = {
    birthday: PropTypes.shape({
        month: PropTypes.string.isRequired,
        day: PropTypes.string.isRequired,
    }).isRequired
};
export default withRouter(connect()(BirthdayWidget));
