/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';

// REDUX
import { connect } from 'react-redux';

// PACKAGES
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { isMobile, isTablet } from "react-device-detect";
import _ from 'underscore';

// GRAPHQL
import { useQuery } from '@apollo/client';
import { STORE_MENU_QUERY_V2 } from '../../graphql/product';
import * as currencyActions from '../../redux/actions/currency';
import * as addressActions from '../../redux/actions/address';
import * as elementsActions from '../../redux/actions/elements';
import * as userActions from '../../redux/actions/user';
import { defaultCurrency } from '../../shared/constants/currency';
import { orderMethods, orderTypeIds } from '../../shared/constants/order';
import { COOKIE_DEALS, COOKIE_MAGIC_BRANDED } from '../../shared/constants/product';
import { isSampleStore, shippingMethods, storeIds } from '../../shared/constants/store';
import * as orderRepo from '../../shared/repos/graphql/order';

// COMPONENTS
import CallOut from '../../components/CallOut/CallOut';
import Loader from '../../components/Loader/Loader';
import ProductCard from '../../components/ProductCard/ProductCard';
import ProductModal from '../../components/ProductModal/ProductModal';
import ProductsHeader from '../../components/ProductsHeader/ProductsHeader';
import SideCart from '../../components/SideCart/SideCart';

// STYLES
import './Braze.css';
import styles from './Menu.module.css';

// CONSTANTS
import endpoints from '../../shared/constants/endpoints';
import cmsKeys from '../../shared/constants/cms';

// UTILS
import elementConstants from "../../shared/constants/element";
import { marketingEvents } from "../../shared/constants/marketing";
import messages from "../../shared/constants/messages";
import * as brazeRepo from "../../shared/repos/marketing/braze";
import { routeCountryPath } from '../../shared/utilities/common';
import guardOrderChange from '../../shared/utilities/orders';
import { addCountryPathToLocation } from "../../shared/utilities/pathes";
import { handlePickupStoreSelectionWithDirectLink } from "../../shared/utilities/store";
import DeliveryPickUpWidget from '../../components/DeliveryPickUpWidget/DeliveryPickUpWidget';
import * as cms from '../../shared/repos/cms/cms';
import Input from '../../components/Input/Input';
/*
  NOTE: PLEASE DELETE ALL THESE REFERENCES AND IMGS FOLDER
  WHEN GRAPHQL IS CONNECTED
*/

const shippingBanner = require('./imgs/shipping-banner.png');
const loyaltyBanner = require('./imgs/loyalty-banner.png');


export class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      showModal: false,
      showSideCart: false,
      inputSearchProduct: '',
      showSuggestions: false,
      categoryObjects: [],
      categorizedMenu: {},
      searchProductCount:0,
    };

      this.menuItemSectionElement = React.createRef();
      this.inputSearchProductRef = React.createRef();
  }

  componentDidMount() {
    const { match, setUserOrderMethod, location } = this.props;
    const storeId = match.params.store_id;
    setTimeout(() => this.handleRouting(), 0);
    if(!Number.isNaN(storeId) && !Number.isNaN(parseInt(storeId, 10))) {
      handlePickupStoreSelectionWithDirectLink(this, storeId);
    }
    this.handleDirectLinkScroll();
    const handleScroll = debounce(this.handleMenuScroll, 100);
    window.addEventListener('scroll', handleScroll);
    cms.getContent([cmsKeys.placeholders.enterShippingAddress], this);

    if (
      location?.pathname?.includes("/shipping")
    ) {
      setUserOrderMethod(orderMethods.shipping);
    }

  }

  componentDidUpdate(prevProps) {
    const { selectedStore } = this.props;
    if (prevProps.selectedStore !== selectedStore) {
      this.handleRouting();
    }
  }

  /**
   * Handles product menu sub routing based on saved data available
   */
  handleRouting = () => {
    const { selectedStore, history, location, selectedCountry, isCountrySelectActive } = this.props;
    const storeMenu = (location.pathname.includes(endpoints.getMenuUrl(selectedStore)) || location.pathname === '/');

    // Emit Braze Event for Store Changed
    if (selectedStore) {
      brazeRepo.logEvent(marketingEvents.STORE_CHANGED, { Store_Id: parseInt(selectedStore.id, 10) });
    }

    if (selectedStore && storeMenu && selectedStore.id === storeIds.doughPro) {
      return history.push(routeCountryPath(addCountryPathToLocation(endpoints.doughProPage, selectedCountry.path_url, isCountrySelectActive)));
    }
    // if user has selected a store
    if (selectedStore && storeMenu) {
      return history.push(routeCountryPath(addCountryPathToLocation(`${endpoints.getMenuUrl(selectedStore)}${location.search}`, selectedCountry.path_url, isCountrySelectActive)));
    }
    // otherwise return to base home menu
    if (storeMenu) {
      brazeRepo.logEvent(marketingEvents.STORE_CHANGED, { Store_Id: storeIds.sampleMenu });
      return history.push(routeCountryPath(addCountryPathToLocation(location.pathname + location.search, selectedCountry.path_url, isCountrySelectActive)));
    }
    return null;
  }

  renderBannerLeftChild = (
    header,
    subHeader,
    label1,
    action1,
    label2,
    action2
  ) => {
    return (
      <div>
        <h1>{header}</h1>
        <p>{subHeader}</p>
        <div>
          {label1 ? (
            <button onClick={action1} type="button">
              {label1}
            </button>
          ) : null}
          {label2 ? (
            <button onClick={action2} type="button">
              {label2}
            </button>
          ) : null}
        </div>
      </div>
    );
  };

  renderBoxCallOutRightChild = (src,imgClass) => {
    return (
      <div className={imgClass}>
        <img alt="box-banner-asset" src={src} />
      </div>
    );
  };

  filterProducts = (products) => {
    if (products.length > 0) {
      const {cakeTopperMobileHide, cakeTopperProductID} = this.props;
      let productLists = products;
      const filterProduct = products.filter(product => ((cakeTopperMobileHide === 'true') && (product.id === cakeTopperProductID) && (isMobile && !isTablet)));
      if (filterProduct.length > 0) {
        productLists = products.filter(product => product.id !== filterProduct[0].id);
      }
      return productLists;
    }
    return products
  };

  handleProductSearchChange = (keyword, searchProductCount=0) => {
    const {inputSearchProduct} = this.state
    if (inputSearchProduct !== keyword) {
      this.setState({ inputSearchProduct: keyword, showSuggestions:true, searchProductCount  });
    }
  };

  handleProductSearchSelect = (keyword) => {
    const {inputSearchProduct} = this.state
    if (inputSearchProduct !== keyword) {
      this.setState({ inputSearchProduct: keyword, showSuggestions:false });
    }
  };


  getUniqueProducts = (allProducts) => {
    return Array.from(
      new Map(allProducts.map(product => [product.id, product])).values()
    );
  };

  searchProduct = (productList, limit) => {
    const {inputSearchProduct} = this.state;
    // If there's no search input, return an empty array
    if (!inputSearchProduct) {
      return [];
    }

    // Convert the search input to lowercase for case-insensitive comparison
    const inputSearchProductLower = inputSearchProduct.toLowerCase();

    // Filter the productListFiltered to include only those products that match the search term
    let filteredProducts = productList.filter(product => {
      // Check if there's a meta tag containing the keyword for each product
      const metaTagMatch = product.metaTags.some(tag => tag.title.toLowerCase().includes(inputSearchProductLower));

      // Check if the product title contains the keyword
      const titleMatch = product.title.toLowerCase().includes(inputSearchProductLower);

      // Return true if either the meta tag or the title matches the search term
      return metaTagMatch || titleMatch;
    });

    if (limit && limit > 0) {
      filteredProducts = filteredProducts.slice(0, limit);
    }

    return filteredProducts;
  };

  getUniqueProductKeywords = (productList) => {
    const keywords = new Set();

    productList.forEach(product => {
      // Add the product title to the set
      if (product.title) {
        keywords.add(product.title.toLowerCase());
      }

      // Add the meta tags to the set
      if (product.metaTags) {
        product.metaTags.forEach(tag => {
          if (tag.title) {
            keywords.add(tag.title.toLowerCase());
          }
        });
      }
    });

    // Convert the set back to an array
    return Array.from(keywords);
  };


  /**
   * Renders product section UI
   * @param {String} type
   * @param {String} header
   * @param {Object} productsContainer
   * @param {String} anchor - necessary for scrollTo functionality on filter component
   * NOTE: DO NOT ADD DUPLICATES & ONLY SET ON FIRST TYPE OF
   */
  renderProductSection = (type, header, productsContainer, anchor, categoryDescription = null, parentCategory = null) => {
    if (!productsContainer) {
      return undefined;
    }

    const hasChildren = !_.isEmpty(productsContainer.children);
    const children = [];

    if (hasChildren) {
      Object.keys(productsContainer.children).forEach(child => {
        children.push(
          this.renderProductSection(
            type,
            child,
            productsContainer.children[child],
            null,
            productsContainer.children[child].description,
            header
          )
        );
      });
    }

    const { products } = productsContainer;

    if (hasChildren) {
      return (
        <div>
          <div id={anchor || `products_${productsContainer.slug}`}>
            <div className="w-full flex items-center  text-dark dark:text-white mt-16 mb-12 md:mb-0">
              <hr className="w-full border-[1px] border-border h-[1px] md:block hidden" />
              <h1
                className={`
                w-full
                lg:w-[60%]
                border-2 border-border
                pt-[6px]
                pb-[12px]
                px-2
                rounded-2xl
                font-congenialBlack text-center
                text-[28px] leading-7 md:text-[44px] md:leading-9 tracking-[-0.2px] md:tracking-[-1px]
                capitalize font-bold

              `}
              >
                {header}
              </h1>
              <hr className="w-full border-[1px] border-border h-[1px] md:block hidden" />
            </div>
            <p className="md:text-center h-full text-darkElevationPrimary dark:text-darkElevationSecondary text-lg leading-4 mt-3">
              {categoryDescription}
            </p>
          </div>
          <div id={anchor} className={`${styles.productsSection}`}>
            {_.flatten(children).map(child => {
              return child;
            })}
          </div>
        </div>
      );
    }

    if (products) {
      const {userAddress, selectedStore, isCaloriesActive, currency} = this.props;
      const isShipping = !selectedStore? false : selectedStore.id === storeIds.shipping;
      const { inputSearchProduct, searchProductCount } = this.state;
      // Filter products to hide custom cake topper in mobile screens
      const productList = this.filterProducts(products);

      parentCategory = parentCategory ? parentCategory.toLowerCase() : null; // eslint-disable-line

      if(parentCategory === 'ice cream' || parentCategory === 'packs'){
        header = null; // eslint-disable-line
      }

      if(header !== COOKIE_MAGIC_BRANDED) {
        // eslint-disable-next-line no-param-reassign
        header = header ? header.toLowerCase() : null; // eslint-disable-line
      }
      return (
        <div
          id={anchor || `products_${productsContainer.slug}`}
          className={`
            ${styles.productsSection}
            flex flex-col md:gap-16 text-dark dark:text-white`}
        >
          <div
            className={`${!header ? "mb-12 md:mb-0" : "py-10 md:py-0 md:mt-6 text-center"}`}
          >
            {header !== "for you" || (inputSearchProduct && searchProductCount>0) ? (
              <>
                {anchor ? (
                  <div className="w-full flex items-center mx-auto">
                    <hr className="w-full border-[1px] border-border h-[1px] md:block hidden" />
                    <h1
                      className={`
                    w-full
                    lg:w-[60%]
                    border-2 border-border
                    pt-[6px]
                    pb-[12px]
                    px-2
                    text-center
                    rounded-2xl
                    font-congenialBlack
                    text-[28px] leading-7 md:text-[44px] md:leading-9 font-bold tracking-[-0.2px] md:tracking-[-1px]
                    capitalize
                  `}
                    >
                      {header}
                    </h1>
                    <hr className="w-full border-[1px] border-border h-[1px] md:block hidden" />
                  </div>
                ) : (
                  <h1
                    className={`
                    font-congenialBlack md:text-center
                    text-[28px] leading-7 md:text-4xl font-bold md:leading-9 tracking-[-0.2px] md:tracking-[-1px]
                    capitalize
                  `}
                  >
                    {header}
                  </h1>
                )}
                <p className="md:text-center text-darkElevationPrimary dark:text-darkElevationSecondary text-lg leading-4 mt-3">
                  {categoryDescription}
                </p>
                {" "}
              </>
            ) : null}
          </div>

          <div
            data-ddog-id="menu_product_cards"
            className={`
            ${styles.productsContainer} grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16`}
          >
            {productList.map((product, index) => {
              const { title } = product;
              const price = userAddress ? product.price : null;
              const upsellFeatured =
                anchor === COOKIE_DEALS ? product.upsellFeatured : false;
              const priceFormatted =
                price && (isShipping || !isSampleStore(selectedStore?.id))
                  ? price.toFixed(2)
                  : null;
              const strikePrice =
                price && product.strike_price
                  ? product.strike_price.toFixed(2)
                  : null;
              const charMax = 200;
              const description =
                product.description.length > charMax
                  ? `${product.description.substring(0, charMax)}...`
                  : product.description;

              const isEven = (index + 1) % 2 === 0;
              const isBox = product.hasAssortments;
              const isCustom = product.options && product.options.length > 0;
              const calories =
                isCaloriesActive && product.calories != null
                  ? ` ${product.calories} kCal`
                  : "";
              return (
                <div
                  data-ddog-id="menu_item_product_card"
                  key={`dkey-${product.id}`}
                  className={`${
                    styles[`${type}Product`]
                  } w-[95%] h-[250px] md:w-[270px] md:h-[254px] mx-auto`}
                >
                  <ProductCard
                    key={`key-${product.id}`}
                    title={title}
                    id={product.id}
                    isBox={isBox}
                    isCustom={isCustom}
                    description={description}
                    price={priceFormatted}
                    strikePrice={strikePrice}
                    type={type}
                    alternate={isEven}
                    primaryImage={product.productImageSmall}
                    secondaryImage={product.accentImage}
                    categories={product.categories}
                    upsellFeatured={upsellFeatured}
                    calories={calories}
                    currency={currency}
                  />
                </div>
              );
            })}
          </div>
          <div className="md:mb-16" />
        </div>
      );
    }
    return undefined;
  };

  getCategorizedMenu = (menu) => {
    const mappedMenu = menu.map(item => {
      const productCategory = item.category;
      return { ...item, productCategory };
    });
    const categorizedMenu = {};
    function populateCategory(category, productsContainer) {
      const {products, children} = category;
      const childGroupedList = _.groupBy(children, item => item.category);
      const childObj = {};
      _.each(childGroupedList, item => {
        item.forEach(product => {
          childObj[product.category] = {
            slug: product.slug,
            description: product.description,
            products: product.products
          }
        })
      });

      // eslint-disable-next-line no-param-reassign
      productsContainer[category.category] = {
        products,
        slug: category.slug,
        children: childObj
      };
    }
    mappedMenu.forEach((category) => {
      populateCategory(category, categorizedMenu);
    });
    return categorizedMenu;
  }

  handleStoreMenu = async (menu,categoriesList) => {
    const { setSelectedStore, setUserCartId, userCartId, setUserOrderMethod, location, selectedStore, setMenuCategories, setProducts, modalObject, isShippingActive } = this.props;

    setProducts(menu);

    const categorizedMenu = this.getCategorizedMenu(menu);
    const sortedCategories=[];

    const categoryObjects = [];
    menu.forEach(item => {
      return categoryObjects.push({
        key: item.category,
        cardSize: 'medium',
        navTitle: item.category,
        menuTitle: item.category,
        categoryDescription: item.description,
        anchor: item.slug,
        productLength: item.products.length
      });
    });

    const obj = {};
    for( let i = 0;i < categoryObjects.length; i +=1){
      obj[categoryObjects[i].anchor]=categoryObjects[i]
    }

    for( let i = 0;i < categoriesList.length; i +=1){
      if (obj[categoriesList[i].slug] && obj[categoriesList[i].slug].productLength > 0) {
        sortedCategories.push(obj[categoriesList[i].slug])
      }
    }

    this.setState({
      categorizedMenu, categoryObjects:sortedCategories,
    });
    setMenuCategories(sortedCategories);
    if (location.pathname.includes('/shipping') && isShippingActive) {
      const orderData = {
        orderTypeId: orderTypeIds.shipping,
        storeId: storeIds.shipping,
        shippingMethod: shippingMethods.shipping,
      };

      if (
        selectedStore &&
          parseInt(selectedStore.id, 10) !== parseInt(storeIds.shipping, 10) &&
          !modalObject
      ) {
        guardOrderChange(true, () => {
          this.shippingStoreChangeModalOkayAction();
        });
      } else if (!userCartId) {
        setSelectedStore({id:storeIds.shipping});
        setUserOrderMethod(orderMethods.shipping);
        const response = await orderRepo.createCart(orderData);
        const { createCart } = response.data;
        setUserCartId(createCart.code);
      }
    }

    return null;
  }

  shippingStoreChangeModalOkayAction = () => {
    const {
      setSelectedStore,
      setUserOrderMethod,
    } = this.props;

    setSelectedStore({id: storeIds.shipping});
    setUserOrderMethod(orderMethods.shipping);
  }

    evaluateQuery = () => {
      const { location, selectedStore, setCurrency } = this.props;

      let storeId = selectedStore ? selectedStore.id : storeIds.sampleMenu;

      if (location.pathname.includes('/shipping')) {
        storeId = storeIds.shipping;
      }

      return useQuery(STORE_MENU_QUERY_V2, {
        fetchPolicy: 'no-cache',
        variables: { id: storeId },
        onCompleted: async (data) => {
          this.handleStoreMenu(data.store.menuV2, data.store.categoriesList);
          setCurrency(data.store.currency);
        }
      });
    }

  getBanners = () => {
    const { history, location, selectedStore, isShippingActive } = this.props;
    const shippingCall = true;
    let shippingBannerData;

    const isCookieLabStore = parseInt(selectedStore?.id, 10) === storeIds.cookieLab;

    if (location.pathname.includes(endpoints.shippingPage)) {
      shippingBannerData = {
        shippingBannerHeader    : messages.shippingMenu.shippingBannerHeader,
        shippingBannerSubHeader : messages.shippingMenu.shippingBannerSubHeader,
        shippingBannerLabel1    : messages.shippingMenu.shippingBannerLabel1,
        shippingBannerAction    : () => { window.location.href = endpoints.salesSite }
      }
    } else {
      shippingBannerData = {
        shippingBannerHeader    : messages.mainMenu.shippingBannerHeader,
        shippingBannerSubHeader : messages.mainMenu.shippingBannerSubHeader,
        shippingBannerLabel1    : messages.mainMenu.shippingBannerLabel1,
        shippingBannerAction    : () => { history.push(routeCountryPath(endpoints.shippingPage)) }
      }
    }

    return [
      (
        <div className={`${styles.bannerWrapper} mt-[180px]`}>
          <CallOut
            leftChild={this.renderBannerLeftChild(
              'Make it a box',
              'Mix and match with your faves for a combo deal.',
              'Customize my box',
              () => history.push(routeCountryPath('/products/box/7')),
              'Pick for me',
              () => history.push(routeCountryPath('/products/box/7/assortment'))
            )}
            rightChild={this.renderBoxCallOutRightChild()}
            isCookieLab={isCookieLabStore}
          />
        </div>
      ), (
        <div className={`${styles.bannerWrapper} mt-[180px]`}>
          <CallOut
            leftChild={this.renderBannerLeftChild(
              'Start earning dough',
              'Join our cookie dough loyalty program to earn points towards free cookies today.',
              'Join now',
              () => history.push(routeCountryPath('/create-account')),
              'Learn more',
              () => history.push(routeCountryPath('/loyalty')),
            )}
            rightChild={this.renderBoxCallOutRightChild(loyaltyBanner,styles.boxBannerAssetWrapper)}
            isCookieLab={isCookieLabStore}
          />
        </div>
      ),(
        (isShippingActive) && (
          <div className={`${styles.bannerWrapper} mt-[100px]`}>
            <CallOut
              leftChild={this.renderBannerLeftChild(
                shippingBannerData.shippingBannerHeader,
                shippingBannerData.shippingBannerSubHeader,
                shippingBannerData.shippingBannerLabel1,
                shippingBannerData.shippingBannerAction,
                null,
                null
              )}
              rightChild={this.renderBoxCallOutRightChild(shippingBanner,styles.boxShippingBannerAssetWrapper)}
              isShipping={shippingCall}
              isCookieLab={isCookieLabStore}
            />
          </div>
        )
      )
    ]
  }

    observerIntersection = (entries) => {
        const {setProductCategory} = this.props;
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const id = entry.target.getAttribute('id');
              setProductCategory(id);

              const categorySelector = document.getElementById(`menuCategorySelector-${id}`)

              if (categorySelector) {
                categorySelector.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
              }
            }
        });
   }

  handleMenuScroll = () => {

    const options = {
        threshold: 0.1
    }

    const observer = new IntersectionObserver(this.observerIntersection, options);

    const { categoryObjects } = this.state
    categoryObjects.forEach((item) => {
      const {anchor} = item;

      const section = document.getElementById(anchor);
      if(section)
        observer.observe(section);
    })


  }

  handleDirectLinkScroll = () => {
    const { location, setProductCategory, redirectUrl, setRedirectUrl} = this.props;
    setTimeout(() => {
      const { hash } = location;
      if (hash === '' && redirectUrl === '') {
        return;
      }

      let anchor = hash ? hash.split('#')[1] : null;

      if(redirectUrl?.length > 1) {
        anchor = redirectUrl ? redirectUrl.split('#')[1] : null;
        setRedirectUrl('');
      }

      const categorySelector = document.getElementById(`${anchor}`);

      if (!categorySelector) {
        return;
      }
      setProductCategory(anchor);
      const yPos = categorySelector.offsetTop - elementConstants.manualDeltaScroll;
      window.scrollTo({ top: yPos, behavior: 'instant' });
    }, elementConstants.directLinkTimeout);
  }

  showDeliveryPickupWidgetModal = () => {
    const { content } = this.state;
    return (
      <div className={`${styles.menuContainer} w-full mb-16`}>
        <div className='lg:max-w-screen-desktop  mx-auto pt-9'>
          <DeliveryPickUpWidget
            isVisible
            addressType="shipping"
            cmsContent={content}
            disableCloseButton
          />
        </div>
      </div>
    );
  }

  menuContainer = () => {
    const { showModal, showSideCart, categorizedMenu, categoryObjects, inputSearchProduct, showSuggestions } = this.state;
    const {
      location,
      contentCardData,
      contentCardHandleClick,
      setHeroContentCardData,
      userAddress,
      isShippingActive,
    } = this.props;
    const storeMenu =
      location.pathname.includes('/menu') ||
      location.pathname.includes('/goto/store') ||
      location.pathname === '/' ||
      new RegExp(`^[/][a-z]{2}[/]?$`).test(location.pathname);
    const isShippingMenu = location.pathname.includes(endpoints.shippingPage);
    const showShippingAddressWidgetCondition = isShippingActive
      && isShippingMenu
      && !storeMenu
      && !userAddress
      && !userAddress?.address1;

    if (showShippingAddressWidgetCondition) {
      return this.showDeliveryPickupWidgetModal();
    }
    const { loading } = this.evaluateQuery();

    let firstBoxProduct = null;

    if(!loading) {
      setTimeout(() => {
        if(contentCardData) {
          contentCardHandleClick(contentCardData);
          setHeroContentCardData({contentCardHandleClick: null, contentCardData: null})
        }
      }, 100)
    }
    const allProducts = [];  // Initialize an empty array to hold all products

    _.each(categorizedMenu, menuItems => {
      allProducts.push(...menuItems.products);
      menuItems.products.forEach(item => {
        if (item.featured) {
          firstBoxProduct = item.id;

          return false;
        }
        return true;
      });
    });

    let allProductsCategory = {};
    let searchProducts = [];
    const searchLimit = isMobile ? 5: null;
    const allUniqueProducts = this.filterProducts(this.getUniqueProducts(allProducts));
    let keywords = [];
    if(inputSearchProduct){
      searchProducts = this.searchProduct(allUniqueProducts, searchLimit);
      keywords = this.getUniqueProductKeywords(searchProducts);
      allProductsCategory = {
        key: 'search',
        cardSize: 'medium',
        navTitle: 'search',
        menuTitle: '',
        categoryDescription: '',
        anchor: '',
        productLength: searchProducts.length,
        products: searchProducts,
        slug: 'search'
      };
    }

    // const banners = this.getBanners();

    return (
      <div className={`${styles.menuContainer} w-full mb-16`}>
        <div className='lg:max-w-screen-desktop  mx-auto pt-9'>
          {showModal && storeMenu ? (
            <ProductModal exit={this.toggleModal} />
          ) : null}
          {showSideCart ? (
            <SideCart toggleSideCart={this.toggleSideCart} />
          ) : null}
          {storeMenu || isShippingMenu ? (
            <ProductsHeader firstBoxProduct={firstBoxProduct} />
          ) : null}

          {!loading ? (
            <div>
              <div className={[!showSuggestions && keywords.length > 0 ? 'mb-12 mb-12 md:mb-0' : '', "flex flex-col justify-center items-center lg:px-0 px-4"].join(" ")}>
                <div className={`${styles.searchDivWidth} relative mx-auto`}>
                  <Input
                    handleInput={(e) => this.handleProductSearchChange(e?.target?.value, searchProducts.length)}
                    placeholder="Search Menu"
                    value={inputSearchProduct}
                    handleSubmit={() => this.handleProductSearchChange()}
                    ref={this.inputSearchProductRef}
                    customInputContainerStyles={`${styles.menuSearch}`}
                  />
                  {inputSearchProduct ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className={`${styles.closeIcon} absolute cursor-pointer text-dark dark:text-light`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => this.handleProductSearchChange()}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  ) : null}
                  {inputSearchProduct && keywords.length === 0 && (
                  <div className={styles.errorContainer}>
                    <p>No product match</p>
                  </div>
                  )}
                </div>
                {showSuggestions && keywords.length > 0 && (
                  <div className={`${styles.searchDivWidth} mx-auto font-filsonProRegular text-dark dark:text-white
                  border-[1px]
                  border-borderLight dark:border-none bg-white dark:bg-darkDry
                  overflow-y-scroll max-h-[212px] mb-12 mb-12 md:mb-0`}
                  >
                    {keywords.map((suggestion) => (
                      <div
                        key={suggestion}
                        onClick={() => this.handleProductSearchSelect(suggestion)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            this.handleProductSearchSelect(suggestion);
                          }
                        }}
                        role="button"
                        aria-label={suggestion}
                        tabIndex={0}
                        className="cursor-pointer p-2 hover:bg-light dark:hover:bg-darkDry"
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={`${styles.menuDetailsContainer} lg:px-0 px-4`}>
                {inputSearchProduct &&  searchProducts.length > 0 ? this.renderProductSection(
                    allProductsCategory.cardSize,
                    allProductsCategory.menuTitle,
                    allProductsCategory,
                    allProductsCategory.anchor,
                    allProductsCategory.categoryDescription,
                  ): []}
                { categoryObjects ? categoryObjects.map((co) => {
                  return [this.renderProductSection(
                    co.cardSize,
                    co.menuTitle,
                    categorizedMenu[co.key],
                    co.anchor,
                    co.categoryDescription,
                  ), null]
                }
                ) : null}
              </div>
            </div>
          ) : (
            <div className={styles.loaderWrapper}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return <this.menuContainer />;
  }
}

Menu.propTypes = {
  cakeTopperMobileHide: PropTypes.string,
  cakeTopperProductID: PropTypes.string,
  isShippingActive: PropTypes.bool,
  isCaloriesActive: PropTypes.bool,
  currency: PropTypes.objectOf(PropTypes.string),
};

Menu.defaultProps = {
  cakeTopperMobileHide: window.environment.REACT_APP_CUSTOM_CAKE_TOPPER_HIDE_MOBILE,
  cakeTopperProductID: window.environment.REACT_APP_CUSTOM_CAKE_TOPPER_PRODUCT_ID,
  isShippingActive: true,
  isCaloriesActive: false,
  currency: defaultCurrency,
};

export const mapStateToProps = state => {
  const {
    userCartId,
    selectedStore,
    userOrderMethod,
    userAddress,
    userCart,
    userToken
  } = state.user;
  const { modalObject, contentCardHandleClick, contentCardData } = state.elements;
  const { isShippingActive } = state.shipping;
  const { isCaloriesActive } = state.product;
  const { currency } = state.currency;
  const { selectedCountry, isCountrySelectActive } = state.country;

  return {
    userCartId,
    selectedStore,
    userOrderMethod,
    userAddress,
    modalObject,
    userCart,
    isShippingActive,
    isCaloriesActive,
    currency,
    selectedCountry,
    isCountrySelectActive,
    userToken,
    contentCardHandleClick,
    contentCardData,
    redirectUrl: state.address.redirectUrl
  };
};

export const mapDispatchToProps = dispatch => ({
  setMenuCategories: value => dispatch(elementsActions.setMenuCategories(value)),
  setProducts: value => dispatch(elementsActions.setProducts(value)),
  setProductCategory: (value) => dispatch(elementsActions.setProductCategory(value)),
  setUserOrderMethod: (value) => dispatch(userActions.setUserOrderMethod(value)),
  setUserCartId: (value) => dispatch(userActions.setUserCartId(value)),
  setSelectedStore: (value) => dispatch(userActions.setSelectedStore(value)),
  setModalObject: (value) => dispatch(elementsActions.setModalObject(value)),
  setCurrency: (value) => dispatch(currencyActions.setCurrency(value)),
  setHeroContentCardData: () => dispatch(elementsActions.setHeroContentCardData()),
  setRedirectUrl: (value) => dispatch(addressActions.setRedirectUrl(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
