import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Collections from "../../components/Homepage/Collections/Collections";
import Delivery from "../../components/Homepage/Delivery/Delivery";
import Offerings from "../../components/Homepage/Offerings/Offerings";
import Promotions from "../../components/Homepage/Promotions/Promotions";
import ActiveOrders from "../../components/ActiveOrders/ActiveOrders";

import { getPageRes } from "../../shared/utilities/contentstack-sdk";
import { onEntryChange } from "../../shared/utilities/contentstack";
import ProductsHeader from "../../components/ProductsHeader/ProductsHeader";
import endpoints from "../../shared/constants/endpoints";
import * as addressActions from '../../redux/actions/address';

import styles from './Homepage.module.css';

function Homepage(props) {
  const { userAddress, userToken, history, setRedirectUrl, selectedCountry } = props;
  const [page, setPage] = useState(null);
  const [showDeliveryWidget, setShowDeliveryWidget] = useState(false);

  const contentParams = {
    entryUrl: "/",
    contentTypeUid: "page",
    referenceFieldPath: ["page_components.from_blog.featured_blogs"],
    jsonRtePath: [
      "page_components.from_blog.featured_blogs.body",
      "page_components.section_with_buckets.buckets.description",
      "page_components.section_with_html_code.description"
    ],
    locale: selectedCountry?.locale?.code
  };

  const fetchData = async () => {
    const {
      contentTypeUid,
      entryUrl,
      referenceFieldPath,
      jsonRtePath,
      locale
    } = contentParams;

    const pageData = await getPageRes({
      entryUrl,
      contentTypeUid,
      referenceFieldPath,
      jsonRtePath,
      locale
    });

    setPage(pageData.value);
  };

  useEffect( () => {
    onEntryChange(() => fetchData());
  }, []);

  const openDeliveryWidget = (hash = null) => {
    setRedirectUrl(hash);
    if (!userAddress) {
      setShowDeliveryWidget(true);
    } else {
      const url = hash ? `${endpoints.menu}${hash}` : endpoints.menu
      history.push(url)
    }
  }

  return (
    (
      <div
        data-pageref={page?.uid}
        data-contenttype={contentParams.contentTypeUid}
        data-locale={page?.locale}
        className={`
      w-full flex flex-col items-center pb-36 mx-auto dark:bg-gradient-bl dark:bg-gradient-to-br dark:from-darkBlack dark:to-darkBlack
      `}
      >
        { userToken && (
          <div className={styles.activeOrdersWrapper}>
            <ActiveOrders />
          </div>
        )}

        <div className="dark:bg-dark w-full">
          <div className="lg:max-w-screen-desktop w-full  p-0 border-0 mx-auto">
            <Delivery showWidget={showDeliveryWidget} setShowDeliveryWidget={setShowDeliveryWidget} />
            <div className="w-full mt-14 md:mt-9">
              <ProductsHeader floatMenuHide="true" openDeliveryWidget={openDeliveryWidget} />
            </div>
          </div>
        </div>

        {/* eslint-disable-next-line */}
        {page?.page_components?.map((component, key) => {
          return (
            <div className="w-full flex flex-col items-center" key={`component-${key}`}>
              <div
                className="w-full dark:bg-dark p-0 m-0 border-0"
              >
                {component?.collections && (
                  <Collections data={component?.collections} openDeliveryWidget={openDeliveryWidget} />
                )}

                {component?.offerings && (
                  <Offerings
                    openDeliveryWidget={openDeliveryWidget}
                    data={{
                      /* eslint-disable-next-line */
                      bottom: component?.offerings?.bottom_section,
                      /* eslint-disable-next-line */
                      bottomHeaderSection: component?.offerings?.bottom_header_section,
                      /* eslint-disable-next-line */
                      top: component?.offerings?.top_section,
                      /* eslint-disable-next-line */
                      bottomDescription: component?.offerings?.bottom_description,
                    }}
                  />
                )}
              </div>
              {component?.promotions?.promotion && (
                <Promotions data={component?.promotions?.promotion} />
              )}
            </div>
          );
        })}
      </div>
    )
  );
}


Homepage.propTypes = {
  userAddress: PropTypes.shape({}),
  userToken: PropTypes.string || null,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  setRedirectUrl: PropTypes.string,
  selectedCountry: PropTypes.shape({})
};

Homepage.defaultProps = {
  userAddress: null,
  userToken: null,
  setRedirectUrl: '',
  selectedCountry: null
};

export const mapStateToProps = (state) => {
  const { userAddress, userToken } = state.user;
  const {selectedCountry} = state.country;
  return { userAddress, userToken, selectedCountry }
}

export const mapDispatchToProps = (dispatch) => ({
  setRedirectUrl: value => dispatch(addressActions.setRedirectUrl(value))
})

export default  connect(mapStateToProps, mapDispatchToProps)(Homepage);
