export default {
  COOKIE_MAGIC_ACTIVE: 'cookie_magic_active',
  COOKIE_MAGIC_CUSTOMER_TELEPHONE_VERIFICATION_ACTIVE: 'cookie_magic_customer_telelphone_verification_active',
  SHOW_CALORIES: 'show_calories',
  SHIPPING_ACTIVE: 'shipping_active',
  COUNTRY_SELECTION_ENABLED: 'country_selection_enabled',
  BRAZE_APP_ID_WEB: 'braze_app_id_web',
  DEFAULT_HERO_BANNER: 'default_hero_banner',
  CONTENT_CARD_REFRESH_DELAY: 'content_card_refresh_delay',
  CAROUSEL_SLIDE_SPEED: 'carousel_slide_speed',
  INTRO_HERO_BANNER: 'intro_hero_banner',
  IS_FOOTER_MENU_NEWSROOM_ENABLED: 'is_footer_menu_newsroom_enabled',
  IS_FOOTER_MENU_FAQ_ENABLED: 'is_footer_menu_faq_enabled',
  IS_FOOTER_MENU_PRIVACY_POLICY_ENABLED: 'is_footer_menu_privacy_policy_enabled',
  IS_FOOTER_MENU_TERMS_ENABLED: 'is_footer_menu_terms_enabled',
  IS_FOOTER_MENU_EVENT_TERMS_ENABLED: 'is_footer_menu_event_terms_enabled',
  IS_FOOTER_MENU_ACCESSIBILITY_ENABLED: 'is_footer_menu_accessibility_enabled',
  IS_FOOTER_MENU_CONTACT_ENABLED: 'is_footer_menu_contact_enabled',
  IS_FOOTER_MENU_ABOUT_US_ENABLED: 'is_footer_menu_about_us_enabled',
  IS_FOOTER_MENU_CAREERS_ENABLED: 'is_footer_menu_careers_enabled',
  IS_FOOTER_MENU_CORPORATE_SALES_ENABLED: 'is_footer_menu_corporate_sales_enabled',
  IS_FOOTER_MENU_DONATIONS_ENABLED: 'is_footer_menu_donations_enabled',
  IS_FOOTER_MENU_MERCH_ENABLED: 'is_footer_menu_merch_enabled',
  IS_FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_ENABLED: "is_footer_menu_promotions_terms_and_conditions_enabled",
  IS_FOOTER_MENU_ALLERGEN_ENABLED: 'is_footer_menu_allergen_enabled',
  FOOTER_MENU_NEWSROOM_LINK: 'footer_menu_newsroom_link',
  FOOTER_MENU_FAQ_LINK: 'footer_menu_faq_link',
  FOOTER_MENU_PRIVACY_POLICY_LINK: 'footer_menu_privacy_policy_link',
  FOOTER_MENU_TERMS_LINK: 'footer_menu_terms_link',
  FOOTER_MENU_EVENT_TERMS_LINK: 'footer_menu_event_terms_link',
  FOOTER_MENU_ACCESSIBILITY_LINK: 'footer_menu_accessibility_link',
  FOOTER_MENU_CONTACT_LINK: 'footer_menu_contact_link',
  FOOTER_MENU_ABOUT_US_LINK: 'footer_menu_about_us_link',
  FOOTER_MENU_CAREERS_LINK: 'footer_menu_careers_link',
  FOOTER_MENU_CORPORATE_SALES_LINK: 'footer_menu_corporate_sales_link',
  FOOTER_MENU_DONATIONS_LINK: 'footer_menu_donations_link',
  FOOTER_MENU_MERCH_LINK: 'footer_menu_merch_link',
  FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_LINK: 'footer_menu_promotions_terms_and_conditions_link',
  FOOTER_MENU_ALLERGEN_LINK: 'footer_menu_allergen_link',
  TIKTOK_LINK: 'tiktok_link',
  INSTAGRAM_LINK: 'instagram_link',
  FACEBOOK_LINK: 'facebook_link',
  TIPS_TYPE: 'tips_type',
  TIPS_VALUES: 'tips_values',
  DISABLE_GIFTCARDS: 'disable_giftcards',
  DATADOG_RUM_ENABLED: 'datadog_rum_enabled',
  ORDER_TOTALS_TITLES: 'order_totals_titles',
  SHOW_TAXES_AFTER_TOTAL: 'show_taxes_after_total',
  MAP_DEFAULT_COORDINATES: 'map_default_coordinates',
  ONETRUST_SCRIPT_ID: 'onetrust_script_id',
  DISABLE_SAVE_CARD: 'disable_save_card',

  IS_CONTACT_US_FEEDBACK_ENABLE: 'contact_us_feedback_enable',
  IS_CONTACT_US_GENERAL_ENABLE: 'contact_us_general_enable',
  IS_CONTACT_US_EVENTS_ENABLE: 'contact_us_events_enable',
  IS_CONTACT_US_REAL_ESTATE_ENABLE: 'contact_us_real_estate_enable',
  IS_CONTACT_US_VENDOR_ENABLE: 'contact_us_vendor_enable',
  IS_CONTACT_US_PRESS_ENABLE: 'contact_us_press_enable',
  IS_CONTACT_US_MERCH_ENABLE: 'contact_us_merch_enable',

  CONTACT_US_FEEDBACK_EMAILS: 'contact_us_feedback_emails',
  CONTACT_US_GENERAL_EMAILS: 'contact_us_general_emails',
  CONTACT_US_EVENTS_EMAILS: 'contact_us_events_emails',
  CONTACT_US_REAL_ESTATE_EMAILS: 'contact_us_real_estate_emails',
  CONTACT_US_VENDOR_EMAILS: 'contact_us_vendor_emails',
  CONTACT_US_PRESS_EMAILS: 'contact_us_press_emails',
  USE_FULLY_QUALIFIED_ADDRESS_FOR_DELIVERY: 'use_fully_qualified_address_for_delivery',
  IS_THEME_SWITCH_ENABLED: 'theme_switch_enabled',
  PASSWORDLESS_ENABLE: 'passwordless_enabled',
  ACTIVE_LOYALTY: 'active_loyalty',
  REFERRAL_ENABLED: 'referral_enabled',
  USE_RECAPTCHA: 'use_recaptcha',
};
