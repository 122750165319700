import React from "react";
import PropTypes from "prop-types";

import Link from "../../components/LinkWithCountry/LinkWithCountry";
import styles from "./TopBanner.module.css";

function TopBanner({ contents }) {
  
  return (
    <>
      <div
        className={`${styles.bannerBackground} mx-auto flex items-center justify-around relative max-w-full`}
      >
        <div
          className=" w-[343px] md:w-[600px] h-[259px] md:h-[194px] pl-4 py-[72px] pr-0 flex 
          flex-col items-center md:items-start gap-3 absolute md:left-[10%] md:top-[10%] text-center md:text-left"
        >
          <h1
            className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-white"
          >
            {contents?.heading}
          </h1>
          <p
            className="font-filsonProRegular text-lg leading-5 md:leading-[22px] tracking-[-0.1px] text-white"
          >
            {contents?.description}
          </p>
          {contents?.link && (
            <Link to={contents?.link?.href}>
              <button
                type="button"
                className="
            bg-button text-lg leading-[22px] tracking-[-0.1px] 
            font-filsonProBold text-white h-[52px] w-[245px] md:w-[254px] rounded-[40px] px-5 py-[15px]"
              >
                {contents?.link?.title}
              </button>
            </Link>
          )}
        </div>
        <img
          // eslint-disable-next-line
          src={contents?.banner_image?.url}
          alt=""
          className="absolute right-[10%] top-[-128px] md:block hidden"
        />

        <img
          // eslint-disable-next-line
          src={contents?.banner_mobile_image?.url}
          alt=""
          className="absolute top-[-67px] md:hidden"
        />
      </div>
    </>
  );
}

TopBanner.propTypes = {
  contents: PropTypes.shape({}).isRequired
};

export default TopBanner;
