import React, { useLayoutEffect, useRef } from "react";
import PropTypes, { number } from "prop-types";
import { connect } from "react-redux";
// eslint-disable-next-line import/no-cycle
import Header from "./Header";
import endpoints from "../../shared/constants/endpoints";

function HeaderSection({
  pathname,
  hasError,
  topHeaderOffsetDivHeight,
  userAddress
}) {
  const headRef = useRef(null);

  useLayoutEffect(() => {
    const body = document.querySelector("body");

    const isCheckoutPage = pathname ? pathname.match(/checkout\/review-order/g) : false;
    const isLoginPage = pathname ? pathname.match(/login/g) : false;
    const isCreateAccountPage = pathname ? pathname.match(/create-account/g) : false;
    const isLocationPage = pathname === endpoints.locations;
    const isdoughProPage = pathname === endpoints.doughProPage;

    const resetPaddingOnPage = isCheckoutPage || isLocationPage || isdoughProPage || isLoginPage || isCreateAccountPage;

    if (resetPaddingOnPage) {
      body.className =
        topHeaderOffsetDivHeight > 99
          ? "pt-[115px] md:pt-[108px]"
          : "pt-[79px] md:pt-[68px]";
    } else if (userAddress) {
      body.className =
        topHeaderOffsetDivHeight > 99
          ? "pt-[176px] md:pt-[168px]"
          : "pt-[140px] md:pt-[128px]";
    } else if (!userAddress && pathname !== '/') {
      body.className =
        topHeaderOffsetDivHeight > 99
          ? "pt-[224px] md:pt-[168px]"
          : "pt-[188px] md:pt-[128px]";
    } else {
      body.className =
        topHeaderOffsetDivHeight > 99
          ? "pt-[224px] md:pt-[108px]"
          : "pt-[188px] md:pt-[68px]";
    }
  }, [topHeaderOffsetDivHeight, userAddress, pathname]);

  return (
    <div className="relative w-full">
      <div className="top-0 w-full zIndex fixed">
        <Header
          pathname={pathname}
          theme={null}
          hasError={hasError}
          headRef={headRef}
        />
      </div>
    </div>
  );
}

HeaderSection.propTypes = {
  pathname: PropTypes.string,
  hasError: PropTypes.bool,
  topHeaderOffsetDivHeight: number,
  userAddress: PropTypes.shape({ address1: PropTypes.string })
};

HeaderSection.defaultProps = {
  pathname: null,
  hasError: false,
  topHeaderOffsetDivHeight: 0,
  userAddress: null
};

export const mapStateToProps = state => {
  const {
    closeTimer
  } = state.elements;
  const { userAddress } = state.user;

  return { userAddress, closeTimer };
};

export default connect(mapStateToProps)(HeaderSection);
