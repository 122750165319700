/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as elementActions from '../../redux/actions/elements';
import * as userActions from '../../redux/actions/user';
import * as productActions from '../../redux/actions/product'
import * as orderRepo from '../../shared/repos/graphql/order';

import MediumProduct from './MediumProduct/MediumProduct';
import SmallProduct from './SmallProduct/SmallProduct';

import DeliveryPickUpWidget from '../DeliveryPickUpWidget/DeliveryPickUpWidget';
import Loader from '../Loader/Loader';

import styles from './ProductCard.module.css';

// CONSTANTS
import { defaultCurrency } from '../../shared/constants/currency';
import endpoints from '../../shared/constants/endpoints';
import { orderMethods } from '../../shared/constants/order';

// UTILITIES
import { guardOrderChange } from '../../shared/utilities/orders';
import * as tagmanagerEvents from '../../shared/utilities/tagmanagerEvents';
import * as productRepo from '../../shared/repos/graphql/product';

import { FEATURED_PRODUCT_TYPE_CART, FEATURED_PRODUCT_TYPE_DETAIL } from '../../shared/constants/product';
import { isMobile, routeCountryPath } from '../../shared/utilities/common';

const oneIcon = require('./imgs/plus.svg');
const manyIcon = require('./imgs/many.svg');

class ProductCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            updateCartLoading: false,
            isUpsellFeaturedRunning: false,
            activeSection: null
        };
        this.productCardElement = React.createRef();
    }

    componentDidMount = () => {
      window.addEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
      if(isMobile()) {
        const {id} = this.props;
        const rect = this.productCardElement.current.getBoundingClientRect();
        if (rect.top >= 100 && rect.left >= 0 && rect.bottom <= window.innerHeight - 100 && rect.right <= window.innerWidth) {
          this.setState({activeSection: id});
        } else {
          this.setState({activeSection: null});
        }
      }
    }

    componentWillUnmount = () => {
      window.removeEventListener('scroll', this.handleScroll)
      this.setState({activeSection: null});
    }
    

    className = () => {

        const { isBox, isCustom } = this.props;

        if (isBox) {
            return 'productCard--box';
        }

        if (isCustom) {
            return 'productCard--customizable';
        }

        return 'productCard--simple';
    }

    onClick = () => {
        const { isBox, isCustom, history, id, upsellFeatured, userCartId, isUpsale } = this.props;
        const { isUpsellFeaturedRunning } = this.state;
        if (upsellFeatured && !isUpsellFeaturedRunning) {
          this.setState({
              isUpsellFeaturedRunning: true
          });
          this.addUpsellFeatured(userCartId,id,FEATURED_PRODUCT_TYPE_DETAIL);
        }

        let path;
        if (isBox) {
            path = `/products/box/${id}`;
        } else if (isCustom) {
            path = `/products/customize/${id}`;
        } else {
            path = `/products/single/${id}`;
        }
        if (isUpsale) {
            path += '?isUpsale=true';
        }

        return history.push(routeCountryPath(path));
    }

    onAdd = async e => {
      const {
        isBox,
        isCustom,
        id,
        userCartId,
        setUserCart,
        afterProductAdded,
        selectedStore,
        setModalObject,
        price,
        title,
        categories,
        upsellFeatured,
        setUpsellProductModalActive
      } = this.props;
      
      e.stopPropagation();
      
      if (upsellFeatured) {
        let type = FEATURED_PRODUCT_TYPE_CART;
        if (isBox || isCustom) {
          type = FEATURED_PRODUCT_TYPE_DETAIL;
        }
        this.addUpsellFeatured(userCartId,id,type);
      }
      if (isBox || isCustom) {
        const { data } = await productRepo.getProduct(id);
        const {product} = data;      
        setUpsellProductModalActive({openModal: true, product, item: data})
        return;
      }

      if (!selectedStore) {
        // eslint-disable-next-line
        return setModalObject({
            children: (
              <div className={styles.widgetWrapper}>
                <DeliveryPickUpWidget isModal productId={id} />
              </div>
            )
        });
      }

      tagmanagerEvents
        .addToCart({
          id,
          title,
          price,
          categories,
          quantity: 1
        });

      this.setState({
        updateCartLoading: true
      });

      const response = await orderRepo.addProductToOrder(userCartId, { product: id });

      const localStorageCart = response.data?.addProductsToOrder?.items.map((item) => {
        return item;
      });

      setUserCart(localStorageCart);

      if (afterProductAdded) {
          afterProductAdded();
      }

      this.setState({
        updateCartLoading: false
      });
      // eslint-disable-next-line    
      return null;      
    };

    setStateDispatchers = () => {  
      const { setModalObject} = this.props;   
      setModalObject(null);
    }

    addUpsellFeatured = async (userCartId, productId, type) => {
        await orderRepo.addProductToUpsellFeatured(userCartId, { productId: parseInt(productId,10), type });
        this.setState({
            isUpsellFeaturedRunning: false
        });
    }

    showDoughProCardOverlay = () => {
      const {
        categories,
        doughProSpecialCategory,
        doughProMember,
        isBetaCustomer,
        isDoughProActive
      } = this.props;
      if (!doughProSpecialCategory || !(isBetaCustomer || isDoughProActive)) {
        return false;
      }
      
      const { slug } = doughProSpecialCategory;
   
      if (!categories || categories.length === 0) {
        return false;
      }
    
      const slugs = categories.map((category) => category.slug ? category.slug.toLowerCase() : category.slug);
      
      if (slugs.indexOf(slug.toLowerCase()) === -1) {
        return false;
      }
    
      if (!doughProMember) {
        return true;
      }

      return !doughProMember.active;
    }

    render() {
        const {
          type,
          id,
          title,
          price,
          strikePrice,
          primaryImage,
          secondaryImage,
          isBox,
          isCustom,
          isLimited,
          description,
          alternate,
          history,
          userOrderMethod,
          calories,
          currency,
        } = this.props;

        const { updateCartLoading, activeSection } = this.state;

        const formattedPrice = price ? parseFloat(price).toFixed(2) : 0;

        const currentOrderNotDoughPro = userOrderMethod ? userOrderMethod !== orderMethods.doughPro : false;

        const productByType = {
            small: (
              <SmallProduct
                id={id}
                onClick={this.onClick}
                onAdd={e => this.onAdd(e)}
                title={title}
                price={formattedPrice}
                strikePrice={strikePrice}
                image={primaryImage}
                icon={isBox || isCustom ? manyIcon : oneIcon}
                className={this.className()}
                doughProOverlay={this.showDoughProCardOverlay()}
                calories={calories}
                currency={currency}
              />
            ),
            medium: (
              <MediumProduct
                id={id}
                onAdd={e => this.onAdd(e)}
                primaryImage={primaryImage}
                secondaryImage={secondaryImage}
                price={formattedPrice}
                strikePrice={strikePrice}
                title={title}
                onClick={this.onClick}
                isLimited={isLimited}
                description={description}
                alternate={alternate}
                icon={isBox || isCustom ? manyIcon : oneIcon}
                className={this.className()}
                doughProOverlay={this.showDoughProCardOverlay()}
                calories={calories}
                currency={currency}
                isSingle={!(isBox || isCustom)}
              />
            )
        };

        const overlayStyles = {
          small: styles.productCardOverlaySmall,
          medium: styles.productCardOverlay,
        };

        return (
          <div
            data-ddog-id="product_card"
            className={`          
          ${type === 'medium' ? 'h-[250px] md:h-[254px]' : ''}
          border-[1px] border-solid border-border
          text-dark dark:text-white
          dark:hover:border-glow
          dark:hover:shadow-glow
          hover:border-lightGlow
          hover:shadow-lightGlow
          rounded-[22px]
          ${styles.productCardWrapper} 
          ${this.showDoughProCardOverlay() ? styles.productCardLocked : ''}
          ${activeSection === id ? 'dark:shadow-glow dark:border-glow border-lightGlow shadow-lightGlow' : ''}
          `}
            ref={this.productCardElement}
          >
            { this.showDoughProCardOverlay() ? (
              <div
                className={`${overlayStyles[type]} ${alternate ? styles.productCardOverlayAlternate : ''}`}
                onClick={() => guardOrderChange(currentOrderNotDoughPro, history.push, routeCountryPath(endpoints.doughProPage))}
              />
            ) : null}

            {updateCartLoading ? (
              <div className={styles.loaderWrapper}>
                <Loader height={40} />
              </div>
            ) : null}

            {productByType[type]}
          </div>
        );
    }
};

ProductCard.propTypes = {
    type: PropTypes.string.isRequired,
    primaryImage: PropTypes.node.isRequired,
    price: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    isLimited: PropTypes.bool,
    description: PropTypes.string,
    alternate: PropTypes.bool,
    isBox: PropTypes.bool,
    isCustom: PropTypes.bool,
    isUpsale: PropTypes.bool,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    categories: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string.isRequired
    })).isRequired,
    afterProductAdded: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    userOrderMethod: PropTypes.string,
    isBetaCustomer: PropTypes.bool,
    isDoughProActive: PropTypes.bool,
    upsellFeatured: PropTypes.bool,
    calories: PropTypes.string,
    currency: PropTypes.objectOf(PropTypes.string)
};

ProductCard.defaultProps = {
    isLimited: false,
    isUpsale: false,
    description: null,
    alternate: false,
    isBox: false,
    isCustom: false,
    id: '',
    afterProductAdded: null,
    userOrderMethod: null,
    isBetaCustomer: false,
    isDoughProActive: false,
    upsellFeatured: false,
    calories: null,
    currency: defaultCurrency,
};

export const mapDispatchToProps = (dispatch) => ({
    setUserCart: (value) => dispatch(userActions.setUserCart(value)),
    setModalObject: (value) => dispatch(elementActions.setModalObject(value)),
    setUpsellProductModalActive: (value) => dispatch(productActions.setUpsellProductModalActive(value))
});

export const mapStateToProps = (state) => {
    const {
        selectedStore,
        userOrderMethod,
        userCart,
        userAddress,
        userCartId,
        doughProMember,
        isBetaCustomer
    } = state.user;
    const {
      doughProSpecialCategory
    } = state.elements;
    const {
      isDoughProActive
    } = state.doughPro;
    const {
        isCaloriesActive
    } = state.product
    const { currency } = state.currency;
    return {
        selectedStore,
        userOrderMethod,
        userCart,
        userAddress,
        userCartId,
        doughProMember,
        doughProSpecialCategory,
        isBetaCustomer,
        isDoughProActive,
        isCaloriesActive,
        currency
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductCard));
