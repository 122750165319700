import { STORE_HOURS_QUERY , ALL_STORES_QUERY, ALL_REGULAR_STORES_QUERY, STORE_QUERY, STORE_QUERY_BY_LOCATION_NAME, STORE_CLOSE_TIMER_QUERY } from '../../../graphql/store';
import { client } from "../../utilities/config";
import {STORES_QUERY} from "../../../graphql/location";
import {STORE_MENU_QUERY_V2} from "../../../graphql/product";
import {store} from "../../../redux/store";
import {setProducts} from "../../../redux/actions/elements";

/**
 * Retrieves the store menu for a given store
 * @param {Number} storeId
 * @returns {Promise<ApolloQueryResult<any>>}
 */
export async function getStoreMenu(storeId) {
  const promise = client.query(
    {
      query: STORE_MENU_QUERY_V2,
      variables: { id: storeId }
    }
  );

  promise.then((response) => {
    const { menuV2 } = response.data.store;

    store.dispatch(setProducts(menuV2));
  })

  return promise;
}

export const getStoreHours = (orderCode, date) => {
  return client.query({
    query: STORE_HOURS_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      orderCode,
      date
    }
  });
};

export const getStoreCloseTimer = (orderCode) => {
  return client.query({
    fetchPolicy: "no-cache",
    query: STORE_CLOSE_TIMER_QUERY,
    variables: { orderCode },
  });
};

/**
 * Gets a list of Stores
 *
 * @param {Object} data {
 *      lat: Float,
 *      lng: Float,
 *      externalId: String,
 * }
 *
 * @returns {Promise<ApolloQueryResult<any>>}
 */
export async function getStores(data) {

  return client.query(
    {
      query: STORES_QUERY,
      fetchPolicy: 'network-only',
      variables: data
    }
  );
}

export const allStores = () => {
  return client.query({
    query: ALL_STORES_QUERY,
  });
};

export const regularStores = () => {
  return client.query({
    query: ALL_REGULAR_STORES_QUERY,
  });
};

export const getStore = (id) => {
  return client.query(
    {
      query: STORE_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    }
  )
};

export const getStoreByLocationName = (LocationName) => {
  return client.query(
    {
      query: STORE_QUERY_BY_LOCATION_NAME,
      fetchPolicy: 'network-only',
      variables: {
        location_page_name: LocationName
      }
    }
  )
};
