import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import styles from "./UpsellProducts.module.css";

import * as rendering from '../../../shared/utilities/renderings';
import * as productRepo from "../../../shared/repos/graphql/product";

import * as productActions from "../../../redux/actions/product";
import * as userActions from "../../../redux/actions/user";
import UpsellProductItem from "./UpsellProductItem";
import Button from "../../Button/Button";
import CheckoutCartButton from "../../CheckoutCartButton/CheckoutCartButton";
import * as productConstants from '../../../shared/constants/product';
import * as tagmanagerEvents from '../../../shared/utilities/tagmanagerEvents';
import * as orderRepo from '../../../shared/repos/graphql/order';
import Loader from "../../Loader/Loader";
import { defaultCurrency } from "../../../shared/constants/currency";

const clearIcon = require("../imgs/clear-dark-icon.svg");

class UpsellProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      item: null,
      customizeProducts: null,
      quantity: null,
      upsellIndex: 0,
    };
  }

  async componentDidMount() {
    await this.setProductData();
  }

  handleCloseWidget = () => {
    const { setUpsellProductModalActive } = this.props;
    setUpsellProductModalActive({ openModal: false, product: null });
  };

  handleOptionChange = options => {
    const { product } = this.state;
    const boxProducts = {};
    const customizeProducts = {};

    const opts = product.options
      .filter(option => {
        return ["PRIMARY", "SELECT_LARGE", "SELECT_SMALL"].includes(
          option.optionGroupType
        );
      })
      .map(item =>
        item.options.map(e => ({
          ...e,
          optional: item.minOptions < 1
        }))
      )
      .reduce((a, b) => a.concat(b));

    Object.entries(options).forEach(([key, value]) => {
      value.forEach(optionId => {
        const selectedProduct = opts.find(item => item.id === optionId) || null;
        if (!selectedProduct) {
          return;
        }
        if (boxProducts[optionId]) {
          boxProducts[optionId].push(selectedProduct);
        } else {
          boxProducts[optionId] = [selectedProduct];
        }
        product.options.forEach(option => {
          if (+option.id === +key) {
            option.options.forEach(opt => {
              if (optionId === opt.id) {
                if (customizeProducts[option.id]) {
                  customizeProducts[option.id].push(optionId);
                } else {
                  customizeProducts[option.id] = [optionId];
                }
              }
            });
          }
        });
      });
    });
    this.setState({
      boxProducts, // eslint-disable-line
      customizeProducts
    });
  };

  setProductData = async () => {
    const { upsellEdit, userCart } = this.props;
    const { product, item } = upsellEdit;
    const { customizeProducts, quantity } = this.state;
    const selectedItem = userCart.find(p => p.id === item.id);

    let selectedOptions = customizeProducts;
    this.setState({
      updatePriceLoading: true, // eslint-disable-line
      updateCartLoading: true,
      item
    });

    const { data } = await productRepo.getProduct(product.id);
    this.setState({
      product: data.product,
      productPrice: data.product.price,
      updatePriceLoading: false, // eslint-disable-line
      updateCartLoading: false
    });

    if (!selectedOptions) {
      selectedOptions = rendering.getSelectedOptions(
        data.product,
        selectedItem
      );
    }
    if (!quantity) {
      this.setState({
        quantity: selectedItem ? selectedItem.quantity : 1
      });
    }
    const options = rendering.checkSelectedOptions(
      data.product,
      selectedOptions
    );
    this.setState({ customizeProducts: options });
  };

  handleNext = () => {
    const { upsellIndex } = this.state;
    this.setState({
      upsellIndex: upsellIndex + 1
    });
  };

  handlePrevious = () => {
    const { upsellIndex } = this.state;
    this.setState({
      upsellIndex: upsellIndex - 1
    });
  };

  handleProductUpdate = async () => {

    const { userCartId, setUserCart, designId, cakeTopperProductId } = this.props;
    const { item, product, quantity, customizeProducts, productPrice } = this.state;
    const customizeProductsArray = [];
    Object.values(customizeProducts).forEach(array =>
      array.forEach(el => customizeProductsArray.push(el))
    );

    tagmanagerEvents
      .addToCart({ ...product, quantity, price: (product.price * quantity).toFixed(2) });

    // Assign cake topper image ID to specific it's product
    let cakeTopperImage = null;
    if (product.id === cakeTopperProductId) {
      cakeTopperImage = designId;
    }
    const productArrayWithCategoryOptionId = [];
    Object.entries(customizeProducts).forEach(([key, value]) => {
      productArrayWithCategoryOptionId.push({
        productIds: value,
        groupOptionId: key
      });
    });
    let updateRes;
    let order;
    if (item?.id) {
      updateRes = await orderRepo.addUpdateOrderProduct(userCartId, {
        orderProductId: parseInt(item.id, 10),
        product: product.id, quantity,
        productOptions: productArrayWithCategoryOptionId,
        price: productPrice,
        cakeTopperImage
      });

      order = updateRes.data.updateProductOrder;
    } else {
      updateRes = await orderRepo.addProductToOrder(userCartId, {
        product: product.id, quantity,
        productOptions: productArrayWithCategoryOptionId,
        price: productPrice,
        cakeTopperImage
      });

      order = updateRes.data.addProductsToOrder;
    }
    
    const localStorageCart = order.items.map(item1 => item1);
    setUserCart(localStorageCart);
    
    this.handleCloseWidget();
  };

  handleAddItem = () => {
    this.setState({
      updateCartLoading: true
    });

    return this.handleProductUpdate();
  };

  showProductOptions = (product) => {
    const { isCaloriesActive, currency } = this.props;
    const { customizeProducts, upsellIndex, quantity, totalCost, updateCartLoading, item } = this.state;
    // const options = product.options
    const { options } = product;
    const checkoutEnabled = this.isCheckoutEnabled();
    const isDisabled = !checkoutEnabled || updateCartLoading
    const checkoutButtonText = item?.id ? productConstants.UPDATE_PRODUCT_TEXT : productConstants.ADD_TO_ORDER_TEXT;
    return (
      <div
        className="fixed inset-0 bg-opacity-25
            backdrop-blur-sm flex justify-center items-center
            zIndex shadow-[0_0_15px_6px_rgba(0,0,0,0.35)]
            bg-darkBlack"
      >
        <div
          id="modalUpsellProducts"
          className={`
              md:shadow-modalShadow md:dark:shadow-modalDarkShadow
              bg-white w-[900px] flex flex-col p-[20px] md:rounded-[40px]
               h-full md:h-auto md:min-h-[600px] outline-none
            `}
        >
          <div className="w-full flex justify-around gap-3">
            <div className="w-full flex justify-between mt-2 text-dark font-filsonProBold text-xl leading-3 tracking-tight">
              <p>{product?.title}</p>
              <p>{`${upsellIndex + 1} / ${options.length}`}</p>
            </div>
            <div
              role="button"       
              tabIndex={0} 
              className={`w-[24px] flex mb-8 justify-end cursor-pointer `}
              id="closeWidget"
              onClick={() => {
                this.handleCloseWidget(); // eslint-disable-line
              }}
              onKeyDown={() => {
                this.handleCloseWidget(); // eslint-disable-line
              }}
            >
              <img
                src={clearIcon}
                alt=""
                className={`w-6 h-6 object-contain ${updateCartLoading ? 'hidden' : ''}`}                
              />
            </div>
          </div>
          <div className="h-[400px] overflow-y-scroll">
            {product ? (
              <UpsellProductItem
                options={options}
                updateProduct
                onOptionChange={this.handleOptionChange}
                customizeProducts={customizeProducts}
                upsellIndex={upsellIndex}
                // setElement={this.setElement}
                isCaloriesActive={isCaloriesActive}
                currency={currency}
              />
            ) : null}
          </div>

          <div className="mt-16 flex flex-col md:flex-row w-full gap-4">
            <div className={`${upsellIndex === 0 ? "hidden" : ""} w-full`}>
              <Button disabled={isDisabled} handleSubmit={this.handlePrevious} label="Previous" />
            </div>
            <div
              className={`${
                upsellIndex === options.length - 1 ? "hidden" : ""
              } w-full`}
            >
              <Button 
                disabled={isDisabled} 
                handleSubmit={this.handleNext} 
                label="Next" 
                customContainerStyles={`
                  ${isDisabled ? `cursor-default opacity-50` : 'cursor-pointer'}
                  `} 
              />
            </div>

            <div
              className={`${
                upsellIndex === options.length - 1 ? "" : "hidden"
              } w-full`}
            >
              {updateCartLoading ? (
                <div className={styles.loaderWrapper}>
                  <Loader />
                </div>
              ) : null}
              <CheckoutCartButton
                onClick={() => {
                  this.handleAddItem();
                }}
                isDisabled={isDisabled}
                label={checkoutButtonText}
                price={totalCost}
                onQuantityChange={value => this.setState({ quantity: value })}
                quantity={quantity}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  isCheckoutEnabled() {
    const { product, customizeProducts, upsellIndex } = this.state;
    let checkoutEnabled = true;

    if (product && Array.isArray(product.options)) {
      product.options.forEach((el, index) => {
        if (index === upsellIndex) {
          const isRequired = el.minOptions > 0;
          const selectionMade = customizeProducts ? customizeProducts[el.id] : null;
          if (selectionMade === null && isRequired) {
            checkoutEnabled = false;
            return null;
          }

          const requiredNotSelected = isRequired && customizeProducts[el.id] === undefined;
          const minSelectionsNotMet = (
            el.minOptions &&
            (customizeProducts[el.id] && customizeProducts[el.id].length !== el.minOptions) &&
            el.OptionGroupType !== productConstants.OPTION_GROUP_TYPE.SIZE_SELECT
          );
          const selectionsExceedMax = selectionMade && el.maxOptions && customizeProducts[el.id].length > el.maxOptions;
          const sizeSelectInvalid = (
            el.optionGroupType === productConstants.OPTION_GROUP_TYPE.SIZE_SELECT && selectionMade && customizeProducts[el.id].length !== 1
          );

          if (
            requiredNotSelected ||
            minSelectionsNotMet ||
            selectionsExceedMax ||
            sizeSelectInvalid
          ) {
            checkoutEnabled = false;
          }

          return null;
        }
        return null;
      });
    }

    return checkoutEnabled;
  }

  render() {    
    const { product } = this.state;    
    return product ? this.showProductOptions(product) : null;
  }
}


UpsellProducts.propTypes = {
  upsellEdit: PropTypes.shape({product: PropTypes.shape(), item: PropTypes.shape()}),
  setUpsellProductModalActive: PropTypes.func,
  cakeTopperProductId: PropTypes.string,
  designId: PropTypes.string,
  userCart: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])})),
  setUserCart: PropTypes.func,
  userCartId: PropTypes.string,
  isCaloriesActive: PropTypes.bool,
  currency: PropTypes.objectOf(PropTypes.string),
  item: PropTypes.shape(),
};

UpsellProducts.defaultProps = {
  upsellEdit: null,
  setUpsellProductModalActive() {},
  setUserCart() {},
  cakeTopperProductId: window.environment.REACT_APP_CUSTOM_CAKE_TOPPER_PRODUCT_ID,
  designId: null,
  userCart: [],
  userCartId: null,
  isCaloriesActive: false,
  currency: defaultCurrency,
  item: null
};

export const mapStateToProps = state => {
  const { upsellEdit, isCaloriesActive } = state.product;
  const {
    userCart,
    userOrderMethod,
    selectedStore,
    userCartId,
    userInfo,
  } = state.user;
  const { currency } = state.currency;
  const { designId } = state.customCakeTopper;
  return {
    userCart,
    designId,
    userOrderMethod,
    selectedStore,
    userCartId,
    userInfo,
    upsellEdit,
    isCaloriesActive,
    currency
  };
};

export const mapDispatchToProps = dispatch => ({
  setUpsellProductModalActive: value =>
    dispatch(productActions.setUpsellProductModalActive(value)),
  setUserCart: value => dispatch(userActions.setUserCart(value)),
  setUserCartId: value => dispatch(userActions.setUserCartId(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpsellProducts);
