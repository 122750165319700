import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import Link from "../../LinkWithCountry/LinkWithCountry";

function Promotions({ data }) {

  const getActionButton = (url, title, tag) => {
    if (/(http(s?)):\/\//i.test(url)) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <button
            type="button"
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...tag}
            className="text-dark dark:text-white h-[54px] px-8 bg-transparent font-filsonProMedium border-solid border border-dark dark:border-darkBorder rounded-[54px]"
          >
            {title}
          </button>
        </a>
)
    } 
      return (
        <Link to={url}>
          <button
            type="button"
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...tag}
            className="text-dark dark:text-white h-[54px] px-8 bg-transparent font-filsonProMedium border-solid border border-dark dark:border-darkBorder rounded-[54px]"
          >
            {title}
          </button>
        </Link>
)
    
    
  }
  
  const getRowsData = () => {
    return data?.map((item, index) => {
      const even = index % 2;
      return (
        <div
          className="md:grid grid-cols-2 md:gap-8 lg:gap-0 lg:max-w-screen-desktop lg:mx-auto"
          // eslint-disable-next-line no-underscore-dangle
          key={item._metadata.uid}
        >
          <div className={`${even ? "md:hidden block" : "block"}`}>
            <img
              src={item?.image?.url}
              alt={item?.image?.title}
              className="md:w-full"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...item?.image?.$?.url}
            />
          </div>
          <div className="self-center md:mt-0 mt-8">
            <div className="flex flex-col gap-4 m-auto lg:w-3/5 lg:items-start items-center">
              <h1
                className="text-dark dark:text-white font-black font-congenialBlack text-4xl leading-9 tracking-tighter"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...item.$?.title}
              >
                {item.title}
              </h1>

              <p
                className="text-dark dark:text-white font-filsonProRegular font-normal leading-5 tracking-tighter"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...item.$?.description}
              >
                {parse(item.description)}
              </p>

              <div className=" flex flex-row lg:flex-col gap-4 ">
                {getActionButton(item?.action.href, item?.action.title, item?.action.$?.title)}
                {item?.action2.href && (
                 getActionButton(item?.action2.href, item?.action2.title, item?.action2.$?.href)
                )}
              </div>
            </div>
          </div>

          <div className={`${even ? "md:block hidden" : "hidden"}`}>
            <img
              src={
                item?.image?.url
                  ? item?.image?.url
                  : '"./assets/images/promotion1.png"'
              }
              alt={item?.image?.url}
              className="md:w-full"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...item?.image?.$?.url}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="md:grid grid-flow-row flex flex-col gap-8 w-full lg:px-0 px-4 mt-9">
      {getRowsData()}
    </div>
  );
}

Promotions.propTypes = {
  data: PropTypes.shape({}).isRequired
};

export default Promotions;
