import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TopHeader from "./TopHeader";
import Instructions from "./Instructions";
import TopBanner from "./TopBanner";
import DeliciousRewards from "./DeliciousRewards";
import Faqs from "./Faqs";
import { getPageComponents } from "../../shared/utilities/contentstack-sdk";
import Loader from "../../components/Loader/Loader";

// eslint-disable-next-line
class InsomniaRewards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contents: {
        page_components: null
      },
      uid: "rewards_page"
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { selectedCountry } = this.props;
    const { uid } = this.state;

    const locale = selectedCountry?.locale?.code;
    const pageData = await getPageComponents(uid, locale);
    this.setState({
      contents: pageData?.value
    });
  };

  render() {
    const { contents, uid } = this.state;

    const contentLoaded =
      contents?.page_components && contents?.page_components.length > 0; {/* eslint-disable-line */}

    if (contentLoaded) {
      return (
        <div
          data-pageref={contents?.uid}
          data-contenttype={uid}
          data-locale={contents?.locale}
          className=" w-full flex flex-col items-center pb-36 mx-auto"
        >
          <div className="w-full">
            <div className="lg:max-w-screen-desktop w-full  p-0 border-0 mx-auto">
              <div className="w-full mt-14 md:mt-9">
                {/* eslint-disable-next-line */}
                <TopHeader contents={contents?.page_components[0].top_banner} />
              </div>
              <div className="w-full mt-14">
                <Instructions
                  // eslint-disable-next-line
                  contents={contents?.page_components[1].step_to_earn}
                />
              </div>
            </div>
            <div className="w-full mt-20">
              {/* eslint-disable-next-line */}
              <TopBanner contents={contents?.page_components[2].banner} />
            </div>
            <div className="lg:max-w-screen-desktop w-full p-0 border-0 mx-auto mt-10">
              <div className="w-full mt-14">
                <DeliciousRewards
                  // eslint-disable-next-line
                  contents={contents?.page_components[3].how_to_redeem}
                />
              </div>

              <div className="w-full mt-14">
                {/* eslint-disable-next-line */}
                <Faqs contents={contents?.page_components[4].faqs} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <Loader />;
  }
}

InsomniaRewards.propTypes = {
  selectedCountry: PropTypes.shape({})
};

InsomniaRewards.defaultProps = {
  selectedCountry: null
};

export const mapStateToProps = state => {
  const { selectedCountry } = state?.country;
  return {
    selectedCountry
  };
};

export default withRouter(connect(mapStateToProps)(InsomniaRewards));
