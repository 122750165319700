const initialState = {
    isCaloriesActive: false,
    upsellEdit: {openModal: false, product: null, item: null}
  };
  
  export default (state = initialState, { type, value }) => {
    switch (type) {
      case "CALORIES_ACTIVE":
        return {
          ...state,
          isCaloriesActive: value
        };
      case "UPSELL_PRODUCT_MODAL_ACTIVE":
        return {
          ...state,
          upsellEdit: value
        };
      default:
        return state;
    }
  };
  