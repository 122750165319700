/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Switch.module.css';


const Toggle = ({ on, onClick }) => (
  <div
    onClick={onClick}
    className={[
      styles.switchContainer,
      on ? styles.switchContainerOn : null,
      'dark:border-darkElevationPrimary border-border border-[1px]'
      ].join(' ')}
  >
    <div className={[
      styles.switchToggle,
      on ? styles.switchOn : null
    ].join(' ')} 
    />
  </div>
) 

Toggle.propTypes = {
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default Toggle;
