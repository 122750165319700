/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import PropTypes from "prop-types";

// REDUX
import { connect } from "react-redux";
import * as elementsActions from "../../redux/actions/elements";

import styles from "./Modal.module.css";

const exitIcon = require("./imgs/exit-icon.svg");

const Modal = props => {

  const {
    exit,
    title,
    description,
    action1,
    action1Label,
    action2,
    action2Label,
    action3Label,
    action3,
    setModalObject,
    children,
    maxWidth
  } = props;

  useEffect(() => {
    const container=document.querySelector("#modal")

    if (!container) {
      return () => {}
    }

    // optional: needed only if the container element is not focusable already
    container.setAttribute("tabindex","0")
    container.focus()

    container.addEventListener("focusout", (ev)=>{
      if (!container.contains(ev.relatedTarget)) container.focus()
    })

    return () => {
      container.removeEventListener("focusout")
    };

  }, [])

  return (
    <div
      className={`${styles.modalWrapper} fixed inset-0 bg-opacity-25
    backdrop-blur-sm flex justify-center items-center
     shadow-[0_0_15px_6px_rgba(0,0,0,0.35)] z-[99999]
    bg-darkBlack`}
    >
      <div
        id="modal"
        className={`
          ${styles.modalContainer}
          shadow-modalShadow dark:shadow-modalDarkShadow bg-white  m-auto flex flex-col outline-none`}
        style={{ maxWidth }}
      >
        {children || (
          <div
            className={`${styles.defaultModal} w-full text-dark  flex flex-col gap-4`}
          >
            <div className="w-full flex">
              <h3 className="font-bold flex-1">{title}</h3>
              {exit ? (
                <div className="w-6 h-6 cursor-pointer">
                  <img
                    alt="exit-icon"
                    onClick={() => setModalObject(null)}
                    src={exitIcon}
                  />
                </div>
              ) : null}
            </div>

            <p
              className="text-darkElevationPrimary "
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className=" text-right">
              {action1Label ? (
                <button
                  onClick={action1}
                  type="button"
                  className={`
                      bg-transparent text-border ${styles.lightAction}
                    `}
                >
                  {action1Label}
                </button>
              ) : null}
              {action2Label ? (
                <button
                  onClick={action2}
                  type="button"
                  className={`bg-dark text-white ${styles.lightAction}`}
                >
                  {action2Label}
                </button>
              ) : null}
              {action3Label ? (
                <button
                  onClick={action3}
                  type="button"
                  className={`bg-dark text-white ${styles.lightAction}`}
                >
                  {action3Label}
                </button>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  exit: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  action1: PropTypes.func,
  action1Label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  action2: PropTypes.func,
  action2Label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  action3: PropTypes.func,
  action3Label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setModalObject: PropTypes.func.isRequired,
  children: PropTypes.element,
  maxWidth: PropTypes.string
};

Modal.defaultProps = {
  exit: true,
  title: null,
  description: null,
  action1: null,
  action1Label: null,
  action2: null,
  action2Label: null,
  action3: null,
  action3Label: null,
  children: null,
  maxWidth: null
};

export const mapDispatchToProps = dispatch => ({
  setModalObject: value => dispatch(elementsActions.setModalObject(value))
});

export default connect(null, mapDispatchToProps)(Modal);
