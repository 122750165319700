/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {QUICK_CHECKOUT} from '../../shared/constants/product';
// REDUX
import * as elementsActions from '../../redux/actions/elements';
import styles from './QuickCheckoutButton.module.css';
import {orderMethods} from "../../shared/constants/order";
import {QuickCheckoutDetails} from "../QuickCheckoutDetails/QuickCheckoutDetails";
import * as userRepo from "../../shared/repos/graphql/user";
import * as userActions from "../../redux/actions/user";

export class QuickCheckoutButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdatedUserInfo: false
    }
  }

  getCardForStore = (cards, selectedStore) => {
    if (!selectedStore || !cards) {
      return null;
    }
    for (let i = 0; i < cards.length; i+=1) {
      if (cards[i].store.id === selectedStore.id) {
        return cards[i];
      }
    }
    return null;
  }

  updateUserInfo = async (userInfo) => {
    const { setUserInfo } = this.props;
    const { isUpdatedUserInfo } = this.state;

    const isNotFullUserInfo = !userInfo ||
            !userInfo.user ||
            !userInfo.user.id ||
            !userInfo.user.customerCards ||
            userInfo.user.customerCards.length === 0 ||
            !userInfo.user.firstname ||
            !userInfo.user.lastname ||
            !userInfo.user.email ||
            !userInfo.user.telephone;

    if (!isUpdatedUserInfo && isNotFullUserInfo) {
      const me = await this.getUser();
      this.setState({isUpdatedUserInfo: true});
      setUserInfo({user: me});
    }
  }

  getUser = async () => {
    const response = await userRepo.getUser();
    const {me} = response.data;
    return me;
  }

  clickQuickCheckout = async () => {
    const {
      addProductToCart,
      setCartLoading,
      selectedStore,
      userAddress,
      userOrderMethod,
      setModalObject,
      product,
      history
    } = this.props;

    setCartLoading(true);

    let res;
    if (addProductToCart) {
      res = await addProductToCart();
    }
    if (res && res.addProductsToOrder) {
      setCartLoading(false);

      return setModalObject({
        children: (
          <div className={styles.widgetWrapper}>
            <QuickCheckoutDetails
              product={product}
              userOrderMethod={userOrderMethod}
              selectedStore={selectedStore}
              orderCode={res.addProductsToOrder.code}
              userAddress={userAddress}
              setModalObject={setModalObject}
              history={history}
            />
          </div>
        )
      });
    }

    setCartLoading(false);
  }

  render() {
    const {
      isDisabled,
      selectedStore,
      userInfo,
      userOrderMethod,
      userToken
    } = this.props;

    const isDelivery = userOrderMethod === orderMethods.delivery;
    const isPickUp = userOrderMethod === orderMethods.pickup;
    const isCheckoutMethod = isDelivery || isPickUp;

    const isUpdateUserInfo = !isDisabled && isCheckoutMethod && selectedStore && userToken;

    if (isUpdateUserInfo) {
      this.updateUserInfo(userInfo);
    }

    const customerCard = this.getCardForStore(userInfo?.user?.customerCards || [], selectedStore);
    const contactInfo = (userInfo?.user?.firstname && userInfo?.user?.lastname && userInfo?.user?.email && userInfo?.user?.telephone)
    const isFullUserInfo = customerCard && contactInfo;

    const showQuickCheckoutButton = isUpdateUserInfo && isFullUserInfo;

    return (showQuickCheckoutButton ? (
      <div
        className={styles.quickCheckoutButtonWrapper}
        onClick={this.clickQuickCheckout}
      >
        <div
          className={styles.quickCheckoutButton}
        >
          <div
            className={styles.labelTitle}
          >
            <p>{QUICK_CHECKOUT}</p>
          </div>
        </div>
      </div>
            ) : null
    );
  }
}

QuickCheckoutButton.propTypes = {
  addProductToCart: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.oneOfType(
              [
                PropTypes.number,
                PropTypes.string
              ]
      ),
      customerCards: PropTypes.array,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      telephone: PropTypes.string
    })
  }),
  userToken: PropTypes.string,
  userAddress: PropTypes.shape({ address1: PropTypes.string }),
  product: PropTypes.shape({}).isRequired,
  isDisabled: PropTypes.bool,
  setModalObject: PropTypes.func,
  setUserInfo: PropTypes.func,
  setCartLoading: PropTypes.func,
  userOrderMethod: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  selectedStore: PropTypes.shape(
          {
            id: PropTypes.oneOfType(
                    [
                      PropTypes.number,
                      PropTypes.string
                    ]
            )
          })
};

QuickCheckoutButton.defaultProps = {
  isDisabled: true,
  selectedStore: {id: ''},
  userInfo: {},
  userToken: null,
  userOrderMethod: '',
  userAddress: null,
  setModalObject: () => null,
  setUserInfo: () => null,
  setCartLoading: () => null,
};

export const mapStateToProps = (state) => {
  const {selectedStore, userAddress, userOrderMethod, userInfo, userToken} = state.user;
  return {selectedStore, userAddress, userOrderMethod, userInfo, userToken};
};

export const mapDispatchToProps = (dispatch) => ({
  setModalObject: (value) => dispatch(elementsActions.setModalObject(value)),
  setUserInfo: (value) => dispatch(userActions.setUserInfo(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickCheckoutButton);
