/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint no-param-reassign:  [2, { "props": false }] */
import React from "react";
import styles from "./CustomProductOption.module.css";

const addIcon = require("./imgs/plus.svg");
const minusIcon = require("./imgs/minus.svg");
const darkAddIcon = require("./imgs/dark-plus.svg");
const darkMinusIcon = require("./imgs/dark-minus.svg");

function CustomQuantityOption({
  image,
  name,
  quantity,
  onChangeAdd,
  onChangeRemove,
  calories,
  renderPrice,
  price,
  currency
}) {
  let paddingClass = null;

  if (name.length < 14) {
    paddingClass = styles.bottomMargin45;
  } else if (name.length > 18) {
    paddingClass = [styles.topMargin0, styles.bottomMargin15].join(" ");
  }

  return (
    <div
      className={`
      relative flex items-center justify-between
      ${styles.quantityWrapper} 
    `}
    >
      <div
        className={`${styles.noImagePlaceHolder} md:absolute left-[-10%] top-[-20%] flex`}
      >
        <div className="w-[85px] h-[85px] lg:w-[106px] lg:h-[106px]">
          <img
            alt={name}
            src={image}
            onError={i => {
              i.target.style.display = "none";
              return null;
            }}
            className="w-[85px] h-[85px] lg:w-[106px] lg:h-[106px] object-contain"
          />
        </div>
        <div className="md:block hidden mt-12">{renderPrice(price, currency)}</div>
      </div>
      <h3
        className={`${paddingClass} flex-1  w-[80%] font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] text-dark dark:text-white`}
      >
        {name}
        <br />
        <span className={styles.caloriesQuantity}>{calories}</span>
      </h3>

      <div className="flex flex-col items-end justify-end">
        {quantity ? (
          <div
            className={`
            ${styles.quantityContainer}
            md:absolute right-[-10%] top-[80%]
            w-[130px] h-11 rounded-[40px] 
            grid grid-cols-3 place-items-center
            p-0 m-0
            px-4
            bg-dark dark:bg-light
          `}
          >
            <div
              onClick={onChangeRemove}
              className="w-11 h-11 rounded-full  flex items-center  bg-dark dark:bg-light"
            >
              <img
                alt="minus"
                src={minusIcon}
                className="block dark:hidden m-auto"
              />
              <img
                alt="minus"
                src={darkMinusIcon}
                className="dark:block hidden m-auto"
              />
            </div>
            <span className="text-white dark:text-dark font-filsonProBold text-lg leading-[22px] tracking-[-0.1px]">
              {quantity}
            </span>
            <div
              onClick={onChangeAdd}
              className="w-11 h-11 rounded-full  flex items-center  bg-dark dark:bg-light"
            >
              <img
                alt="plus"
                src={addIcon}
                className="block dark:hidden m-auto"
              />
              <img
                alt="plus"
                src={darkAddIcon}
                className="dark:block hidden m-auto"
              />
            </div>
          </div>
        ) : (
          <div
            className={`
            ${styles.quantityContainer}
            md:absolute right-[-10%] top-[80%]  
            w-11 h-11           
          `}
          >
            <div
              onClick={onChangeAdd}
              className="w-11 h-11 rounded-full  flex items-center  bg-dark dark:bg-light"
            >
              <img
                alt="plus"
                src={addIcon}
                className="block dark:hidden m-auto "
              />
              <img
                alt="plus"
                src={darkAddIcon}
                className="dark:block hidden m-auto"
              />
            </div>
          </div>
        )}
        <p className={`${styles.renderPrice} block md:hidden`}>
          {renderPrice(price, currency)}
        </p>
      </div>
    </div>
  );
}

export default CustomQuantityOption;
