import React, { Component } from "react";
import PropTypes from "prop-types";

import {connect} from "react-redux";
import Link from "../../../components/LinkWithCountry/LinkWithCountry";
import { isRecaptcha } from "../../../shared/utilities/config";

import * as userRepo from "../../../shared/repos/graphql/user";
import Button from "../../../components/Button/Button";
import PhoneWidget from "../../../components/PhoneWidget/PhoneWidget";
import styles from "./Login.module.css";
import * as elementsActions from "../../../redux/actions/elements";
import {setCaptchaToken} from "../../../shared/utilities/captcha";
import captchaAction from "../../../shared/constants/captchaAction";

export class LoginWithPhone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseErrorMessage: null,
      loading: false,
      phoneNumber: {
        phone: null,
        code: null,
        maskValidation: false
      }
    };
  }

  handleLoginWithPhone = async () => {
    const { phoneNumber } = this.state;

    if (phoneNumber.maskValidation) {
      const { handleLogin, setVerificationToken, setVerificationAction } = this.props;
      this.setState({
        loading: true
      });
      if (isRecaptcha()) {
        await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.loginWithPhone);
      }
      const response = await userRepo
        .login(
          null,
          null,
          null,
          null,
          phoneNumber?.code,
          phoneNumber?.phone
        )
        .catch(error => {
          return this.setState({
            responseErrorMessage: error.message,
            loading: false
          });
        });

      if (response && response.data) {
        const { login } = response.data;
        const { token, customer } = login;

        handleLogin(token, customer, phoneNumber);
      }
      this.setState({
        loading: false
      });
    } else {
      this.setState({
        responseErrorMessage: "Please enter the correct phone number",
        loading: false
      });
    }
    return null;
  };

  handleInput = (fieldName, value) => {
    this.setState({
      [fieldName]: value
    });
  };

  render() {
    const { responseErrorMessage, phoneNumber, loading } = this.state;
    const { toggleLogin } = this.props;
    const isDisabled = loading || !phoneNumber.maskValidation;
    return (
      <div className="flex flex-col gap-2 loginWithPhone">
        <h1 className="font-filsonProRegular text-base leading-5">
          Enter your mobile number
        </h1>
        <PhoneWidget onInput={this.handleInput} fieldName="phoneNumber" />

        {responseErrorMessage && (
          <p className={styles.hasError}>{responseErrorMessage}</p>
        )}

        <div className={styles.actionsContainer}>
          <Button
            isLoading={loading}
            disabled={isDisabled}
            handleSubmit={isDisabled ? null : () => this.handleLoginWithPhone()}
            customContainerStyles={`${styles.buttonContainer} ${
              isDisabled ? "!bg-disabled" : ""
            } !block w-full bg-button text-white text-center py-2 h-11`}
            label="Continue"
          />
        </div>
        {/* SSO CONTAINER */}
        <div className="flex flex-col gap-1">
          <div className={`${styles.divider} my-4`}>
            <div className="border-t-[1px] border-darkElevationPrimary dark:border-darkElevationSecondary border-solid w-full" />
            <span>or</span>
            <div className="border-t-[1px] border-darkElevationPrimary dark:border-darkElevationSecondary border-solid w-full" />
          </div>

          <button
            onClick={toggleLogin}
            type="button"
            className="bg-light w-full"
          >
            <p>Continue with Email</p>
          </button>
        </div>

        <div className={styles.ssoContainer}>
          <p className={`${styles.privacyTermsCopy} mb-4 text-darkElevationSecondary`}>
            <Link to="/privacy-policy">Privacy Policy</Link>
            &nbsp; & &nbsp;
            <Link to="/terms">Terms and Conditions</Link>
          </p>
        </div>
      </div>
    );
  }
}

LoginWithPhone.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  setVerificationToken: PropTypes.func.isRequired,
  setVerificationAction: PropTypes.func.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  setVerificationToken: value =>
    dispatch(elementsActions.setVerificationToken(value)),
  setVerificationAction: value =>
    dispatch(elementsActions.setVerificationAction(value))
});

export default connect(null, mapDispatchToProps)(LoginWithPhone)
