import { CAROUSEL_SPEED } from "../../shared/constants/contentCards";

const cleanState = {
  showMobileMenu: false,
  showHeaderWidget: false,
  widgetOption: 'delivery',
  widgetValue: '',
  categoriesPosition: null,
  isStickyCategories: false,
  productCategory: 'Boxes',
  modalObject: null,
  menuCategories: null,
  products: null,
  doughProSpecialCategory: null,
  autocompleteSessionToken: '',
  topHeaderOffsetDivHeight: 99,
  carouselSpeed: CAROUSEL_SPEED,
  orderExperienceRating: 0,
  orderExperienceComment: '',
  orderExperienceSubmitted: false,
  mapDefaultCoordinates: {
    lat: 0.00,
    lng: 0.00
  },
  showDeliveryPickupWidget: false,
  hideHeaderMenuOnScroll: true,
  contentCardHandleClick: null,
  contentCardData: null,
  barcode:{
    showBarcodeCard: false,
    value: null
  },
  closeTimer: {
    timerValue: null,
    timerMessage: null,
    timerShouldShow: false,
  },
  verificationToken: '',
  verificationAction: '',
  useRecaptcha: 0,
};

const initialState = {
  ...cleanState
};

export default (state = initialState, { type, value }) => {

  const valueByType = [
    {
      type: "HAS_ACTIVE_ORDERS",
      value: { ...state, hasActiveOrders: true }
    },
    {
      type: "NO_ACTIVE_ORDERS",
      value: { ...state, hasActiveOrders: false }
    },
    {
      type: "TOGGLE_MOBILE_MENU",
      value: { ...state, showMobileMenu: !state.showMobileMenu }
    },
    {
      type: "TOGGLE_HEADER_WIDGET",
      value: { ...state, showHeaderWidget: !state.showHeaderWidget }
    },
    {
      type: "HIDE_HEADER_WIDGET",
      value: { ...state, showHeaderWidget: false }
    },
    { type: "SET_WIDGET_OPTION", value: { ...state, widgetOption: value } },
    { type: "SET_WIDGET_VALUE", value: { ...state, widgetValue: value } },
    {
      type: "SET_PRODUCT_POSITION",
      value: { ...state, categoriesPosition: value }
    },
    {
      type: "SET_STICKY_CATEGORIES",
      value: { ...state, isStickyCategories: value }
    },
    {
      type: "SET_PRODUCT_CATEGORY",
      value: { ...state, productCategory: value }
    },
    { type: "SET_MODAL_OBJECT", value: { ...state, modalObject: value } },
    { type: "SET_MENU_CATEGORIES", value: { ...state, menuCategories: value } },
    { type: "SET_PRODUCTS", value: { ...state, products: value } },
    {
      type: "SET_DOUGH_PRO_SPECIAL_CATEGORY",
      value: { ...state, doughProSpecialCategory: value }
    },
    {
      type: "SET_AUTOCOMPLETE_SESSION_TOKEN",
      value: { ...state, autocompleteSessionToken: value }
    },
    {
      type: "SET_TOP_HEADER_OFFSET_DIV_HEIGHT",
      value: { ...state, topHeaderOffsetDivHeight: value }
    },
    { type: "SET_CAROUSEL_SPEED", value: { ...state, carouselSpeed: value } },
    {
      type: "SET_ORDER_EXPERIENCE_RATING",
      value: { ...state, orderExperienceRating: value }
    },
    {
      type: "SET_ORDER_EXPERIENCE_COMMENT",
      value: { ...state, orderExperienceComment: value }
    },
    {
      type: "SET_ORDER_EXPERIENCE_SUBMITTED",
      value: { ...state, orderExperienceSubmitted: value }
    },
    {
      type: "SET_ORDER_MIN_AMOUNT",
      value: { ...state, orderMinAmount: value }
    },
    {
      type: "SET_MAP_DEFAULT_COORDINATES",
      value: { ...state, mapDefaultCoordinates: value }
    },
    {
      type: "SET_DELIVERY_PICKUP_ADDRESS_WIDGET",
      value: { ...state, ...value }
    },
    {
      type: "SET_HERO_CONTENT_CARD_DATA",
      value: {
        ...state,
        contentCardHandleClick: value?.contentCardHandleClick,
        contentCardData: value?.contentCardData
      }
    },
    {
      type: "TOGGLE_BARCODE_CARD",
      value: { ...state, barcode:{showBarcodeCard: value?.showBarcodeCard, value: value?.value} }
    },
    {
      type: "SET_CLOSE_TIMER",
      value: { ...state, ...value }
    },
    {
      type: "SET_VERIFICATION_TOKEN",
      value: { ...state, verificationToken: value },
    },
    {
      type: "SET_VERIFICATION_ACTION",
      value: { ...state, verificationAction: value },
    },
    {
      type: "SET_USE_RECAPTCHA",
      value: { ...state, useRecaptcha: value }
    },
  ];

  const selectedType = valueByType.find(item => item.type === type);

  return selectedType ? selectedType.value : state;
};
