import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as userActions from "../../../redux/actions/user";
import * as userRepo from "../../../shared/repos/graphql/user";

// COMPONENT
import Button from "../../../components/Button/Button";

import styles from "./Login.module.css";

import { routeCountryPath } from "../../../shared/utilities/common";
import { Tab, Tabs } from "../../../components/Tabs/Tabs";

import OtpInput from "./OtpInput";

import LoginWithEmail from "./LoginWithEmail";
import Welcome from "./Welcome";
import LoginWithPhone from "./LoginWithPhone";
import ResendOtp from "../../../components/ResendOtp/ResendOtp";
import { LABELS } from "../../../shared/constants/labels";

const backIcon = require("./imgs/back.svg");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loginWithMobile: true,
      continueWithOtp: false,
      phoneNumber: "",
      token: null,
      otp: "",
      activeTab: LABELS.SIGN_IN_TAB,
      user: null,
      password: null,
      phoneOTPErrMessage: null,
    };
    this.recaptchaComponentRef = React.createRef();

    this.loginGuard();
  }

  loginGuard = () => {
    const { userToken, history } = this.props;

    if (userToken) {
      history.push(routeCountryPath("/user/profile"));
    }
  };

  handleLoginWithPhone = (token, user, phoneNumber) => {
    this.setState({
      continueWithOtp: true,
      phoneNumber,
      user
    });
  };

  toggleLogin = () => {
    const { loginWithMobile } = this.state;
    this.setState({
      loginWithMobile: !loginWithMobile
    });
  };

  handleLogin = (token, user, password) => {
    this.setState({
      token,
      user,
      password,
    });
  };

  handleUserWelcome = () => {
    const { setUserId, order, setUserToken, history } = this.props;
    const { token, user, password } = this.state;
    return (
      <Welcome
        order={order}
        setUserToken={setUserToken}
        setUserId={setUserId}
        token={token}
        handleLoginWithPhone={this.handleLoginWithPhone}
        user={user}
        password={password}
        history={history}
      />
    );
  };

  loginWithEmail = () => {
    const { order } = this.props;
    return (
      <LoginWithEmail
        handleLogin={this.handleLogin}
        toggleLogin={this.toggleLogin}
        order={order}
      />
    );
  };

  loginWithMobile = () => {
    return (
      <LoginWithPhone
        handleLogin={this.handleLoginWithPhone}
        toggleLogin={this.toggleLogin}
      />
    );
  };

  onCompleteOtp = otp => {
    this.setState({ otp });
  };

  loginWithOtp = async () => {
    const { otp, phoneNumber, user, password } = this.state;
    try {
      this.setState({
        loading: true
      });
      const result = await userRepo.confirmCustomerTelephoneOtp(
        otp,
        phoneNumber?.code,
        phoneNumber?.phone,
        user?.email,
        password
      );
      const { data } = result;

      if (data?.confirmCustomerTelephoneOtp?.telephoneVerified) {
        const updatedUser = {
          ...user,
          telephoneVerified:
            data?.confirmCustomerTelephoneOtp?.telephoneVerified
        };
        this.setState({
          token: data?.confirmCustomerTelephoneOtp?.token,
          user: updatedUser,
          password,
        });
      }
      this.setState({
        loading: false,
        continueWithOtp: false
      });
    } catch (e) {
      if (!e?.message) {
        this.setState({
          phoneOTPErrMessage: "Something went wrong! Please try again.",
          loading: false
        });
        return;
      }

      this.setState({
        phoneOTPErrMessage: e.message,
        loading: false
      });
    }
  };

  handleResendOtp = async() => {
    const { phoneNumber } = this.state;
    await userRepo.resendCustomerVerificationTelephoneOtp(phoneNumber?.code, phoneNumber?.phone);
  }

  continueWithOtp = () => {
    const { phoneNumber, otp, loading, phoneOTPErrMessage } = this.state;
    const isDisabled = !(otp.length >= 4);
    return (
      <div className="flex gap-3 flex-col w-full">
        <OtpInput
          onComplete={this.onCompleteOtp}
          length={4}
          classInput="bg-[#2C0C37] border-2 border-darkDry text-center text-4xl"
          label={
            phoneNumber?.phone
              ? `Enter the 4-digit code sent to you at ${phoneNumber.code} ${phoneNumber.phoneFormat}`
              : null
          }
        />

        {phoneOTPErrMessage && (
          <p className={styles.hasError}>{phoneOTPErrMessage}</p>
        )}

        <ResendOtp handleClick={this.handleResendOtp} />

        <div className="w-full mt-4">
          <Button
            isLoading={loading}
            disabled={isDisabled}
            customContainerStyles={`${styles.buttonContainer} ${
              isDisabled ? "!bg-disabled" : ""
            } !block w-full bg-button text-white text-center py-2 h-11`}
            handleSubmit={isDisabled ? null : this.loginWithOtp}
            label="Continue"
          />
        </div>
      </div>
    );
  };

  switchToSignUp = () => {
    this.setState({
      activeTab: LABELS.SIGN_UP_TAB
    });
  };

  singUp = () => {
    const { history } = this.props;
    return history.push(routeCountryPath("/create-account"));
  };

  loginContainer = () => {
    const { loginWithMobile, continueWithOtp, token, activeTab } = this.state;

    return (
      <div className="w-full max-w-screen-desktop mx-auto">
        <div>
          <div className="border-2 border-darkDry rounded-full w-11 h-11 hidden">
            <img src={backIcon} alt="back" />
          </div>
        </div>
        <div
          className={`${styles.pageWrapper} flex justify-center items-center text-white w-full`}
        >
          {(token !== '' && token === null) || continueWithOtp ? (
            <div className={`${styles.pageContainer} w-[343px]`}>
              {!continueWithOtp && (
                <Tabs active={activeTab}>
                  <Tab label={LABELS.SIGN_IN_TAB}>
                    {loginWithMobile
                      ? this.loginWithMobile()
                      : this.loginWithEmail()}
                  </Tab>
                  <Tab label={LABELS.SIGN_UP_TAB} onClick={this.singUp} />
                </Tabs>
              )}

              {continueWithOtp && this.continueWithOtp()}
            </div>
          ) : (
            <div
              className={`${styles.pageContainer} w-[343px] flex justify-center items-center`}
            >
              {this.handleUserWelcome()}
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return <this.loginContainer />;
  }
}

Login.propTypes = {
  setUserToken: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  userToken: PropTypes.string.isRequired,
  order: PropTypes.shape({
    code: PropTypes.string
  })
};

Login.defaultProps = {
  order: null
};

export const mapStateToProps = state => {
  const { userToken } = state.user;
  const { order } = state;
  return { userToken, order };
};

export const mapDispatchToProps = dispatch => ({
  setUserToken: value => dispatch(userActions.setUserToken(value)),
  setUserId: value => dispatch(userActions.setUserId(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
