/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Barcode from 'react-barcode';
import { checkPath } from '../../shared/utilities/common';
import CustomerRegistration from './CustomerRegistration/CustomerRegistration';
import * as orderRepo from '../../shared/repos/graphql/order';
import * as orderActions from '../../redux/actions/order';
import * as userActions from '../../redux/actions/user'
import HeroLeft from './HeroLeft/HeroLeft';

import {
  renderGiftHeroLeft,
  renderGiftHeroRight,
  renderGiftBottom,
  renderConfirmationHeroRight,
  renderLoggedInConfirmation
} from './rendering';

import styles from './ConfirmOrder.module.css';
import { getBarcodeId } from "../../shared/utilities/renderings";
import { orderTypeIds, recipientMessageLength } from "../../shared/constants/order";
import log from '../../shared/utilities/datadog/log';

class ConfirmOrder extends Component {
  constructor(props) {
    super(props);

    const orderCode = this.props.match.params.id;
    const { order } = this.props;
    const orderRecipient = order ? order.recipient : null;

    this.state = {
      giftFirstName: orderRecipient ? orderRecipient.firstName : '',
      giftLastName: orderRecipient ? orderRecipient.lastName : '',
      giftPhone: orderRecipient ? orderRecipient.phone : '',
      giftMessage: order ? order.comment : '',
      giftMessageLabel: '',
      firstnameErr: '',
      lastnameErr: '',
      phoneErr: '',
      edit: false,
      isValidData: true
    };

    this.getConfirmedOrder(orderCode);
  }

  async getConfirmedOrder(orderCode) {
    const { orderUpdated } = this.props;

    const response = await orderRepo.getConfirmedOrder(orderCode);
    const { order } = response.data;

    log.logInfo(
      "[ConfirmOrder][getConfirmedOrder] ",
      {
        "OrderCode": orderCode,
        "response": response.data,
      });

    const messageMaxLength = order.orderTypeId === orderTypeIds.shipping ? recipientMessageLength.shippingOrder : recipientMessageLength.anotherOrder;
    const label = `Type a message for the recipient ( max ${messageMaxLength} characters )`;

    if (order.recipient) {
      this.setState({
        giftFirstName: order.recipient.firstName || '',
        giftLastName: order.recipient.lastName || '',
        giftPhone: order.recipient.phone || '',
        giftMessage: order.comment || '',
        giftMessageLabel: order.comment.length > 1 ? '' : label,
        edit: true
      });
    }
    else {
      this.setState({ giftMessageLabel: label })
    }

    orderUpdated(order);
  };

  renderBarCode = () => {
    const { order } = this.props;
    const { customer } = order;
    const barcodeId = getBarcodeId(customer.loyalty.id);

    return (
      <div className={styles.barCodeContainer}>
        <div>
          <p>Scan barcode to use rewards</p>
          <Barcode
            fontSize={0}
            background="#fcf7ef"
            value={barcodeId}
          />
        </div>
      </div>
    );
  };

  confirmOrderContainer = () => {
    const { order } = this.props; // eslint-disable-line

    const customer = order ? order.customer : null;

    const isGift = checkPath(this, '/gift');

    return (
      <div className={styles.pageWrapper}>
        <div className={styles.pageContainer}>
          { order && <input type="hidden" value={order.id} id="confirmOrderId" /> }
          <div className={styles.pageTop}>
            {isGift ? renderGiftHeroLeft(this) : <HeroLeft />}
            {isGift ? renderGiftHeroRight() : renderConfirmationHeroRight()}
          </div>

          <div className={`${styles.pageBottom} max-w-screen-desktop mx-auto text-left`}>
            {isGift ? renderGiftBottom(this) : null}

            <div className={!isGift && customer ? styles.barCodeWrapper : null}>
              {!isGift && customer ? renderLoggedInConfirmation(this) : null}
              {/* {!isGift && customer ? this.renderBarCode() : null} */}
            </div>
            {!isGift && !customer && order ? <CustomerRegistration /> : null}

          </div>
        </div>
      </div>
    );
  };

  render() {
    return <this.confirmOrderContainer />;
  }
}

ConfirmOrder.propTypes = {
  orderUpdated: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  setAlertRecipientPhoneUpdated: PropTypes.func.isRequired
};

export const mapStateToProps = state => {
  return {
    order: state.order,
    alertRecipientPhone: state.user.alertRecipientPhone
  };
};

export const mapDispatchToProps = (dispatch) => ({
  orderUpdated: (value) => dispatch(orderActions.orderUpdated(value)),
  setAlertRecipientPhoneUpdated: (value) => dispatch(userActions.setAlertRecipientPhone(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOrder);
