import { store } from '../../redux/store';
import * as userActions from '../../redux/actions/user';
import * as elementsActions from '../../redux/actions/elements';
import { orderTypeChangeModal,orderPickTypeChangeModal, orderBelowMinimumDeliveryAmount, noDeliveryAddressSelected, notItemInTheCart, orderLocationChangeModal } from './modals';
import {orderMethods, orderTypeIds} from '../constants/order';
import * as tagmanagerEvents from './tagmanagerEvents';
import dispatches from './order-dispatches';
import { defaultCurrency } from '../constants/currency';

export const clearCart = (action = null, ...args) => {
    dispatches(store, userActions, elementsActions);

    if (action) {
        action(...args);
    }
};

export const guardOrderChange = (isNewType, action, ...args) => {
    if (!isNewType) {
        return action(...args);
    }

    const storeState = store.getState();
    if (storeState.user.userCart && storeState.user.userCart.length > 0) {
        tagmanagerEvents.abandonCart(storeState.user.userCartId, storeState.user.userCart);
    }
    
    return store.dispatch(elementsActions.setModalObject(orderTypeChangeModal(clearCart, action, ...args)));
};

export const guardOrderChangeLocation = (isNewType, action, ...args) => {
    if (!isNewType) {
        return action(...args);
    }

    const storeState = store.getState();
    if (storeState.user.userCart && storeState.user.userCart.length > 0) {
        tagmanagerEvents.abandonCart(storeState.user.userCartId, storeState.user.userCart);
    }
    
    return store.dispatch(elementsActions.setModalObject(orderLocationChangeModal(clearCart, action, ...args)));
};

export const guardOrderChangeToPickup = (isNewType, action, ...args) => {
    if (!isNewType) {
        return action(...args);
    }

    const storeState = store.getState();
    if (storeState.user.userCart && storeState.user.userCart.length > 0) {
        tagmanagerEvents.abandonCart(storeState.user.userCartId, storeState.user.userCart);
    }
    
    return store.dispatch(elementsActions.setModalObject(orderPickTypeChangeModal(clearCart, action, ...args)));
};

export const isDoughProOrder = (orderTypeId) => {
    if (!orderTypeId) {
        return false;
    }
    
    return parseInt(orderTypeId, 10) === orderTypeIds.doughPro;
};

export const isDeliveryOrPickup = (userOrderMethod) => {
    return userOrderMethod === orderMethods.delivery || userOrderMethod === orderMethods.pickup;
};

export const checkoutOrderMinAmount = (orderMinAmount, currency = defaultCurrency.symbol) => {
    store.dispatch(elementsActions.setModalObject(
        orderBelowMinimumDeliveryAmount(orderMinAmount, currency)
    ));
};

export const checkoutWithNoItems = () => {
    store.dispatch(elementsActions.setModalObject(
        notItemInTheCart()
    ));
};

export const checkoutNoDeliveryAddressSelected = (action) => {
    store.dispatch(elementsActions.setModalObject(
        noDeliveryAddressSelected(action)
    ));
};

export const orderETA = (order) => {
  if (!order) {
    return 'Delivery in ASAP';
  }

  if (order.orderTypeId === orderTypeIds.shipping) {
    return 'Shipping to ';
  }

  return order.deliveryEstimate ? `Delivery in ${order.deliveryEstimate}`
    : "Delivery in ASAP";
};

export default guardOrderChange;

