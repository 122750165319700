import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import Link from "../../../components/LinkWithCountry/LinkWithCountry";
import { isRecaptcha } from "../../../shared/utilities/config";
import {
  setCaptchaToken
} from "../../../shared/utilities/captcha";

import * as userRepo from "../../../shared/repos/graphql/user";

// FORMS
import { handleFormInput } from "../../../shared/utilities/validations";
import Button from "../../../components/Button/Button";
import SimpleInput from "../../../components/Input/SimpleInput";
import { defaultLabels } from "../../../shared/constants/login";
import styles from "./Login.module.css";
import captchaAction from "../../../shared/constants/captchaAction";
import * as elementsActions from "../../../redux/actions/elements";

const usernameIcon = require("./imgs/username.svg");
const passwordIcon = require("./imgs/password.svg");

export class LoginWithEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      usernameErrorMessage: null,
      passwordErrorMessage: null,
      responseErrorMessage: null,
      loading: false
    };
  }

  handleSubmit = async () => {
    const { username, password } = this.state;
    const { handleLogin, setVerificationToken, setVerificationAction } = this.props;
    this.state = {
      usernameErrorMessage: null,
      passwordErrorMessage: null,
      responseErrorMessage: null
    };
    if (!username) {
      return this.setState({
        usernameErrorMessage: "Please enter a username. "
      });
    }

    if (!password) {
      return this.setState({
        passwordErrorMessage: "Please enter a password. "
      });
    }
    this.setState({
      loading: true
    });

    if (isRecaptcha()) {
      await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.loginWithEmail);
    }
    const response = await userRepo
      .login(username, password, null, null, null)
      .catch(error => {
        return this.setState({
          responseErrorMessage: error.message,
          loading: false
        });
      });

    if (response && response.data) {
      const { login } = response.data;
      const { token, customer } = login;

      await handleLogin(token, customer, password);
    }
    this.setState({
      loading: false
    });
    return null;
  };

  render() {
    const {
      username,
      password,
      usernameErrorMessage,
      passwordErrorMessage,
      responseErrorMessage,
      loading
    } = this.state;
    const { toggleLogin } = this.props;

    const isDisabled = loading;
    return (
      <div className="flex flex-col gap-2 loginWithEmail">
        <h1 className="font-filsonProRegular text-base leading-5">
          Enter your email and password
        </h1>
        <SimpleInput
          handleInput={e => {
            handleFormInput(e, this, "username");
          }}
          placeholder="Email address *"
          value={username}
          label={defaultLabels.email}
          inputIcon={usernameIcon}
          errorMessage={usernameErrorMessage}
          fieldContainerClass="border-[1px] border-darkElevationPrimary rounded-[40px]"
          fieldClass={`!text-white w-full bg-transparent outline-none h-11 ${styles.inputLightModeOverride}`}

        />
        <SimpleInput
          customClass="mt-2"
          handleInput={e => {
            handleFormInput(e, this, "password");
          }}
          placeholder="Password *"
          value={password}
          errorMessage={passwordErrorMessage}
          inputIcon={passwordIcon}
          type="password"
          label={defaultLabels.password}
          fieldContainerClass="border-[1px] border-darkElevationPrimary rounded-[40px]"
          fieldClass={`!text-white w-full bg-transparent outline-none h-11 ${styles.inputLightModeOverride}`}
        />
        <div className={styles.actionsContainer}>
          <Button
            isLoading={loading}
            disabled={isDisabled}
            handleSubmit={isDisabled ? null : () => this.handleSubmit()}
            customContainerStyles={`${styles.buttonContainer} !block w-full bg-button text-white text-center py-2 h-11`}
            label="Continue"
          />
        </div>
        {responseErrorMessage && (
          <p className={styles.hasError}>{responseErrorMessage}</p>
        )}
        <div className={`${styles.forgotPassword} w-full text-center mt-2`}>
          <p>
            <Link to="/reset-password">Forgot Password?</Link>
          </p>
        </div>

        <div className="flex flex-col gap-1">
          <div className={`${styles.divider} my-4`}>
            <div className="border-t-[1px] border-darkElevationPrimary dark:border-darkElevationSecondary border-solid w-full" />
            <span>or</span>
            <div className="border-t-[1px] border-darkElevationPrimary dark:border-darkElevationSecondary border-solid w-full" />
          </div>

          <button
            onClick={toggleLogin}
            type="button"
            className="bg-light w-full"
          >
            <p>Continue with Phone</p>
          </button>
        </div>

        {/* SSO CONTAINER */}
        <div className={styles.ssoContainer}>
          <p className={`${styles.privacyTermsCopy} mb-4 text-darkElevationSecondary`}>
            <Link to="/privacy-policy">Privacy Policy</Link>
            &nbsp; & &nbsp;
            <Link to="/terms">Terms and Conditions</Link>
          </p>
        </div>
      </div>
    );
  }
}

LoginWithEmail.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  setVerificationToken: PropTypes.func.isRequired,
  setVerificationAction: PropTypes.func.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  setVerificationToken: value =>
    dispatch(elementsActions.setVerificationToken(value)),
  setVerificationAction: value =>
    dispatch(elementsActions.setVerificationAction(value)),
});

export default connect(null, mapDispatchToProps)(LoginWithEmail)
