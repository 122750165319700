import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import styles from "./Instructions.module.css";

function Instructions({ contents }) {
  return (
    <div className="w-full flex flex-col justify-center gap-8 px-4">
      <div className="flex flex-col md:items-center justify-center gap-3 h-[88px]">
        <h1 className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-dark">
          {contents?.heading}
        </h1>
        <p className="text-darkElevationPrimary">{contents?.description}</p>
      </div>

      <div className="w-full flex flex-col md:flex-row items-center justify-between gap-4">
        {contents?.steps &&
          contents?.steps.map((p, i) => (
            <div className="flex md:items-center md:justify-center flex-row md:flex-col gap-4 max-w-[362px]">
              <p
                className="h-[43px] w-[43px] bg-dark text-white rounded-full flex items-center justify-center
            text-xl font-congenialBlack
          "
              >
                {i + 1}
              </p>
              <div className="md:w-full w-[80%] flex flex-col md:items-center md:justify-center gap-2">
                <h1 className="font-filsonProBold text-2xl leading-6 tracking-[-0.3px] text-dark">
                  {p.title}
                </h1>

                <p
                  className={`${styles.instruction_desc} 
                  md:text-center text-lg font-filsonProRegular leading-[22px] tracking-[-0.1px] text-darkElevationPrimary`}
                >
                  {parse(p.description)}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

Instructions.propTypes = {
  contents: PropTypes.shape({}).isRequired
};

export default Instructions;
