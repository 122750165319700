import * as Utils from "@contentstack/utils";
import ContentstackLivePreview from "@contentstack/live-preview-utils";
import {
  customHostUrl,
  initializeContentStackSdk,
  isValidCustomHostUrl,
  getConfig
} from "./utils";

const envConfig = getConfig();

let customHostBaseUrl = envConfig.CONTENTSTACK_API_HOST;

customHostBaseUrl = customHostUrl(customHostBaseUrl);

let Stack;

try {
  // SDK initialization
  Stack = initializeContentStackSdk();

  // set host url only for custom host or non prod base url's
  if (isValidCustomHostUrl(customHostBaseUrl)) {
    Stack.setHost(customHostBaseUrl);
  }

  // Setting LP if enabled
  ContentstackLivePreview.init({
    stackSdk: Stack,
    clientUrlParams: {
      host: envConfig.CONTENTSTACK_APP_HOST
    },
    stackDetails: {
      apiKey: envConfig.CONTENTSTACK_API_KEY,
      environment: envConfig.CONTENTSTACK_ENVIRONMENT
    },
    enable: envConfig.CONTENTSTACK_LIVE_PREVIEW === "true",
    ssr: false
  }).catch(err => {
    // eslint-disable-next-line no-console
    console.error("[Content Stack Error]: ", err);
  });
} catch (error) {
  // eslint-disable-next-line no-console
  console.error("[Content Stack Error]: ", error);
}

const renderOption = {
  span: (node, next) => next(node.children)
};

export const { onEntryChange } = ContentstackLivePreview;

/**
 *
 * fetches all the entries from specific content-type
 * @param {* content-type uid} contentTypeUid
 * @param {* reference field name} referenceFieldPath
 * @param {* Json RTE path} jsonRtePath
 *
 */
export const getEntry = ({
  contentTypeUid,
  referenceFieldPath,
  jsonRtePath,
  locale
}) => {
  return new Promise((resolve, reject) => {
    let language = locale || window.environment.REACT_APP_DEFAULT_LOCALE_CODE;
    language = language.toLowerCase().replace('_', '-');

    const query = Stack.ContentType(contentTypeUid).Query().language(language);
    if (referenceFieldPath) query.includeReference(referenceFieldPath);
    query
      .toJSON()
      .find()
      .then(
        result => {
          // eslint-disable-next-line
          jsonRtePath &&
            Utils.jsonToHTML({
              entry: result,
              paths: jsonRtePath,
              renderOption
            });
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
  });
};

/**
 *fetches specific entry from a content-type
 *
 * @param {* content-type uid} contentTypeUid
 * @param {* url for entry to be fetched} entryUrl
 * @param {* reference field name} referenceFieldPath
 * @param {* Json RTE path} jsonRtePath
 * @returns
 */
export const getEntryByUrl = ({
  contentTypeUid,
  entryUrl,
  referenceFieldPath,
  jsonRtePath,
  locale
}) => {
  return new Promise((resolve, reject) => {
    let language = locale || window.environment.REACT_APP_DEFAULT_LOCALE_CODE;
    language = language.toLowerCase().replace('_', '-');

    const blogQuery = Stack.ContentType(contentTypeUid).Query().language(language);
    if (referenceFieldPath) blogQuery.includeReference(referenceFieldPath);
    blogQuery.toJSON();
    const data = blogQuery.where("url", `${entryUrl}`).find();
    data.then(
      result => {
        // eslint-disable-next-line
        jsonRtePath &&
          Utils.jsonToHTML({
            entry: result,
            paths: jsonRtePath,
            renderOption
          });
        resolve(result[0]);
      },
      error => {
        reject(error);
      }
    );
  });
};
