import { addEditableTags } from "@contentstack/utils";
import { getConfig } from "./contentstack/utils";
import { getEntry, getEntryByUrl } from "./contentstack";

const envConfig = getConfig();
const liveEdit = envConfig.CONTENTSTACK_LIVE_EDIT_TAGS === "true";

export const getAllEntries = async (contentTypeUid, locale) => {
  const response = await getEntry({
    contentTypeUid,
    referenceFieldPath: undefined,
    jsonRtePath: undefined,
    locale
  });
  // eslint-disable-next-line no-unused-expressions
  liveEdit &&
    response[0].forEach(entry => addEditableTags(entry, contentTypeUid, true));

  return {
    value: response[0]
  };
};

export const getPageRes = async ({
  entryUrl,
  contentTypeUid,
  referenceFieldPath,
  jsonRtePath,
  locale
}) => {
  const response = await getEntryByUrl({
    contentTypeUid,
    entryUrl,
    referenceFieldPath,
    jsonRtePath,
    locale
  });
  // eslint-disable-next-line no-unused-expressions
  liveEdit && addEditableTags(response[0], contentTypeUid, true);
  return {
    value: response[0]
  };
};

export const getHeaderRes = async (locale) => {
  const response = await getEntry({
    contentTypeUid: "header",
    referenceFieldPath: ["navigation_menu.page_reference"],
    jsonRtePath: ["notification_bar.announcement_text"],
    locale
  });
  // eslint-disable-next-line no-unused-expressions
  liveEdit && addEditableTags(response[0][0], "header", true);
  return {
    value: response[0][0]
  };
};

export const getFooterRes = async (locale) => {
  const response = await getEntry({
    contentTypeUid: "footer",
    referenceFieldPath: undefined,
    jsonRtePath: ["copyright"],
    locale
  });
  // eslint-disable-next-line no-unused-expressions
  liveEdit && addEditableTags(response[0][0], "footer", true);

  return {
    value: response[0][0]
  };
};

export const getPageComponents = async (uid, locale) => {
  const response = await getEntry({
    contentTypeUid: uid,
    referenceFieldPath: undefined,
    jsonRtePath: [],
    locale
  });
  // eslint-disable-next-line no-unused-expressions
  liveEdit && addEditableTags(response[0][0], uid, true);

  return {
    value: response[0][0]
  };
};
