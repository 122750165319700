/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

// REDUX
import { connect } from "react-redux";

// MAIN ROUTES
import Appboy from '@braze/web-sdk';
import _ from "lodash";
import UserRoutes from "../../routes/UserRoutes/UserRoutes";
import ProductRoutes from "../../routes/ProductRoutes/ProductRoutes";
import CheckoutRoutes from "../../routes/CheckoutRoutes/CheckoutRoutes";
import IndexRoutes from "../../routes/IndexRoutes/IndexRoutes";

// 404 ROUTE
import NoMatch from "../NoMatch/NoMatch";

// COMPONENTS
import Modal from "../../components/Modal/Modal";
import MobileMenu from "../../components/MobileMenu/MobileMenu";

import styles from "./PageContainer.module.css";
import * as countryActions from "../../redux/actions/country";
import * as footerSettingsActions from "../../redux/actions/footerSettings";
import defaultCountry from "../../shared/constants/country";
import Loader from "../../components/Loader/Loader";

import * as doughProActions from "../../redux/actions/doughPro";
import * as shippingActions from "../../redux/actions/shipping";
import * as productActions from "../../redux/actions/product";
import * as brazeActions from "../../redux/actions/braze";
import * as elementsActions from "../../redux/actions/elements";
import * as siteSettingRepo from "../../shared/repos/graphql/siteSetting";
import * as tipsActions from "../../redux/actions/tips";
import * as loyaltyActions from "../../redux/actions/loyalty";
import * as giftCardActions from '../../redux/actions/giftCard';
import * as orderTotalsTitles from '../../redux/actions/orderTotalsTitles';
import * as contactUsSettingActions from '../../redux/actions/contactUsSetting';
import * as addressActions from '../../redux/actions/address';
import { getCountries, getPhonePrefixes } from "../../shared/repos/graphql/country";
import siteSetting from '../../shared/constants/siteSetting';
import * as brazeRepo from "../../shared/repos/marketing/braze";
import ddRum from '../../shared/utilities/datadog/rum';
import { sendGeolocationToBraze } from "../../shared/utilities/braze";
import endpoints from '../../shared/constants/endpoints';
import { defaultCurrency } from '../../shared/constants/currency';
import CheckoutButton from "../../components/CheckoutButton/CheckoutButton";
import elementConstant from "../../shared/constants/element";
// UTILS
import {
  // renderBackground,
  renderGlobalHeader,
  renderGlobalFooter
} from "../../shared/utilities/renderings";
import Error from "../../components/Error/Error";
import {
  isHasCountryPathInLocation,
  addCountryPathToLocation,
  getLocationWithoutCountryPath,
  removeCountryPathFromLocation,
} from "../../shared/utilities/pathes";
import tipsTypes from "../../shared/constants/tipsTypes";
import * as userActions from "../../redux/actions/user";
import {store as mainStore} from "../../redux/store";
import * as customCakeTopperActions from "../../redux/actions/customCakeTopper";
import * as currencyActions from "../../redux/actions/currency";
import {clearStorage, routeCountryPath} from "../../shared/utilities/common";
import { checkoutOrderMinAmount } from "../../shared/utilities/orders";
import { orderMethods } from "../../shared/constants/order";
import * as orderRepo from "../../shared/repos/graphql/order";
import { ids } from "../../shared/constants/locales";
import * as themeActions from "../../redux/actions/theme";

class PageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  componentDidMount = async () => {
    const {
      setSelectedCountry,
      setUserZoneId,
      resetUserData,
      resetCustomCakeTopperData,
      resetCurrencyData,
      userCartId
    } = this.props;
    try {
      const { userZoneId } = mainStore.getState().user;
      const zoneId = Number(window.environment.REACT_APP_ZONE_ID ?? 1);

      if (userZoneId && zoneId && userZoneId !== zoneId) {
        resetCustomCakeTopperData();
        resetCurrencyData();
        resetUserData();
        clearStorage();
        setUserZoneId(zoneId);
      }

      if (!userZoneId) {
        setUserZoneId(zoneId);
      }

      if (userCartId) {
        await orderRepo.getOrder(userCartId);
      }

      const resp = await this.getSiteSettings();
      const allSettings = resp.data.siteSettings;
      const countryActiveParam = _.find(allSettings, {
        name: siteSetting.COUNTRY_SELECTION_ENABLED,
        country_id: null
      });
      if (countryActiveParam && +countryActiveParam.value) {
        const selectedCountry = await this.handleCountry();
        const specific = this.setSiteSettingsByCountry(
          allSettings,
          +selectedCountry.id
        );
        this.registerOneTrust(specific);
        this.registerBraze(specific);
        ddRum.initialize(specific);
        this.setState({ isLoading: false });
      } else {
        const common = this.setSiteSettingsByCountry(allSettings, null);
        this.registerOneTrust(common);
        this.registerBraze(common);
        ddRum.initialize(common);
        setSelectedCountry(defaultCountry);
        this.setState({ isLoading: false });
      }

      getPhonePrefixes().then((res) => {
        countryActions.setPhonePrefixes(res.data.phonePrefixes);
      });

    } catch (e) {
      setSelectedCountry(defaultCountry);
      this.setState({ isLoading: false });
    }
  };

  handleCountry = () => {
    const { setAllCountries, setSelectedCountry, selectedCountry } = this.props;
    return new Promise((resolve, reject) => {
      const zoneId = Number(window.environment.REACT_APP_ZONE_ID ?? 1);
      let pathname = window.location.pathname.slice(1);
      const matches = pathname.match(/^[a-z]{2}(\/|$)/g);
      if (pathname.length >= 2 && matches && matches.length > 0) {
        pathname = matches[0].replace("/", "");
      } else {
        pathname = "";
      }
      getCountries()
        .then(res => {
          if (!res || !res.data || !res.data.countries) {
            reject();
          } else {
            const { all, selected } = res.data.countries;
            let activeCountry;
            setAllCountries(all);
            if (
              selectedCountry &&
              selectedCountry.id === null &&
              selected &&
              pathname === ""
            ) {
              activeCountry = selected;
            } else if (pathname !== (selected.path_url ?? "")) {
              all.forEach(country => {
                if (pathname === (country.path_url ?? "")) {
                  activeCountry = country;
                }
              });
            }
            if (
              activeCountry === undefined &&
              selectedCountry &&
              selectedCountry.id &&
              (selectedCountry.path_url ?? "") === pathname
            ) {
              activeCountry = _.find(all, { id: selectedCountry.id });
            }
            if (activeCountry === undefined) {
              activeCountry = selected;
            }
            if (+activeCountry.zone_id === +zoneId) {
              setSelectedCountry(activeCountry);
            } else {
              window.location.href = activeCountry.path_url ?? "";
            }

            resolve(activeCountry);
          }
        })
        .catch(reject);
    });
  };

  registerOneTrust = settings => {
    const onetrustId = _.find(settings, {
      name: siteSetting.ONETRUST_SCRIPT_ID
    });

    if (!onetrustId || !onetrustId.value) {
      return;
    }

    const oneTrustScript1 = document.createElement("script");
    oneTrustScript1.src = `https://cdn.cookielaw.org/consent/${onetrustId.value}/OtAutoBlock.js`;
    document.head.appendChild(oneTrustScript1);

    const oneTrustScript2 = document.createElement("script");
    oneTrustScript2.src =
      "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    oneTrustScript2.dataset.domainScript = onetrustId.value;
    document.head.appendChild(oneTrustScript2);
  };

  registerBraze = settings => {
    const {
      introBannerVisibilityUpdated,
      shouldDisplayIntroBanner,
      setContentCardRefreshDelay
    } = this.props;
    const scriptEnvironments = ["local", "dev", "production", "staging"];
    const brazeApiKey = _.find(settings, {
      name: siteSetting.BRAZE_APP_ID_WEB
    });
    const cardRefreshDelay = _.find(settings, {
      name: siteSetting.CONTENT_CARD_REFRESH_DELAY
    });
    const delay = parseInt(cardRefreshDelay.value, 10);
    setContentCardRefreshDelay(delay);
    if (
      brazeApiKey &&
      brazeApiKey.value !== "" &&
      scriptEnvironments.includes(window.environment.REACT_APP_ENV)
    ) {
      Appboy.destroy();
      Appboy.initialize(brazeApiKey.value, {
        baseUrl: window.environment.REACT_APP_BRAZE_ENDPOINT
      });
      brazeRepo.subscribeToContentCardUpdates();
      Appboy.openSession();
      Appboy.requestImmediateDataFlush();
      setTimeout(() => {
        Appboy.requestContentCardsRefresh();
        if (shouldDisplayIntroBanner) {
          introBannerVisibilityUpdated(false);
        }
      }, cardRefreshDelay.value);
      Appboy.getDeviceId(deviceId => {
        siteSettingRepo
          .saveDeviceId(deviceId, ids.appVersion)
          .then(() => {})
          .catch(() => {});
      });
      sendGeolocationToBraze();
    }
  };

  setSiteSettingsByCountry = (settings, countryId = null) => {
    let specificSettings = [];
    const commonSettings = _.filter(settings, setting => {
      return setting.country_id === null;
    });
    if (countryId === null) {
      specificSettings = commonSettings;
    } else {
      const byCountry = _.filter(settings, setting => {
        return setting.country_id === countryId;
      });
      specificSettings = _.map(commonSettings, setting => {
        const byCountrySetting = _.find(byCountry, { name: setting.name });
        return byCountrySetting ?? setting;
      });
    }
    const {
      setIsDoughProActive,
      isDoughProActive,
      setIsShippingActive,
      isShippingActive,
      setIsCaloriesActive,
      isCaloriesActive,
      setIsCountrySelectActive,
      isCountrySelectActive,
      setDefaultHeroBanner,
      setCarouselSpeed,
      setIntroHeroBanner,
      setTiktokLink,
      setInstagramLink,
      setFacebookLink,
      setFooterMenuItemsSettings,
      setFooterMenuItemsLinksSettings,
      setTipsSettings,
      setIsGiftcardDisabled,
      setOrderTotalsTitles,
      setShowTaxesAfterTotal,
      setDefaultMapCoordinates,
      setContactUsSettings,
      setUseFullyQualifiedAddressForDelivery,
      setThemeSettings,
      setPasswordlessEnable,
      setActiveLoyaltySetting,
      setReferralEnabledSetting,
      setUseRecaptcha
    } = this.props;
    const footerMenuItemsSettings = {
      [siteSetting.IS_FOOTER_MENU_NEWSROOM_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_FAQ_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_PRIVACY_POLICY_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_TERMS_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_EVENT_TERMS_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_ACCESSIBILITY_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_CONTACT_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_ABOUT_US_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_CAREERS_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_CORPORATE_SALES_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_DONATIONS_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_MERCH_ENABLED]: false,
      [siteSetting.IS_FOOTER_MENU_ALLERGEN_ENABLED]: false
    };

    const contactUsSetting = {
      [siteSetting.IS_FOOTER_MENU_NEWSROOM_ENABLED]: false,
      [siteSetting.IS_CONTACT_US_FEEDBACK_ENABLE]: false,
      [siteSetting.IS_CONTACT_US_GENERAL_ENABLE]: false,
      [siteSetting.IS_CONTACT_US_EVENTS_ENABLE]: false,
      [siteSetting.IS_CONTACT_US_REAL_ESTATE_ENABLE]: false,
      [siteSetting.IS_CONTACT_US_VENDOR_ENABLE]: false,
      [siteSetting.IS_CONTACT_US_PRESS_ENABLE]: false,
      [siteSetting.IS_CONTACT_US_MERCH_ENABLE]: false,

      [siteSetting.CONTACT_US_FEEDBACK_EMAILS]: "",
      [siteSetting.CONTACT_US_GENERAL_EMAILS]: "",
      [siteSetting.CONTACT_US_EVENTS_EMAILS]: "",
      [siteSetting.CONTACT_US_REAL_ESTATE_EMAILS]: "",
      [siteSetting.CONTACT_US_VENDOR_EMAILS]: "",
      [siteSetting.CONTACT_US_PRESS_EMAILS]: ""
    };
    const tipsSettings = {
      [siteSetting.TIPS_TYPE]: tipsTypes.TYPE_PERCENT,
      [siteSetting.TIPS_VALUES]: [18, 20, 25]
    };
    const footerMenuItemsLinks = {};
    const themeSettings = {
      [siteSetting.IS_THEME_SWITCH_ENABLED]: false,
    }

    specificSettings.forEach(siteSettings => {
      const { name, value } = siteSettings;
      switch (name) {
        case siteSetting.COOKIE_MAGIC_ACTIVE: {
          const isDoughProActiveResult = parseInt(value, 10) === 1;
          if (isDoughProActive === isDoughProActiveResult) {
            break;
          }
          setIsDoughProActive(isDoughProActiveResult);
          break;
        }
        case siteSetting.SHIPPING_ACTIVE: {
          const isShippingActiveResult = parseInt(value, 10) === 1;

          if (isShippingActive === isShippingActiveResult) {
            break;
          }
          setIsShippingActive(isShippingActiveResult);
          break;
        }
        case siteSetting.SHOW_CALORIES: {
          const isCaloriesActiveResult = parseInt(value, 10) === 1;
          if (isCaloriesActive === isCaloriesActiveResult) {
            break;
          }
          setIsCaloriesActive(isCaloriesActiveResult);
          break;
        }
        case siteSetting.COUNTRY_SELECTION_ENABLED: {
          const countrySelectActiveResult = parseInt(value, 10) === 1;
          if (isCountrySelectActive === countrySelectActiveResult) {
            break;
          }
          setIsCountrySelectActive(countrySelectActiveResult);
          break;
        }
        case siteSetting.DEFAULT_HERO_BANNER: {
          const defaultHeroBanner = JSON.parse(value);
          setDefaultHeroBanner(defaultHeroBanner);
          break;
        }
        case siteSetting.CAROUSEL_SLIDE_SPEED: {
          const carouselSpeed = parseInt(value, 10);
          setCarouselSpeed(carouselSpeed);
          break;
        }
        case siteSetting.INTRO_HERO_BANNER: {
          const introHeroBanner = JSON.parse(value);
          setIntroHeroBanner(introHeroBanner);
          break;
        }
        case siteSetting.TIKTOK_LINK: {
          setTiktokLink(value);
          break;
        }
        case siteSetting.INSTAGRAM_LINK: {
          setInstagramLink(value);
          break;
        }
        case siteSetting.FACEBOOK_LINK: {
          setFacebookLink(value);
          break;
        }
        case siteSetting.IS_FOOTER_MENU_NEWSROOM_ENABLED:
        case siteSetting.IS_FOOTER_MENU_FAQ_ENABLED:
        case siteSetting.IS_FOOTER_MENU_PRIVACY_POLICY_ENABLED:
        case siteSetting.IS_FOOTER_MENU_TERMS_ENABLED:
        case siteSetting.IS_FOOTER_MENU_EVENT_TERMS_ENABLED:
        case siteSetting.IS_FOOTER_MENU_ACCESSIBILITY_ENABLED:
        case siteSetting.IS_FOOTER_MENU_CONTACT_ENABLED:
        case siteSetting.IS_FOOTER_MENU_ABOUT_US_ENABLED:
        case siteSetting.IS_FOOTER_MENU_CAREERS_ENABLED:
        case siteSetting.IS_FOOTER_MENU_CORPORATE_SALES_ENABLED:
        case siteSetting.IS_FOOTER_MENU_DONATIONS_ENABLED:
        case siteSetting.IS_FOOTER_MENU_ALLERGEN_ENABLED:
        case siteSetting.IS_FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_ENABLED:
        case siteSetting.IS_FOOTER_MENU_MERCH_ENABLED: {
          footerMenuItemsSettings[name] = Number(value) === 1;
          break;
        }
        case siteSetting.FOOTER_MENU_NEWSROOM_LINK:
        case siteSetting.FOOTER_MENU_FAQ_LINK:
        case siteSetting.FOOTER_MENU_PRIVACY_POLICY_LINK:
        case siteSetting.FOOTER_MENU_TERMS_LINK:
        case siteSetting.FOOTER_MENU_EVENT_TERMS_LINK:
        case siteSetting.FOOTER_MENU_ACCESSIBILITY_LINK:
        case siteSetting.FOOTER_MENU_CONTACT_LINK:
        case siteSetting.FOOTER_MENU_ABOUT_US_LINK:
        case siteSetting.FOOTER_MENU_CAREERS_LINK:
        case siteSetting.FOOTER_MENU_CORPORATE_SALES_LINK:
        case siteSetting.FOOTER_MENU_DONATIONS_LINK:
        case siteSetting.FOOTER_MENU_ALLERGEN_LINK:
        case siteSetting.FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_LINK:
        case siteSetting.FOOTER_MENU_MERCH_LINK: {
          if ((value || "").trim().indexOf("http") === -1) {
            footerMenuItemsLinks[name] = value;
          } else {
            footerMenuItemsLinks[name] = value;
          }
          break;
        }
        case siteSetting.TIPS_TYPE: {
          tipsSettings[siteSetting.TIPS_TYPE] = value;
          break;
        }
        case siteSetting.TIPS_VALUES: {
          tipsSettings[siteSetting.TIPS_VALUES] = JSON.parse(value);
          break;
        }
        case siteSetting.DISABLE_GIFTCARDS: {
          setIsGiftcardDisabled(value);
          break;
        }
        case siteSetting.ORDER_TOTALS_TITLES: {
          setOrderTotalsTitles(value);
          break;
        }
        case siteSetting.SHOW_TAXES_AFTER_TOTAL: {
          setShowTaxesAfterTotal(Number(value) === 1);
          break;
        }
        case siteSetting.MAP_DEFAULT_COORDINATES: {
          const defaultMapCoordinates = JSON.parse(value);
          setDefaultMapCoordinates(defaultMapCoordinates);
          break;
        }
        case siteSetting.USE_FULLY_QUALIFIED_ADDRESS_FOR_DELIVERY: {
          const useFullyQualifiedAddressForDelivery = Number(value) === 1;
          setUseFullyQualifiedAddressForDelivery(
            useFullyQualifiedAddressForDelivery
          );
          break;
        }
        case siteSetting.IS_CONTACT_US_FEEDBACK_ENABLE:
        case siteSetting.IS_CONTACT_US_GENERAL_ENABLE:
        case siteSetting.IS_CONTACT_US_EVENTS_ENABLE:
        case siteSetting.IS_CONTACT_US_REAL_ESTATE_ENABLE:
        case siteSetting.IS_CONTACT_US_VENDOR_ENABLE:
        case siteSetting.IS_CONTACT_US_PRESS_ENABLE:
        case siteSetting.IS_CONTACT_US_MERCH_ENABLE:
        case siteSetting.CONTACT_US_FEEDBACK_EMAILS:
        case siteSetting.CONTACT_US_GENERAL_EMAILS:
        case siteSetting.CONTACT_US_EVENTS_EMAILS:
        case siteSetting.CONTACT_US_REAL_ESTATE_EMAILS:
        case siteSetting.CONTACT_US_VENDOR_EMAILS:
        case siteSetting.CONTACT_US_PRESS_EMAILS: {
          if ((value || "").trim().indexOf("@") === -1) {
            contactUsSetting[name] = value;
          } else {
            contactUsSetting[name] = value;
          }
          break;
        }
        case siteSetting.IS_THEME_SWITCH_ENABLED: {
          const themeActive = parseInt(value, 10);
          themeSettings[siteSetting.IS_THEME_SWITCH_ENABLED] = themeActive === 1;
          setThemeSettings(themeSettings);
          break;
        }
        case siteSetting.PASSWORDLESS_ENABLE: {
          setPasswordlessEnable(!!+value);
          break;
        }
        case siteSetting.ACTIVE_LOYALTY: {
          setActiveLoyaltySetting(value);
          break;
        }
        case siteSetting.REFERRAL_ENABLED: {
          const referralEnabled = parseInt(value, 10) === 1;
          setReferralEnabledSetting(referralEnabled);
          break;
        }
        case siteSetting.USE_RECAPTCHA: {
          const useRecaptchaJson = JSON.parse(value);
          const useRecaptcha = parseInt(
            useRecaptchaJson[elementConstant.consumerPlatform],
            10
          ) ?? 0;
          setUseRecaptcha(useRecaptcha);
          break;
        }
        default: {
          break;
        }
      }
    });
    setFooterMenuItemsSettings(footerMenuItemsSettings);
    setFooterMenuItemsLinksSettings(footerMenuItemsLinks);
    setTipsSettings(tipsSettings);
    setContactUsSettings(contactUsSetting);
    setThemeSettings(themeSettings);
    return specificSettings;
  };

  getSiteSettings = () => {
    const { errorMessage } = this.props;
    return new Promise((resolve, reject) => {
      if (!errorMessage) {
        siteSettingRepo
          .getSiteSettings([
            siteSetting.COOKIE_MAGIC_ACTIVE,
            siteSetting.SHIPPING_ACTIVE,
            siteSetting.SHOW_CALORIES,
            siteSetting.COUNTRY_SELECTION_ENABLED,
            siteSetting.BRAZE_APP_ID_WEB,
            siteSetting.CONTENT_CARD_REFRESH_DELAY,
            siteSetting.DEFAULT_HERO_BANNER,
            siteSetting.CAROUSEL_SLIDE_SPEED,
            siteSetting.INTRO_HERO_BANNER,
            siteSetting.IS_FOOTER_MENU_NEWSROOM_ENABLED,
            siteSetting.IS_FOOTER_MENU_FAQ_ENABLED,
            siteSetting.IS_FOOTER_MENU_PRIVACY_POLICY_ENABLED,
            siteSetting.IS_FOOTER_MENU_TERMS_ENABLED,
            siteSetting.IS_FOOTER_MENU_EVENT_TERMS_ENABLED,
            siteSetting.IS_FOOTER_MENU_ACCESSIBILITY_ENABLED,
            siteSetting.IS_FOOTER_MENU_CONTACT_ENABLED,
            siteSetting.IS_FOOTER_MENU_ABOUT_US_ENABLED,
            siteSetting.IS_FOOTER_MENU_CAREERS_ENABLED,
            siteSetting.IS_FOOTER_MENU_CORPORATE_SALES_ENABLED,
            siteSetting.IS_FOOTER_MENU_DONATIONS_ENABLED,
            siteSetting.IS_FOOTER_MENU_MERCH_ENABLED,
            siteSetting.IS_FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_ENABLED,
            siteSetting.IS_FOOTER_MENU_ALLERGEN_ENABLED,
            siteSetting.FOOTER_MENU_NEWSROOM_LINK,
            siteSetting.FOOTER_MENU_FAQ_LINK,
            siteSetting.FOOTER_MENU_PRIVACY_POLICY_LINK,
            siteSetting.FOOTER_MENU_TERMS_LINK,
            siteSetting.FOOTER_MENU_EVENT_TERMS_LINK,
            siteSetting.FOOTER_MENU_ACCESSIBILITY_LINK,
            siteSetting.FOOTER_MENU_CONTACT_LINK,
            siteSetting.FOOTER_MENU_ABOUT_US_LINK,
            siteSetting.FOOTER_MENU_CAREERS_LINK,
            siteSetting.FOOTER_MENU_CORPORATE_SALES_LINK,
            siteSetting.FOOTER_MENU_DONATIONS_LINK,
            siteSetting.FOOTER_MENU_MERCH_LINK,
            siteSetting.FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_LINK,
            siteSetting.FOOTER_MENU_ALLERGEN_LINK,
            siteSetting.TIKTOK_LINK,
            siteSetting.INSTAGRAM_LINK,
            siteSetting.FACEBOOK_LINK,
            siteSetting.TIPS_TYPE,
            siteSetting.TIPS_VALUES,
            siteSetting.DISABLE_GIFTCARDS,
            siteSetting.DATADOG_RUM_ENABLED,
            siteSetting.ORDER_TOTALS_TITLES,
            siteSetting.SHOW_TAXES_AFTER_TOTAL,
            siteSetting.MAP_DEFAULT_COORDINATES,
            siteSetting.ONETRUST_SCRIPT_ID,
            siteSetting.DISABLE_SAVE_CARD,
            siteSetting.IS_CONTACT_US_FEEDBACK_ENABLE,
            siteSetting.IS_CONTACT_US_GENERAL_ENABLE,
            siteSetting.IS_CONTACT_US_EVENTS_ENABLE,
            siteSetting.IS_CONTACT_US_REAL_ESTATE_ENABLE,
            siteSetting.IS_CONTACT_US_VENDOR_ENABLE,
            siteSetting.IS_CONTACT_US_PRESS_ENABLE,
            siteSetting.IS_CONTACT_US_MERCH_ENABLE,
            siteSetting.CONTACT_US_FEEDBACK_EMAILS,
            siteSetting.CONTACT_US_GENERAL_EMAILS,
            siteSetting.CONTACT_US_EVENTS_EMAILS,
            siteSetting.CONTACT_US_REAL_ESTATE_EMAILS,
            siteSetting.CONTACT_US_VENDOR_EMAILS,
            siteSetting.CONTACT_US_PRESS_EMAILS,
            siteSetting.USE_FULLY_QUALIFIED_ADDRESS_FOR_DELIVERY,
            siteSetting.IS_THEME_SWITCH_ENABLED,
            siteSetting.PASSWORDLESS_ENABLE,
            siteSetting.ACTIVE_LOYALTY,
            siteSetting.REFERRAL_ENABLED,
            siteSetting.USE_RECAPTCHA,
          ], true)
          .then((response) => {
            if (!response || !response.data || !response.data.siteSettings) {
              reject(response);
              return;
            }
            resolve(response);
          })
          .catch(reject);
      } else {
        reject();
      }
    });
  };

  transformLocation = currentLocation => {
    const { isCountrySelectActive, selectedCountry, history } = this.props;
    // Adding country url to prefix
    // If we have selected country and have no path_url in current path so add it

    if (
      isCountrySelectActive &&
      selectedCountry.path_url &&
      !isHasCountryPathInLocation(
        currentLocation.pathname,
        selectedCountry.path_url
      )
    ) {
      const tmp = removeCountryPathFromLocation(currentLocation.pathname);
      const newLocation = addCountryPathToLocation(
        tmp,
        selectedCountry.path_url,
        isCountrySelectActive
      );
      if (
        window.location.pathname !== newLocation ||
        window.location.search !== currentLocation.search
      ) {
        history.replace({
          pathname: newLocation,
          search: currentLocation.search,
          state: currentLocation.state
        });
        return null;
      }
    }
    const path = currentLocation;
    // Removing path url from pathname for backward compatibility. To not add selected country path url to all links of system
    if (
      isCountrySelectActive &&
      selectedCountry.path_url &&
      isHasCountryPathInLocation(
        currentLocation.pathname,
        selectedCountry.path_url
      )
    ) {
      path.pathname = getLocationWithoutCountryPath(
        currentLocation.pathname,
        selectedCountry.path_url
      );
    }

    return path;
  };

  handleCheckout = async e => {
    const {
      userCart,
      history,
      userCartId,
      userOrderMethod,
      orderMinAmount,
      currency,
      setOrderMinAmount
    } = this.props;
    e.preventDefault();
    let minAmount = orderMinAmount;
    let isMinAmount = false;
    const cartIconLink = endpoints.checkoutOrderPage.replace(":id", userCartId);

    if (userCart.length > 0 && userOrderMethod === orderMethods.delivery) {
      if (!minAmount) {
        const order = await orderRepo.getOrder(userCartId);
        setOrderMinAmount(order?.data?.order?.shippingMethodMinAmount);
        minAmount = order?.data?.order?.shippingMethodMinAmount;
      }

      const totalPrice = userCart
        .map(item => item.quantity * item.price)
        .reduce((a, b) => a + b, 0);
      if (totalPrice < minAmount) {
        checkoutOrderMinAmount(minAmount, currency.symbol);
        isMinAmount = true;
      }
    }
    if (!isMinAmount) {
      history.push(routeCountryPath(cartIconLink));
    }
  };

  render = () => {
    const { isLoading } = this.state;
    const {
      showMobileMenu,
      modalObject,
      errorMessage,
      location,
      topHeaderOffsetDivHeight,
      userCart,
      currency,
      passwordlessEnabled
    } = this.props;

    const currentLocation = location;
    if (isLoading === false) {
      const newLocation = this.transformLocation(currentLocation);
      if (newLocation === null) {
        return null;
      }
      const { pathname } = newLocation;
      const isCheckoutPage = pathname.match(/checkout\/review-order/g);
      const isProductPage = pathname.match(/products\//g);

      if (showMobileMenu) return <MobileMenu location={pathname} />;
      return (
        <div className={styles.pageContainerContent}>
          {modalObject ? <Modal {...modalObject} /> : null}
          {" "}
          {/* eslint-disable-line */}
          {/* GLOBAL BACKGROUND RENDERING */}
          {/* {renderBackground(pathname)} */}
          {/* GLOBAL HEADER RENDERING */}
          {renderGlobalHeader(pathname, topHeaderOffsetDivHeight, errorMessage)}
          {/* MAIN ROUTES */}
          <div className={`w-full flex flex-col gap-9 `}>
            {errorMessage ? (
              <Error errorType="400" />
            ) : (
              <Switch location={{...currentLocation, passwordlessEnabled}}>
                {/* User Routes */}
                <Route path="/user" component={UserRoutes} />
                {/* Product Routes */}
                <Route path="/products" component={ProductRoutes} />
                {/* Checkout Routes */}
                <Route path="/checkout" component={CheckoutRoutes} />
                {/* FALLBACK 404 PATH */}
                <Route path="/404" component={NoMatch} />
                {/* Index Routes */}
                <Route path="/" component={IndexRoutes} />
                {/* CATCH ALL - REDIRECT TO 404 */}
                <Redirect to="404" />
              </Switch>
            )}

            {userCart.length > 0 && !isCheckoutPage && !isProductPage ? (
              <div className={`${styles.floatButtonContainer}`}>
                <CheckoutButton
                  label="Checkout"
                  currency={currency}
                  onClick={this.handleCheckout}
                  isCheckout
                />
              </div>
            ) : null}
          </div>
          {renderGlobalFooter(pathname)}
        </div>
      );
    }
    return (
      <div>
        <div className={styles.loaderBlock}>
          <Loader />
        </div>
      </div>
    );
  };
}

PageContainer.propTypes = {
  showMobileMenu: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  modalObject: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  isCountrySelectActive: PropTypes.bool,
  selectedCountry: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    path_url: PropTypes.string,
    api_url: PropTypes.string,
    short_name: PropTypes.string,
    url: PropTypes.string,
    zone_id: PropTypes.number,
    currency: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      symbol: PropTypes.string,
    }),
  }),
  setSelectedCountry: PropTypes.func.isRequired,
  setUserZoneId: PropTypes.func.isRequired,
  setAllCountries: PropTypes.func.isRequired,
  setIsCountrySelectActive: PropTypes.func.isRequired,
  setIsDoughProActive: PropTypes.func.isRequired,
  setIsCaloriesActive: PropTypes.func.isRequired,
  setIsShippingActive: PropTypes.func.isRequired,
  resetUserData: PropTypes.func.isRequired,
  resetCustomCakeTopperData: PropTypes.func.isRequired,
  resetCurrencyData: PropTypes.func.isRequired,
  isDoughProActive: PropTypes.bool,
  isShippingActive: PropTypes.bool,
  isCaloriesActive: PropTypes.bool,
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  topHeaderOffsetDivHeight: PropTypes.number.isRequired,
  introBannerVisibilityUpdated: PropTypes.func.isRequired,
  shouldDisplayIntroBanner: PropTypes.bool,
  setDefaultHeroBanner: PropTypes.func.isRequired,
  setCarouselSpeed: PropTypes.func.isRequired,
  setIntroHeroBanner: PropTypes.func.isRequired,
  setContentCardRefreshDelay: PropTypes.func.isRequired,
  setFooterMenuItemsSettings: PropTypes.func.isRequired,
  setFooterMenuItemsLinksSettings: PropTypes.func.isRequired,
  setTiktokLink: PropTypes.func.isRequired,
  setInstagramLink: PropTypes.func.isRequired,
  setFacebookLink: PropTypes.func.isRequired,
  setTipsSettings: PropTypes.func.isRequired,
  setIsGiftcardDisabled: PropTypes.func.isRequired,
  setOrderTotalsTitles: PropTypes.func.isRequired,
  setShowTaxesAfterTotal: PropTypes.func.isRequired,
  setDefaultMapCoordinates: PropTypes.func.isRequired,
  currency: PropTypes.objectOf(PropTypes.string),
  userCartId: PropTypes.string,
  userCart: PropTypes.shape({
    map: PropTypes.func,
    length: PropTypes.func,
  }),
  setContactUsSettings: PropTypes.func.isRequired,
  userOrderMethod: PropTypes.string,
  setOrderMinAmount: PropTypes.func.isRequired,
  orderMinAmount: PropTypes.number,
  setUseFullyQualifiedAddressForDelivery: PropTypes.func.isRequired,
  setThemeSettings: PropTypes.func.isRequired,
  setPasswordlessEnable: PropTypes.func.isRequired,
  passwordlessEnabled: PropTypes.bool,
  setActiveLoyaltySetting: PropTypes.func.isRequired,
  setReferralEnabledSetting: PropTypes.func.isRequired,
  setUseRecaptcha: PropTypes.func.isRequired,
};

PageContainer.defaultProps = {
  modalObject: null,
  errorMessage: '',
  isCountrySelectActive: false,
  selectedCountry: defaultCountry,
  isDoughProActive: false,
  isShippingActive: false,
  isCaloriesActive: false,
  shouldDisplayIntroBanner: true,
  currency: defaultCurrency,
  userCartId: null,
  userCart: [],
  userOrderMethod: '',
  orderMinAmount: null,
  passwordlessEnabled: null
};

export const mapStateToProps = (state) => {
  const { modalObject, topHeaderOffsetDivHeight,  orderMinAmount } = state.elements;
  const { isDoughProActive } = state.doughPro;
  const { isShippingActive } = state.shipping;
  const { isCaloriesActive } = state.product;
  const {
    selectedCountry,
    allCountries,
    isCountrySelectActive,
  } = state.country;

  const {
    shouldDisplayIntroBanner
  } = state.braze;
  const {passwordlessEnabled} = state.footerSettings
  return {
    modalObject,
    selectedCountry,
    allCountries,
    isCountrySelectActive,
    isDoughProActive,
    isShippingActive,
    isCaloriesActive,
    topHeaderOffsetDivHeight,
    shouldDisplayIntroBanner,
    userCart: state.user.userCart,
    userCartId: state.user.userCartId,
    userOrderMethod: state.user.userOrderMethod,
    orderMinAmount,
    passwordlessEnabled,
  };
};

export const mapDispatchToProps = (dispatch) => {
  const setAllCountries = (value) =>
    dispatch(countryActions.setAllCountries(value));
  const setSelectedCountry = (value) =>
    dispatch(countryActions.setSelectedCountry(value));
  const setIsDoughProActive = (value) =>
    dispatch(doughProActions.setIsDoughProActive(value));
  const setIsShippingActive = (value) =>
    dispatch(shippingActions.setIsShippingActive(value));
  const setIsCaloriesActive = (value) =>
    dispatch(productActions.setIsCaloriesActive(value));
  const setIsCountrySelectActive = (value) =>
    dispatch(countryActions.setIsCountrySelectActive(value));

  const introBannerVisibilityUpdated = (value) =>
    dispatch(brazeActions.introBannerVisibilityUpdated(value));

  const setDefaultHeroBanner = (value) =>
    dispatch(brazeActions.setDefaultHeroBanner(value));

  const setCarouselSpeed = (value) =>
    dispatch(elementsActions.setCarouselSpeed(value));

  const setIntroHeroBanner = (value) =>
    dispatch(brazeActions.setIntroHeroBanner(value));

  const setContentCardRefreshDelay = (value) =>
    dispatch(brazeActions.setContentCardRefreshDelay(value));

  const setTiktokLink = (value) =>
    dispatch(footerSettingsActions.setTiktokLink(value));

  const setInstagramLink = (value) =>
    dispatch(footerSettingsActions.setInstagramLink(value));

  const setFacebookLink = (value) =>
    dispatch(footerSettingsActions.setFacebookLink(value));

  const setFooterMenuItemsSettings = (value) =>
    dispatch(footerSettingsActions.setFooterMenuItemsSettings(value));
  const setFooterMenuItemsLinksSettings = (value) =>
    dispatch(footerSettingsActions.setFooterMenuItemsLinksSettings(value));
  const setTipsSettings = (value) =>
    dispatch(tipsActions.setTipsSettings(value));

  const setIsGiftcardDisabled = (value) =>
    dispatch(giftCardActions.setIsGiftcardDisabled(value));

  const setOrderTotalsTitles = (value) =>
    dispatch(orderTotalsTitles.setOrderTotalsTitles(value));

  const setShowTaxesAfterTotal = (value) =>
    dispatch(orderTotalsTitles.setShowTaxesAfterTotal(value));

  const setDefaultMapCoordinates = (value) =>
    dispatch(elementsActions.setDefaultMapCoordinates(value));
  const setContactUsSettings = (value) =>
    dispatch(contactUsSettingActions.setContactUsSettings(value));

  const setUserZoneId = (value) => dispatch(userActions.setUserZoneId(value));

  const resetUserData = () => dispatch(userActions.resetUserData());
  const resetCustomCakeTopperData = () => dispatch(customCakeTopperActions.resetCustomCakeTopperData());
  const resetCurrencyData = () => dispatch(currencyActions.resetCurrencyData());

  const setOrderMinAmount = (value) => dispatch(elementsActions.setOrderMinAmount(value));

  const setUseFullyQualifiedAddressForDelivery = (value) => dispatch(addressActions.setUseFullyQualifiedAddressForDelivery(value));

  const setThemeSettings = (value) => dispatch(themeActions.setThemeSettings(value));

  const setPasswordlessEnable = (value) => dispatch(footerSettingsActions.setPasswordlessEnable(value));
  const setActiveLoyaltySetting = (value) =>
    dispatch(loyaltyActions.setActiveLoyaltySetting(value));
  const setReferralEnabledSetting = (value) =>
    dispatch(loyaltyActions.setReferralEnabledSetting(value));
  const setUseRecaptcha = (value) => dispatch(elementsActions.setUseRecaptcha(value));
  return {
    setAllCountries,
    setSelectedCountry,
    setIsCountrySelectActive,
    setIsDoughProActive,
    setIsShippingActive,
    setIsCaloriesActive,
    introBannerVisibilityUpdated,
    setDefaultHeroBanner,
    setCarouselSpeed,
    setIntroHeroBanner,
    setContentCardRefreshDelay,
    setTiktokLink,
    setInstagramLink,
    setFacebookLink,
    setFooterMenuItemsSettings,
    setFooterMenuItemsLinksSettings,
    setTipsSettings,
    setIsGiftcardDisabled,
    setOrderTotalsTitles,
    setShowTaxesAfterTotal,
    setDefaultMapCoordinates,
    setUserZoneId,
    resetUserData,
    resetCustomCakeTopperData,
    resetCurrencyData,
    setContactUsSettings,
    setOrderMinAmount,
    setUseFullyQualifiedAddressForDelivery,
    setThemeSettings,
    setPasswordlessEnable,
    setActiveLoyaltySetting,
    setReferralEnabledSetting,
    setUseRecaptcha
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageContainer));
