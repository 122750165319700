import React from "react";
import PropTypes from "prop-types";

import Link from "../../components/LinkWithCountry/LinkWithCountry";
import styles from "./TopHeader.module.css";

const logo = require("./imgs/logo.svg");
const mobileLogo = require("./imgs/mobile_logo.svg");

function TopHeader({ contents }) {
  return (
    <div
      className={`${styles.topContainer} flex md:flex-row flex-col-reverse 
        h-[457px] md:h-[380px]
        justify-center md:justify-between px-[100px] items-center mx-auto`}
    >
      <div className="text-white max-h-[212px] w-[317px] flex flex-col gap-2 items-center md:text-left text-center md:items-baseline mb-2 md:mb-0">
        <h1
          className="font-congenialBlack text-4xl md:text-[44px] leading-9 md:leading-10 tracking-[-1px]"
        >
          {contents?.heading}
        </h1>
        <p
          className="font-filsonProRegular leading-[22px] tracking-[-0.1] text-lg"
        >
          {contents?.description}
        </p>
        {contents?.link && (
          <Link to={contents?.link?.href}>
            <button
              type="button"
              className="
          bg-button text-lg leading-[22px] tracking-[-0.1px] 
          font-filsonProBold text-white h-[52px] w-[245px] md:w-[254px] rounded-[40px] px-5 py-[15px]"
            >
              {contents?.link?.title}
            </button>
          </Link>
        )}
      </div>
      <div className="w-[195px] h-[230px] md:w-[324px]  md:h-[278px] flex items-center justify-center">
        <img
          src={logo}
          alt="Insomnia Rewards Logo"
          className="md:block hidden"
        />
        <img
          src={mobileLogo}
          alt="Insomnia Rewards Logo"
          className="md:hidden block"
        />
      </div>
    </div>
  );
}

TopHeader.propTypes = {
  contents: PropTypes.shape({}).isRequired
};

export default TopHeader;
