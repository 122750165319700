export default {
    subscriptionType: {
        optIn: 1,
        orderUpdates: 2,
        consent: 3,
    },
    titleLabel: 'Opt-in to Receive Updates on the Latest News and Offers',
    subscriptionKeyField: {
        EMAIL_OPT_IN: 'email',
        SMS_OPT_IN: 'phone',
        ORDER_NOTIFICATION: 'push_order_updates_enabled',
        PROMO_NOTIFICATION: 'push_promos_enabled',
    },
}
