/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import Link from "../../components/LinkWithCountry/LinkWithCountry";

import * as userRepo from "../../shared/repos/graphql/user";
import * as schoolRepo from "../../shared/repos/graphql/school";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";

import { handleFormInput } from "../../shared/utilities/validations";
import {
  checkPath,
  getErrorMessages,
  getValidationErrorMessages,
  formatValidationMessage,
  routeCountryPath
} from "../../shared/utilities/common";

import styles from "./AccountForm.module.css";
import BirthdayWidget from "../../components/BirthdayWidget/BirthdayWidget";
import * as userActions from "../../redux/actions/user";

import customerMessages from "../../shared/constants/customer";
import PhoneInputWidget from "../../components/PhoneInputWidget/PhoneInputWidget";
import messages from "../../shared/constants/messages";
import DropDown from "../../components/Dropdown/Dropdown";
import InputSearchBox from "../../components/InputSearchBox/InputSearchBox";

const backIcon = require("./imgs/back-icon.svg");
const lightBackIcon = require("./imgs/light-back-icon.svg");

class CreateAccountOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      phoneTelephone: "",
      prefixTelephone: "",
      birthday: {},
      cards: null,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      errorMessage: null,
      birthdayErrorMessage: null,
      getUserLoading: false,
      updateUserLoading: false,
      passwordChangeLoading: false,
      cardRemoveLoading: false,
      cardErrorMessage: null,
      passwordChangeErrorMessage: null,
      phoneNumberErrorMessage: null,
      passwordUpdatedMessage: "",
      selectedGraduationYear: {
        label: "",
        value: ""
      },
      selectedSchool: {
        label: "",
        value: ""
      },
      studentStatus: null,
      schoolsList: []
    };
  }

  componentDidMount() {
    this.getUser();
    this.getSchools();
  }

  renderInputItem = (
    label,
    state,
    value,
    errorMessage,
    isDark,
    type = "text",
    disabled = false
  ) => {
    const customContainerStyles = [
      isDark ? styles.inputWrapper : styles.customInputWrapperLight
    ].join(" ");
    const customInputContainerStyles = disabled
      ? styles.accountFormDisabled
      : "";
    const inputValue = this.state[state]; //eslint-disable-line

    return (
      <Input
        handleInput={e => handleFormInput(e, this, state)}
        customContainerStyles={customContainerStyles}
        customInputContainerStyles={customInputContainerStyles}
        placeholder=" "
        type={type}
        disabled={disabled}
        isEdit
        errorMessage={errorMessage}
        label={label}
        value={inputValue}
      />
    );
  };

  isEmpty = state => {
    if (state === null) {
      return false;
    }

    return state.length === 0;
  };

  getUser = async () => {
    this.setState({
      getUserLoading: true
    });

    try {
      const response = await userRepo.getUser();

      const { me } = response.data;

      if (me) {
        let birthday = {
          month: null,
          day: null
        };
        if (me.birthday) {
          const birthdayArray = me.birthday.split("-");
          const birthYear =
            birthdayArray[0] && birthdayArray[0] !== "0000"
              ? birthdayArray[0]
              : "";
          birthday = {
            year: birthYear,
            month: birthdayArray[1],
            day: birthdayArray[2]
          };
        }
        this.setBirthday(birthday);

        this.setState({
          firstName: me.firstname,
          lastName: me.lastname,
          email: me.email,
          telephone: me.telephone,
          cards: me.customerCards,
          phoneTelephone: me.phoneTelephone,
          prefixTelephone: me.prefixTelephone,
          me,
          studentStatus: me.isStudent,
          selectedSchool: {
            label: me.schoolName,
            value: me.schoolId
          },
          selectedGraduationYear: {
            label: me.graduationYear,
            value: me.graduationYear
          }
        });
      }
    } catch (exception) {
      const { message } = exception.graphQLErrors[0];

      this.setState({
        errorMessage: message
      });
    }

    this.setState({
      getUserLoading: false
    });
  };

  updateUser = async () => {
    const {
      firstName,
      lastName,
      email,
      telephone,
      phoneTelephone,
      prefixTelephone,
      birthday,
      phoneNumberErrorMessage,
      selectedGraduationYear,
      selectedSchool,
      studentStatus
    } = this.state;
    const { history, setUserInfo } = this.props;
    this.setState({
      errorMessage: null,
      birthdayErrorMessage: null
    });

    if (!prefixTelephone && phoneTelephone) {
      this.setState({
        phoneNumberErrorMessage: "Please select phone country code"
      });
      return;
    }

    if (phoneNumberErrorMessage !== null) {
      return;
    }

    if (birthday && !birthday.month && birthday.day) {
      this.setState({
        birthdayErrorMessage: "birthday month is required"
      });
      return;
    }
    if (birthday && birthday.month && !birthday.day) {
      this.setState({
        birthdayErrorMessage: "birthday day is required"
      });
      return;
    }
    if (birthday && birthday.month && birthday.day && !birthday.year) {
      this.setState({
        birthdayErrorMessage: "birthday year is required"
      });
      return;
    }

    if (studentStatus) {
      if (!selectedSchool.value) {
        this.setState({
          studentInfoError: "School name is required"
        });
        return;
      }

      if (!selectedGraduationYear.value) {
        this.setState({
          studentInfoError: "Graduation year is required"
        });
        return;
      }
    }

    this.setState({
      updateUserLoading: true
    });

    const userUpdateData = {
      firstName,
      lastName,
      email,
      telephone,
      phoneTelephone,
      prefixTelephone,
      isStudent: studentStatus,
      schoolId: studentStatus ? parseInt(selectedSchool.value, 10) : null,
      graduationYear: studentStatus ? selectedGraduationYear.value : null
    };

    const updatedBirthday = birthday && birthday.year && birthday.month && birthday.day? `${  birthday.year  }-${  birthday.month  }-${  birthday.day}` : null;
    if (updatedBirthday) {
      userUpdateData.birthday = updatedBirthday;
    }

    try {
      await userRepo.updateUser(userUpdateData);
      const user = {
        firstName,
        lastName
      };
      setUserInfo({ user });

      const passwordValidated = await this.updatePassword();

      if (passwordValidated) {
        history.push(routeCountryPath("/user/profile"));
      }
    } catch (errors) {
      const { extensions, message } = errors.graphQLErrors[0];
      const { validation, reason } = extensions;
      if (validation) {
        const errorMsg = validation[Object.keys(validation)[0]][0];

        this.setState({
          updateUserLoading: false,
          errorMessage: errorMsg
        });
      } else if (reason === 'CUSTOMER_BIRTHDAY_UPDATE_FAILED'){
          this.setState({
            updateUserLoading: false,
            birthdayErrorMessage: message || 'Error while process request. Please try again later.'
          });
        } else {
          this.setState({
            updateUserLoading: false,
            errorMessage: message || 'Error while process request. Please try again later.',
          });
        }
    }

    this.setState({
      updateUserLoading: false
    });
  };

  setBirthday = birthday => {
    this.setState({
      birthday
    });
  };

  onPhoneBlur = phoneData => {
    if (phoneData.maskValidation === true) {
      this.setState({
        phoneNumberErrorMessage: null
      });
    } else {
      this.setState({
        phoneNumberErrorMessage: messages.phoneInput.maskValidation.replace(
          "%s",
          phoneData.mask
        )
      });
    }
  };

  validatePassword = () => {
    const { newPassword, confirmPassword } = this.state;
    let valid = true;

    this.setState({ newPasswordErrorMessage: "" });
    if (confirmPassword !== newPassword) {
      valid = false;
      this.setState({
        confirmPasswordErrorMessage: customerMessages.passwordMatch
      });
    } else {
      this.setState({ confirmPasswordErrorMessage: "" });
    }

    return valid;
  };

  updatePassword = async () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    const validatePassword = this.validatePassword();
    let passwordUpdated = validatePassword;
    if (validatePassword && newPassword && confirmPassword) {
      const data = { currentPassword, newPassword, confirmPassword };
      try {
        this.setState({ passwordChangeLoading: true });
        const changeCustomerPassword = await userRepo.changeCustomerPassword(
          data
        );
        const result = changeCustomerPassword?.data;
        this.setState({
          passwordChangeLoading: false,
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
          changePasswordErrorMessage: null,
          passwordUpdatedMessage:
            result && result.changeCustomerPassword
              ? customerMessages.passwordUpdatedMessage
              : null
        });
        passwordUpdated = true;
      } catch (e) {
        const errors = getValidationErrorMessages(e);

        const message = [];
        if (errors) {
          let errorMessage = formatValidationMessage(
            errors,
            "data.currentPassword"
          );
          if (errorMessage) {
            message.push(errorMessage);
          }

          errorMessage = formatValidationMessage(errors, "data.newPassword");
          if (errorMessage) {
            message.push(errorMessage);
          }

          errorMessage = formatValidationMessage(
            errors,
            "data.confirmPassword"
          );
          if (errorMessage) {
            message.push(errorMessage);
          }

          errorMessage = formatValidationMessage(errors, "message");
          if (errorMessage) {
            message.push(errorMessage);
          }
        }
        this.setState({
          changePasswordErrorMessage: message,
          passwordChangeLoading: false,
          passwordUpdatedMessage: null
        });
        passwordUpdated = false;
      }
    }
    return passwordUpdated;
  };

  removeCard = async card => {
    const { cards } = this.state;
    this.setState({ cardRemoveLoading: true });
    try {
      await userRepo.removeCard(card);

      const withoutRemoveCard = cards.filter(item => item.id !== card);
      this.setState({
        cards: withoutRemoveCard,
        cardRemoveLoading: false
      });
    } catch (reason) {
      const message = getErrorMessages(reason, 0);
      this.setState({
        cardErrorMessage: message,
        cardRemoveLoading: false
      });
    }
  };

  handleStudentStatus = studentStatus => {
    this.setState({
      studentInfoError: '',
      studentStatus
    });
  };

  updateGraduationYear = (value, label) => {
    this.setState({
      studentInfoError: '',
      selectedGraduationYear: {
        label,
        value
      }
    });
  };

  updateSchool = (value, label) => {
    this.setState({
      studentInfoError: '',
      selectedSchool: {
        label,
        value
      }
    });
  };

  getSchools = async () => {
    const response = await schoolRepo.getSchools();
    const { schools } = response.data;

    const schoolsList = schools.map(school => {
      return {
        label: school.name,
        value: school.id
      };
    });

    this.setState({
      schoolsList
    });
  };

  render() {
    const isUpdate = checkPath(this, "/update");
    const {
      errorMessage,
      firstName,
      lastName,
      email,
      phoneTelephone,
      telephone,
      prefixTelephone,
      getUserLoading,
      updateUserLoading,
      birthday,
      birthdayErrorMessage,
      newPassword,
      newPasswordErrorMessage,
      confirmPassword,
      confirmPasswordErrorMessage,
      cards,
      cardRemoveLoading,
      cardErrorMessage,
      changePasswordErrorMessage,
      phoneNumberErrorMessage,
      passwordUpdatedMessage,
      me,
      selectedGraduationYear,
      selectedSchool,
      studentStatus,
      studentInfoError,
      schoolsList
    } = this.state;

    if (getUserLoading) {
      return (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      );
    }
    const yearsOptions = Array.from(
      { length: 10 },
      (_, index) => (new Date().getFullYear()) + index
    );

    const birthdayFieldDisabled = !!(
      me &&
      me.birthday &&
      me.birthday.split("-")[0] !== null &&
      me.birthday.split("-")[0] !== '0000' &&
      me.birthday.split("-")[1] !== null &&
        me.birthday.split("-")[2] !== null
    );

    const telephoneFieldDisabled = !!(
      me &&
      (me.telephone || me.prefixTelephone || me.phoneTelephone)
    );
    const emailFieldDisabled = !!(me && me.email);

    return (
      <div className={`${styles.pageWrapper} text-dark dark:text-white mt-20`}>
        <div className={`${styles.pageContainer} max-w-screen-desktop`}>
          <div className="flex justify-between">
            <h1>
              {' '}
              {`${isUpdate ? "Update " : "Create "}`}
              {' '}
your account
            </h1>
            <Link to="/user/profile">
              <img alt="back" src={backIcon} className="block dark:hidden" />
              <img
                alt="back"
                src={lightBackIcon}
                className="hidden dark:block"
              />
            </Link>
          </div>

          {!isUpdate ? <p>*Indicates required fields</p> : null}

          <span className={styles.error}>{errorMessage}</span>

          <div className={styles.inputsWrapper}>
            <div className={styles.inputsColumn}>
              <h4>Account Information</h4>
              {this.renderInputItem("First name", "firstName", firstName, "")}
              {this.renderInputItem("Last name", "lastName", lastName, "")}
              {this.renderInputItem(
                "Email",
                "email",
                email,
                "",
                null,
                "text",
                emailFieldDisabled
              )}

              <h4 className={styles.hideMobile}>Mobile Number</h4>

              <PhoneInputWidget
                disabled={telephoneFieldDisabled}
                onInput={e =>
                  this.setState({
                    phoneTelephone: e.phone,
                    prefixTelephone: e.code,
                    telephone: e.telephone
                  })}
                handleOnBlur={this.onPhoneBlur}
                label="Mobile Number"
                placeholder=" "
                telephone={telephone}
                code={prefixTelephone}
                phone={phoneTelephone}
                customInputContainerStyles={
                  telephoneFieldDisabled ? styles.accountFormDisabled : ""
                }
                customSelectStyles={styles.selectPhoneCode}
                className={[styles.hideMobile, styles.phoneInputWidget].join(
                  " "
                )}
              />
              {phoneNumberErrorMessage && (
                <p className={styles.error}>{phoneNumberErrorMessage}</p>
              )}

              <div className="flex justify-between gap-2">
                <h4 className={styles.hideMobile}>Birthday</h4>
                {birthdayErrorMessage && (
                  <p className={`${styles.error} ${styles.birthdayError}`}>
                    {birthdayErrorMessage}
                  </p>
                )}
              </div>
              <BirthdayWidget
                header="Birthday"
                subHeader=""
                sectionHeader="Select Birthday"
                birthday={birthday}
                setBirthday={this.setBirthday}
                disabled={birthdayFieldDisabled}
              />

              <div className="flex gap-2 mt-2">
                <h4>Are you currently a Student?</h4>
                <p className="flex gap-2">
                  <input
                    type="radio"
                    name="isStudent"
                    id="studentStatusYes"
                    value="Yes"
                    checked={studentStatus === true}
                    onChange={() => this.handleStudentStatus(true)}
                  />
                  {/* eslint-disable-next-line */}
                  <label htmlFor="studentStatusYes">Yes</label>
                </p>
                <p className="flex gap-2">
                  <input
                    type="radio"
                    name="isStudent"
                    id="studentStatusNo"
                    value="No"
                    checked={studentStatus === false}
                    onChange={() => this.handleStudentStatus(false)}
                  />
                  {/* eslint-disable-next-line */}
                  <label htmlFor="studentStatusYes">No</label>
                </p>
              </div>
              {studentInfoError && (
                <p className={styles.error}>{studentInfoError}</p>
              )}
              {studentStatus && (
                <>
                  <div className="flex justify-between gap-2">
                    <div className="w-full">
                      <InputSearchBox
                        label="Search School Name"
                        items={schoolsList}
                        onSelectItem={this.updateSchool}
                        selectedItem={selectedSchool.label}
                      />
                    </div>

                    <div className="w-full">
                      <DropDown
                        onChange={e =>
                          this.updateGraduationYear(e.value, e.label)}
                        options={yearsOptions}
                        value={selectedGraduationYear}
                        customDropDownStyles={styles.dropDownPlaceholderYear}
                        className="mt-5"
                      />
                    </div>
                  </div>
                </>
              )}

              {isUpdate && (
                <>
                  <h4 className={styles.hideMobile}>Password</h4>
                  <div className={styles.passwordCardContainer}>
                    <div className={styles.confirmationWrapper}>
                      {this.renderInputItem(
                        "New password",
                        "newPassword",
                        newPassword,
                        newPasswordErrorMessage,
                        null,
                        "password"
                      )}
                      {this.renderInputItem(
                        "Confirm password",
                        "confirmPassword",
                        confirmPassword,
                        confirmPasswordErrorMessage,
                        null,
                        "password"
                      )}
                    </div>
                  </div>

                  <p className={styles.error}>
                    {changePasswordErrorMessage &&
                      changePasswordErrorMessage.map(e => {
                        return e;
                      })}
                  </p>
                  <p className={styles.success}>{passwordUpdatedMessage}</p>
                </>
              )}

              <Button
                isLoading={updateUserLoading}
                label={isUpdate ? "Save Changes" : "Create Account"}
                handleSubmit={() => this.updateUser()}
                customContainerStyles={`${styles.createAccountButtonContainer} mt-4`}
              />
            </div>

            <div className={styles.inputsColumn}>
              {isUpdate && (
                <div className={styles.passwordCardContainer}>
                  <div className={styles.cardsContainer}>
                    <h4>Credit cards</h4>
                    {cardRemoveLoading ? (
                      <div className={styles.loaderSecondaryWrapper}>
                        <Loader />
                      </div>
                    ) : cards && cards.length > 0 ? (
                      <ul className={styles.cardsList}>
                        {cards.map(item => {
                          return (
                            <li
                              className={`${styles.listItem} mb-2`}
                              key={item.id}
                            >
                              <p>{`Card ending in ${item.last4} for ${item.store.name}`}</p>
                              <Button
                                label="delete"
                                customContainerStyles={styles.deleteAction}
                                handleSubmit={() => this.removeCard(item.id)}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <p>You do not have any saved credit cards.</p>
                    )}
                    <p className={styles.error}>{cardErrorMessage}</p>
                  </div>
                </div>
              )}

              <h4 className={styles.hideDesktop}>Mobile Number</h4>

              <PhoneInputWidget
                disabled
                customContainerStyles={styles.customInputWrapperLight}
                onInput={e =>
                  this.setState({
                    phoneTelephone: e.phone,
                    prefixTelephone: e.code,
                    telephone: e.telephone
                  })}
                handleOnBlur={this.onPhoneBlur}
                label="Mobile Number"
                placeholder=" "
                telephone={telephone}
                code={prefixTelephone}
                phone={phoneTelephone}
                className={[styles.hideDesktop, styles.phoneInputWidget].join(
                  " "
                )}
              />
              {phoneNumberErrorMessage && (
                <p className={styles.error}>{phoneNumberErrorMessage}</p>
              )}

              {!isUpdate ? (
                <div className={styles.loyaltyCopyContainer}>
                  <p>
                    By creating an account you will be automatically enrolled in
                    the Insomnia Cookies &apos;Cookie Dough&apos; loyalty
                    program.
                  </p>

                  <Link to="/loyalty">Learn More</Link>
                </div>
              ) : null}

              <div
                className={[
                  styles.actionsContainer,
                  isUpdate ? styles.actionsContainerUpdate : null
                ].join(" ")}
              >
                <span className={styles.hideMobile}>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                  &amp;
                  <Link to="/terms">Terms and Conditions</Link>
                </span>

                <span className={styles.hideDesktop}>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                  &amp;
                  <Link to="/terms">Terms and Conditions</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateAccountOrder.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export const mapDispatchToProps = dispatch => ({
  setUserInfo: value => dispatch(userActions.setUserInfo(value))
});

export default withRouter(
  connect(null, mapDispatchToProps)(CreateAccountOrder)
);
