/* eslint-disable import/prefer-default-export */

export const hasActiveOrders = () => ({
  type: 'HAS_ACTIVE_ORDERS',
});

export const noActiveOrders = () => ({
  type: 'NO_ACTIVE_ORDERS',
});

export const toggleMobileMenu = () => ({
  type: 'TOGGLE_MOBILE_MENU',
});

export const toggleHeaderWidget = () => ({
  type: 'TOGGLE_HEADER_WIDGET'
})

export const hideHeaderWidget = () => ({
  type: 'HIDE_HEADER_WIDGET'
})


export const setWidgetOption = value => ({
  type: 'SET_WIDGET_OPTION',
  value
})

export const setWidgetValue = value => ({
  type: 'SET_WIDGET_VALUE',
  value
})

export const setCategoriesPosition = value => ({
  type: 'SET_PRODUCT_POSITION',
  value
})

export const setStickyCategories = value => ({
  type: 'SET_STICKY_CATEGORIES',
  value
})

export const setDeliveryPickupAddressWidget = value => ({
  type: 'SET_DELIVERY_PICKUP_ADDRESS_WIDGET',
  value
})

export const setProductCategory = value => ({
  type: 'SET_PRODUCT_CATEGORY',
  value
})

export const setModalObject = value => ({
  type: 'SET_MODAL_OBJECT',
  value
})

export const setMenuCategories = value => ({
  type: 'SET_MENU_CATEGORIES',
  value
})

export const setProducts = value => (
  {
    type: 'SET_PRODUCTS',
    value
  }
)

export const setDoughProSpecialCategory = value => (
  {
    type: 'SET_DOUGH_PRO_SPECIAL_CATEGORY',
    value
  }
)

export const setAutocompleteSessionToken = value => (
  {
    type: 'SET_AUTOCOMPLETE_SESSION_TOKEN',
    value
  }
)

export const setTopHeaderOffsetDivHeight = value => ({
  type: 'SET_TOP_HEADER_OFFSET_DIV_HEIGHT',
  value
});

export const setCarouselSpeed = value => ({
  type: 'SET_CAROUSEL_SPEED',
  value
});

export const setOrderExperienceRating = value => ({
  type: 'SET_ORDER_EXPERIENCE_RATING',
  value
});

export const setOrderExperienceComment = value => ({
  type: 'SET_ORDER_EXPERIENCE_COMMENT',
  value
});

export const submittedOrderExperience = value => ({
  type: 'SET_ORDER_EXPERIENCE_SUBMITTED',
  value
});

export const setOrderMinAmount = value => ({
  type: 'SET_ORDER_MIN_AMOUNT',
  value
});

export const setDefaultMapCoordinates = value => ({
  type: 'SET_MAP_DEFAULT_COORDINATES',
  value
});

export const setHeroContentCardData = value => ({
  type: 'SET_HERO_CONTENT_CARD_DATA',
  value
});

export const toggleBarcodeCard = (value) => ({
  type: 'TOGGLE_BARCODE_CARD',
  value
});

export const setCloseTimer = value => ({
  type: 'SET_CLOSE_TIMER',
  value
});

export const setVerificationToken = value => ({
  type: 'SET_VERIFICATION_TOKEN',
  value
});

export const setUseRecaptcha = value => ({
  type: 'SET_USE_RECAPTCHA',
  value
});

export const setVerificationAction = value => ({
  type: 'SET_VERIFICATION_ACTION',
  value
});
