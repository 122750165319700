/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from 'react';

import PropTypes from 'prop-types';

// COMPONENTS
import CheckBox from '../CheckBox/CheckBox';
import Button from "../Button/Button";

import styles from './CheckoutContainer.module.css';
import { store } from "../../redux/store";
import Loader from "../Loader/Loader";

const expandIcon = require('./imgs/expand-icon.svg');
const expandLightIcon = require('./imgs/expand-light-icon.svg');
const alertIcon = require('./imgs/alert-icon.svg');

class CheckoutContainer extends Component {
    unsubscribeMounts;

    unsubscribeUpdates;

  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded ?? false,
      isSelected: false
    };
  }

  componentDidMount() {
    this.unsubscribeMounts = store.subscribe(() => {});
  }

  componentDidUpdate() {
    const { expanded } = this.state;

    if (this.unsubscribeUpdates) {
        this.unsubscribeUpdates();
    }

    this.unsubscribeUpdates = store.subscribe(() => {
      if (expanded === true) {
        return 
      }
      this.setState({expanded})
    });
  }

  componentWillUnmount() {
      if (this.unsubscribeMounts) {
          this.unsubscribeMounts();
      }
      if (this.unsubscribeUpdates) {
          this.unsubscribeUpdates();
      }
  }

  /**
   * Toggles isSelected state
   */
  toggleSelected = (e) => {
    const { onChange, onExpand } = this.props;
    const { expanded, isSelected } = this.state;
    const { props } = this;
    const { component, type } = props;

    if (component && expanded === false) {
      if (type === 'payment') {
        component.setState({
          addPayment: false,
          editPayment: false,
          paymentDetails: false,
          addSchoolCash: false
        });
      }
    }

    this.setState({ expanded: !expanded });
    if (onExpand) {
      onExpand(!expanded);
    }
    if (onChange) {
      onChange(!isSelected, e);
    }
  };

  /**
   * Handles data transfer between child to parent
   */
  handleSelection = e => {
    const { onChange } = this.props;
    const { isSelected } = this.state;
    this.setState({ isSelected: !isSelected });
    if (onChange) {
      onChange(!isSelected, e);
    }
  };

  /**
   * Handles correct component interaction
   * based on available props
   */
  handleInteraction = e => {
    const { children, check, navigateTo } = this.props;
    if (navigateTo) navigateTo();
    if (!children && !check) return;
    if (children) return this.toggleSelected(e); // eslint-disable-line
    this.handleSelection(e);
  };

  /**
   * Action when the section button is clicked.
   *
   * @param {Function} action
   *
   * @author Elizabeth Clouser <elizabeth@somepage.com>
   */
  btnClick = async (action) => {
    await action();

    this.setState({
      expanded: false
    });
  };

  render() {
    const { expanded } = this.state;
    const { cookieDough } = this.props;
    const { isSelected } = cookieDough ? this.props : this.state;
    const {
      children,
      innerContext,
      header,
      subHeader,
      check,
      loading,
      subHeaderAsset,
      isAlert,
      navigateTo,
      btnDisabled,
      btnStyles,
      btnAction,
      disabled,
      label,
      subHeaderClass,
      className
    } = this.props;


      let containerClass = ( children || check || navigateTo ) && !disabled ? styles.containerInteractive : null;

      if(disabled === true){
        containerClass = styles.containerDisabled;
      }

    return (
      <div className={`${styles.checkoutContainerWrapper} ${className} bg-white dark:bg-dark shadow-md`}>
        <div
          className={`${styles.checkoutContainer} ${expanded ? styles.containerExpanded : null}`}
        >
          <div
            onClick={e => this.handleInteraction(e)}
            className={[
              styles.checkoutContainerCollapsedContainer,
              containerClass,
            ].join(' ')}
          >
            {/* COMPONENT CONTAINER LEFT SIDE */}
            <div className={`${styles.checkoutContainerLeft}`}>
              <p className='text-dark dark:text-white'>{header}</p>
              
              <span className={`text-darkElevationPrimary dark:text-white flex item-center gap-1
                ${subHeaderClass}
                ${isAlert ? styles.alert : null}`}
              >
                {subHeaderAsset ? <img alt="subheader-asset" src={subHeaderAsset} /> : null }
                <p>{subHeader}</p>
              </span>

              {innerContext? (<span className={`${styles.checkoutContainerInnerContext} text-darkElevationPrimary dark:text-white`}>{innerContext}</span>) : null}

            </div>
            {/* COMPONENT CONTAINER RIGHT SIDE */}
            <div className={`${styles.checkoutContainerRight} font-filsonProRegular text-xs leading-[14px]  text-dark dark:text-white`}>
              {children && isAlert ? (
                <img id={styles.alertIcon} alt="alert-icon" src={alertIcon} />
              ) : null}
              {children || navigateTo ? (
                <>
                  {!expanded ? (<span className={styles.changeText}>CHANGE</span>) : null}
                  <img
                    className={`${navigateTo ? styles.navigate : null} dark:hidden block`}
                    id={`${styles.expandIcon}`}
                    alt="expand-icon"
                    src={expandIcon}
                  />
                  <img
                    className={`${navigateTo ? styles.navigate : null} dark:block hidden`}
                    id={`${styles.expandIcon}`}
                    alt="expand-icon"
                    src={expandLightIcon}
                  />

                </>
              ) : null}

              {check && loading ? (
                <Loader height={25} />
              ) : null }

              {check && !loading ? (
                <CheckBox
                  onClick={this.handleInteraction}
                  isChecked={isSelected}
                />
              ) : null}
            </div>
          </div>
          {/* COMPONENT BODY */}
          <div className={styles.containerDropDownChildren}>
            {expanded ? children : null}

            {btnAction && expanded ? (
              <Button
                customContainerStyles={btnStyles}
                disabled={btnDisabled}
                handleSubmit={() => this.btnClick(btnAction)}
                label={label}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

CheckoutContainer.propTypes = {
    children:
    PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element
    ]
    ),
    header: PropTypes.string.isRequired,
    subHeader: PropTypes.string,
    innerContext: PropTypes.string,
    check: PropTypes.bool,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onExpand: PropTypes.func,
    subHeaderAsset: PropTypes.shape(),
    isAlert: PropTypes.bool,
    navigateTo: PropTypes.func,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    subHeaderClass: PropTypes.string
};

CheckoutContainer.defaultProps = {
    children: null,
    innerContext: null,
    check: false,
    loading: false,
    onChange: null,
    onExpand: null,
    subHeaderAsset: null,
    isAlert: false,
    navigateTo: null,
    subHeader: null,
    label: 'Select',
    subHeaderClass: null
};

export default CheckoutContainer;
