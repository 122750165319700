import React, { Component } from 'react';

import PropTypes from 'prop-types';

import {connect} from "react-redux";
import parentStyles from "../ReviewOrder.module.css";
import styles from "./Discounts.module.css";
import {handleFormInput} from "../../../shared/utilities/validations";
import Loader from "../../../components/Loader/Loader";
import * as orderRepo from "../../../shared/repos/graphql/order";
import * as orderActions from "../../../redux/actions/order";
import * as paymentActions from '../../../redux/actions/payment';
import { orderTypeIds, paymentMethodIds, totalTitles } from '../../../shared/constants/order';

const clearIcon = require('../../../components/DeliveryPickUpWidget/imgs/clear-dark-icon.svg');
const clearLightIcon = require('../../../components/DeliveryPickUpWidget/imgs/clear-light-icon.svg');
const tagIcon = require('../imgs/tag.svg');
const tagLightIcon = require('../imgs/tag-light.svg');

class Discounts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promoCode: '',
            promoCodes: [],
            promoError: '',
            promoResultLoading: false,
            promoRemovalLoading: false
        };
    }

    componentDidMount() {
        this.initializeDiscounts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.initializeDiscounts();
        }
    }

    setPaymentCredentials = () => {
        const { order, setPaymentCredentials, paymentCredentials } = this.props;
        const { promoCodes } = this.state;

        const total = order.totals.find(ttl => ttl.title === totalTitles.total);

        let paymentCredentialsExists = false;
        if (
            paymentCredentials &&
            parseInt(paymentMethodIds.giftcard, 10) === parseInt(paymentCredentials.paymentMethodId, 10) &&
            paymentCredentials.credential === promoCodes[0]
        ) {
            paymentCredentialsExists = true;
        }

        if (total.value === 0 && !paymentCredentialsExists && !this.isDoughProOrder())  {
            setPaymentCredentials({
                paymentMethodId: paymentMethodIds.giftcard,
                credential: promoCodes[0]
            });
        }
    }
 
    setDiscounts(order) {
        const updatedPromos = [];

        if( order.discounts && order.discounts.length > 0) {
            order.discounts.forEach((cartTotal) => {
                let {title} = cartTotal;
                title = title.replace('Coupon: ', '');
                title = title.replace('Giftcard: ', '');

                updatedPromos.push(title);
            });
        }

        this.setState({
            promoCodes: updatedPromos
        }, () => {
            this.setPaymentCredentials();
        });
    }

    isDoughProOrder = () => {
        const {order} = this.props;
        
        return orderTypeIds.doughPro === parseInt(order.orderTypeId, 10);
    }

    initializeDiscounts() {
        const { order } = this.props;

        if (order) {
            this.setDiscounts(order);
        }
    }

    removeDiscount(discountCode) {
        const { order, setPaymentCredentials, paymentCredentials } = this.props;

        this.setState({ promoRemovalLoading: true });

        let orderParams = null;
        if (paymentCredentials) {
          orderParams = {
            paymentCredential: paymentCredentials
          }
        }

        orderRepo.removeDiscount(order.code, discountCode, orderParams).then((response) => {
            const newOrder = this.extractNewTotals(order, response.data.removeDiscountFromOrder);

            this.setState({
                promoRemovalLoading: false
            });

            this.setDiscounts(newOrder);

            setPaymentCredentials(null);
        }).catch(reason => {
            this.setState({ promoError: reason.graphQLErrors[0].message, promoResultLoading: false });

            setPaymentCredentials(null);
        });
    }

    handlePromoSubmission() {
        const { promoCode, promoResultLoading } = this.state;
        const { order, paymentCredentials } = this.props;

        if (promoResultLoading) {
            return;
        }

        if (promoCode.length < 1) {
            this.setState({ promoError: 'Field cannot be empty.' });
            return;
        }

        this.setState(
            { promoResultLoading: true }
        );

        let orderParams = null;
        if (paymentCredentials) {
          orderParams = {
            paymentCredential: paymentCredentials
          }
        }

        orderRepo.addGiftcardOrPromotion(order.code, [promoCode], orderParams).then((response) => {

            const newOrder = this.extractNewTotals(order, response.data.addDiscountToOrder);

            this.setState({
                promoCode: ' ',
                promoError: false,
                promoResultLoading: false,
            });

            this.setDiscounts(newOrder);
        }).catch(reason => {
            const { message } = reason.graphQLErrors[0];

            this.setState({ 
                promoError: message, 
                promoResultLoading: false 
            });
        });
    };

    handlePromoCodeRendering() {
        const { promoCodes, promoRemovalLoading } = this.state;
        return promoCodes.map(code => (
          <div
            key={code}
            className={[
              parentStyles.discountContainer,
              styles.appliedDiscount,
              parentStyles.disableInput
            ].join(" ")}
          >
            <input
              disabled
              value={code}
              className="inputDarkModeOverride bg-transparent hidden"
            />
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            {promoRemovalLoading ? (
              <span className={styles.inputLoaderContainer}>
                <Loader height={25} id="promo_removal_loader" />
              </span>
            ) : (
              <div className="flex gap-1 max-w-full min-w-[153px] h-8 rounded-[40px] px-2 py-2 border-[1px] border-dark dark:border-white text-dark dark:text-white">

                <img
                  alt="Discount Tag"
                  src={tagIcon}
                  id="discount_tag"
                  className={`${parentStyles.clearImg} block dark:hidden top-0 w-4 h-4 flex-none`}
                />
                <img
                    
                  alt="Discount Tag"
                  src={tagLightIcon}
                  id="discount_tag"
                  className={`${parentStyles.clearImg} hidden dark:block top-0 w-4 h-4 flex-none`}
                />

                <p className='text-sm font-filsonProRegular font-bold flex-1'>
                  {code}
                </p>

                <div className='flex-initial'>
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
                  <img
                    onClick={() => {
                      this.removeDiscount(code);
                    }}
                    alt={`Remove Discount ${code}`}
                    src={clearIcon}
                    id="remove_discount_button"
                    className={`${parentStyles.clearImg} block dark:hidden top-0 w-4 h-4`}
                  />
                  {/* eslint-disable-next-line */}
                  <img
                    onClick={() => {
                      this.removeDiscount(code);
                    }}
                    alt={`Remove Discount ${code}`}
                    src={clearLightIcon}
                    id="remove_discount_button"
                    className={`${parentStyles.clearImg} hidden dark:block top-0 w-4 h-4`}
                  />
                </div>

               
              </div>
            )}
          </div>
        ));
    };

    // eslint-disable-next-line class-methods-use-this
    extractNewTotals(order, orderTotals) {
        const newOrder = {
            ...order,
            discounts: [...orderTotals.discounts],
            totals: orderTotals.totals
        };
return newOrder; }

 renderDiscountsContainer = () => {
        const {
            promoCode,
            promoCodes,
            promoResultLoading,
            promoError
        } = this.state;

        return (
          <>
            <div className={`${styles.discountsInputRow} flex gap-2 mt-2`}>
              <input
                value={promoCode}
                onChange={(e) => handleFormInput(e, this, 'promoCode')}
                placeholder="Enter code or number"
                className={`${styles.inputContainer} 
                font-filsonProRegular font-[425] h-11 rounded-[40px] 
                border-[1px] border-lightDry dark:border-darkDry text-dark dark:text-white
                outline-none px-4 placeholder-dark dark:placeholder-white bg-transparent 
                w-full inputDarkModeOverride
                `}
              />
              <button
                id="btn_handle_promo_submit"
                className={`${styles.submitButton} h-11 rounded-[40px] bg-dark dark:bg-button text-white font-filsonProBold`}
                onClick={() => this.handlePromoSubmission()}
                type="button"
              >
                {promoResultLoading ? (<Loader height={25} id="location_loader" />): "Apply"}
              </button>
            </div>
            <p className={styles.promoError} hidden={!promoError}>{promoError}</p>
            {promoCodes.length > 0 ? this.handlePromoCodeRendering() : null}
          </>
        )
    }
    
    render() {
        return <this.renderDiscountsContainer />
    }
}

Discounts.propTypes = {
    order: PropTypes.shape().isRequired,
    setPaymentCredentials: PropTypes.func.isRequired,
    paymentCredentials: PropTypes.shape()
};

Discounts.defaultProps = {
    paymentCredentials: null
};

export const mapStateToProps = (state) => {
    return {
        order: state.order,
        paymentCredentials: state.payment.credentials
    }
}

export const mapDispatchToProps = (dispatch) => ({
    orderUpdated: (value) => dispatch(orderActions.orderUpdated(value)),
    paymentCredentialsUpdated: (value) => dispatch(paymentActions.paymentCredentialsUpdated(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Discounts);
