/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React, { Component } from 'react';

import moment from 'moment';

import Loader from '../../components/Loader/Loader';
import CheckoutContainer from '../../components/CheckOutContainer/CheckoutContainer';
import OrderTrackingWidget from "../../components/OrderTrackingWidget/OrderTrackingWidget";

import styles from './OrderDetails.module.css';

import * as reduxStore from "../../redux/store";

import * as orderRepo from '../../shared/repos/graphql/order';

import { orderTypeIds } from '../../shared/constants/order';
import { getOrderTotals } from '../../shared/utilities/renderings';
import orderTotals from '../../shared/constants/orderTotals';

class OrderDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            order: {}
        };
    }

    componentDidMount() {
        this.getOrder();
    }

    orderInProgress = () => {
        const { order } = this.state;

        if (order.isCancelled || order.isRefunded || order.isStale) {
          return false;
        }

        return order.status?.status < 5;
    }

    getOrder = async () => {
        const { match } = this.props;

        const response = await orderRepo.getOrder(match.params.id);

        this.setState({
            loading: false,
            order: response.data.order
        });
    };

    renderDetailsItem = (index, title, category, quantity, price, currency) => {
        return (
          <div
            key={index}
            className={`
            py-[17px] mb-3 border-[1px] border-borderLight
          ${styles.itemContainer}`}
          >
            <div>
              <p className={styles.itemTitle}>{title}</p>
              <p className={styles.itemType}>{category}</p>
            </div>
            <div>
              <p className={styles.itemType}>{category}</p>
            </div>
            <div className={styles.orderAmountPrice}>
              <p>{quantity}</p>
              <span />
              <p>{`${currency.symbol}${price.toFixed(2)}`}</p>
            </div>
          </div>
        );
    };

  renderLineItem = (label, price, isDiscount, currency) => {
    const store = reduxStore.store.getState();
    const { orderTotalsTitles } = store.orderTotalsTitles;
    const orderTotalsLabels = JSON.parse(orderTotalsTitles);

    let customLabel = label;

    if (orderTotalsLabels && orderTotalsLabels[label] !== undefined) {
      customLabel = orderTotalsLabels[label];
    }

    return (
      <>
        {
          customLabel ? (
            <div className={styles.subTotalPrice} key={label}>
              <p>{ customLabel }</p>
              <p>
                {`${isDiscount && price > 0 ? '-' : ''}${currency.symbol}${price.toFixed(2)
                  }`}
              </p>
            </div>
          )
            : <></>
        }
      </>
    );
  }

    renderOrderSubHeader = () => {
        const { order } = this.state;
        const { orderTypeId, store, shippingAddress } = order;

        if (order.isCancelled) {
          return (<p>Cancelled</p>);
        }

        if (order.isRefunded) {
          return (<p>Refunded</p>);
        }

        const subHeaderByOrderTypeId = {};

        subHeaderByOrderTypeId[orderTypeIds.delivery] = () => {
          if (this.orderInProgress()) {
            return null;
          }

          return (
            <p>
              Delivered
              <span>
              to
                {' '}
                {shippingAddress.address1}
              </span>
            </p>
          );
        }

        subHeaderByOrderTypeId[orderTypeIds.pickup] = () => {
          if (this.orderInProgress()) {
            return null;
          }

          return (
            <p>
              Picked up
              <span>
                at
                {' '}
                {store.address}
              </span>
            </p>
          );
        }

        subHeaderByOrderTypeId[orderTypeIds.shipping] = () => (
          <p>
            { this.orderInProgress() ? 'Ship' : 'Shipped' }
            <span>
              to
              {' '}
              {shippingAddress.address1}
            </span>
          </p>
        );

        subHeaderByOrderTypeId[orderTypeIds.eGiftCard] = () => (
          <p>
            Gift card
          </p>
        )

        return subHeaderByOrderTypeId[orderTypeId]();
    };

    renderTrackingWidget = () => {
      const { order } = this.state;

      if (this.orderInProgress()) {
        return <OrderTrackingWidget order={order} />;
      }

      return null;
    }

    renderOrderDetails = () => {
        const { order } = this.state;
        const { orderTypeId, deliveryTime, shippingAddress, shipment, sender, createdAt, shippingAddress2, recipient, paymentMethod } = order;

        const isGiftOrder = orderTypeId === orderTypeIds.eGiftCard;
        const deliveryDate = deliveryTime === '0000-00-00 00:00:00' ? moment(createdAt).format('MMM D') : moment(deliveryTime).format('MMM D');
        const deliveryType = orderTypeId === orderTypeIds.pickup ? 'Pickup' : 'Delivery';
        const address = shippingAddress2 ? `${shippingAddress.address1}, ${shippingAddress2}` : shippingAddress.address1;

        const renderShippingAddress = shippingAddress && !isGiftOrder ?  this.renderUserDetails(
            'Delivery Address',
            `${address}, ${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.postcode}`
        ):null;

        const renderShipment = order.shipment ? this.renderUserDetails(
            'Shipping Date',
            shipment.estimatedDeliveryDate
        ): this.renderUserDetails(
            `${deliveryType} Date`,
            deliveryDate
        );

        const renderRecipient = order.recipient ? this.renderUserDetails(
            'Gift Recipient Contact Information',
            `${recipient.firstName} ${recipient.phone}`
        ): null;

        const renderSender = order.sender ? this.renderUserDetails(
            'Your Contact Information',
            `${sender.firstName} ${sender.lastName}, ${sender.phone}, ${sender.email}`
        ): null;
        const renderPaymentMethod = order.paymentMethod ? this.renderUserDetails('Payment Method',paymentMethod.title) : null;
        return (
          <div className={styles.userDetailsContainer}>
            {renderShippingAddress}
            {renderShipment}
            {renderRecipient}
            {renderSender}
            {renderPaymentMethod}
          </div>
        );
    };

    renderDetailsHeader = () => {
        const { order } = this.state;
        const { orderTypeId } = order;

        const headerByOrderTypeId = {};
        headerByOrderTypeId[orderTypeIds.delivery] = 'Delivery Details';
        headerByOrderTypeId[orderTypeIds.pickup] = 'Pickup Details';
        headerByOrderTypeId[orderTypeIds.shipping] = 'Shipping Details';

        return headerByOrderTypeId[orderTypeId];
    };

    renderUserDetails = (header, subHeader) => (
      <CheckoutContainer header={header} subHeader={subHeader} />
    );

    renderOrderTotals = () => {
        const { order } = this.state;
        const  {currency} = order.store;
        let serviceFeeTotal = null;

        return (
          <div className={styles.orderDetailsContainer}>
            <div className={styles.discountsContainer}>
              {order.discounts.map(discount => {
                        return this.renderLineItem(
                            discount.title,
                            discount.value,
                            true,
                            currency
                        );
                    })}
            </div>

            {order.totals.filter(item => {
                const isCoupons = item.title.toLowerCase() === orderTotals.coupons.title.toLowerCase();
                const isTotal = item.title.toLowerCase() === orderTotals.total.title.toLowerCase();

                if (orderTotals.serviceFee.title.toLowerCase() === item.title.toLowerCase()) {
                      serviceFeeTotal = item;

                      return false;
                    }
                return !isTotal && !isCoupons;
                }).map(item => {
                    let itemTitle = item.title;
                    const isloyalty = item.title.toLowerCase() === orderTotals.loyalty.title.toLowerCase();

                    if (
                      orderTotals.tax.title.toLowerCase() === item.title.toLowerCase() &&
                      serviceFeeTotal
                    ) {
                      itemTitle = 'Taxes & Fees';
                    }

                    return (this.renderLineItem(itemTitle, item.value, isloyalty, currency));
                })}

            <div className={[ styles.subTotalPrice, styles.totalPrice ].join(' ')}>
              <p>Total</p>

              {order.totals.filter(item => {
                    return item.title.toLowerCase() === orderTotals.total.title.toLowerCase();
                }).map(item => {
                    return (
                      <p key={item.value}>
                        {currency.symbol}
                        {item.value.toFixed(2)}
                      </p>
                    );
                })}
            </div>
          </div>
        );
    };

    renderItems = () => {
        const { order } = this.state;
        const {currency} = order.store;

        return (
          <div className={`
          bg-white dark:bg-dark px-8 py-10 border-[1px] border-lightDry dark:border-darkBorder
          rounded-[20px]
            ${styles.detailsItemsContainer}
            `}
          >
            {order.items.map((item, index) => {
                const price = getOrderTotals(item);
                    return this.renderDetailsItem(
                        index,
                        item.product.title,
                        item.product.categories.map(category => category.title).join(', ').toLowerCase(),
                        item.quantity,
                        price,
                        currency
                    );
                })}
          </div>
        );
    };

    render() {
        const { loading, order } = this.state;

        if (loading) {
            return (
              <div className={styles.loaderWrapper}>
                <Loader />
              </div>
            );
        }

        return (
          <div className={`${styles.pageWrapper} text-dark dark:text-white mb-16`}>
            <div className={`${styles.pageContainer} max-w-screen-desktop`}>
              <h1>Order Details</h1>

              {this.renderOrderSubHeader()}
              <span>
                Order #
                {order.id}
              </span>

              {this.renderTrackingWidget()}

              {this.renderItems()}

              <div className={styles.detailsWrapper}>
                <h3>{this.renderDetailsHeader()}</h3>
                <div className={`${styles.detailsContainer} mb-4`}>

                  {this.renderOrderDetails()}

                  {this.renderOrderTotals()}

                  <div className={styles.userDetailsContainer}>
                    <h3>{this.renderDetailsHeader()}</h3>
                    {this.renderOrderDetails()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default OrderDetails;
