/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint no-param-reassign:  [2, { "props": false }] */
import React from "react";
import styles from "./CustomProductOption.module.css";

const checkIcon = require('./imgs/check-icon.svg');
const checkLightIcon = require('./imgs/check-light-icon.svg');

function CustomSelectorOption({
  name,
  isSelected,
  onChange,
  price,
  calories,
  currency,
  renderPrice
}) {
  return (
    <div
      className={`${styles.selectorWrapper} text-dark dark:text-white font-filsonProRegular text-base leading-5`}
    >
      <div
        onClick={onChange}
        className={isSelected ? "bg-dark dark:bg-light" : null}
      >
        <img
          alt="check"
          src={checkLightIcon}
          className={`${isSelected ? "hidden dark:block" : "hidden"}`}
        />
        <img
          alt="check"
          src={checkIcon}
          className={`${isSelected ? "dark:hidden block" : "hidden"}`}
        />
      </div>
      <p
        className={[
          styles.selectorName,
          renderPrice(price, currency) ? styles.selectorNameWithPrice : null,
          'text-md'
        ].join(" ")}
      >
        {name}
        <br />
        <span className={styles.caloriesDisplay}>{calories}</span>
      </p>
      <p>{renderPrice(price, currency)}</p>
    </div>
  );
}

export default CustomSelectorOption;
